import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import TopArrow from "../../assets/images/top-arrow.png";

const ScrollToTop = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <button
        className="inline-block absolute bottom-0 right-[2%] min-1024:right-0 min-1024:left-[50%] h-[70px] w-[65px] z-[999]"
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      >
        <img className="h-[70px] w-[65px]" src={TopArrow} alt="scroll-to-top" />
      </button>
    </div>
  );
};

export default ScrollToTop;
