import React, { useContext } from "react";

// import Swiper core and required modules
// import { Navigation } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import "swiper/css/effect-fade";
// import "./PromotionalSlider.scss";

// import PromotionalSliderbg from "../../assets/images/promotional_slider_bg.jpg";
// import Prodcut2 from "../../assets/images/product2.webp";
import { HomepageStoreContext } from "../../context/HomepageStore";

export default function PromotionalSlider() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeData, setHomeData } =
    useContext(HomepageStoreContext);

  // console.log('promotional slider', homeData.promotional_slider.background_img.url);
  return (
    <div className="promotional-sliders">
      {/* desktop */}
      <div
        className="768:w-[100%] relative hidden min-1024:block"
        style={{
          backgroundColor: homeData.promotional_slider.background_color,
          backgroundImage: `url(${
            homeData.promotional_slider.background_img.url &&
            homeData.promotional_slider.background_img.url
          })`,
          backgroundSize: "cover",
          objectFit: "cover",
          minHeight: "100vh",
        }}
      >
        <div className="innaree-container pt-[300px] pb-[100px]">
          <div className="grid grid-cols-6 items-center gap-4">
            <div className="col-span-3">
              {/* title 1 */}
              <span
                className="block mb-[20px]"
                style={{
                  fontWeight:
                    homeData.promotional_slider.title_1_typography[
                      "font-weight"
                    ],
                  fontStyle:
                    homeData.promotional_slider.title_1_typography[
                      "font-style"
                    ],
                  textAlign:
                    homeData.promotional_slider.title_1_typography[
                      "text-align"
                    ],
                  textTransform:
                    homeData.promotional_slider.title_1_typography[
                      "text-transform"
                    ],
                  fontSize:
                    homeData.promotional_slider.title_1_typography[
                      "font-size"
                    ] + homeData.promotional_slider.title_1_typography.unit,
                  color: homeData.promotional_slider.title_1_typography.color,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    homeData.promotional_slider.title_1 &&
                    homeData.promotional_slider.title_1,
                }}
              />

              {/* title 2 */}
              <span
                className="block mb-[20px]"
                style={{
                  fontWeight:
                    homeData.promotional_slider.title_2_typography[
                      "font-weight"
                    ],
                  fontStyle:
                    homeData.promotional_slider.title_2_typography[
                      "font-style"
                    ],
                  textAlign:
                    homeData.promotional_slider.title_2_typography[
                      "text-align"
                    ],
                  textTransform:
                    homeData.promotional_slider.title_2_typography[
                      "text-transform"
                    ],
                  fontSize:
                    homeData.promotional_slider.title_2_typography[
                      "font-size"
                    ] + homeData.promotional_slider.title_2_typography.unit,
                  color: homeData.promotional_slider.title_2_typography.color,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    homeData.promotional_slider.title_2 &&
                    homeData.promotional_slider.title_2,
                }}
              />

              {/* coupon code */}
              <span
                className="block mb-[20px]"
                style={{
                  fontWeight:
                    homeData.promotional_slider.promo_code_typography[
                      "font-weight"
                    ],
                  fontStyle:
                    homeData.promotional_slider.promo_code_typography[
                      "font-style"
                    ],
                  textAlign:
                    homeData.promotional_slider.promo_code_typography[
                      "text-align"
                    ],
                  textTransform:
                    homeData.promotional_slider.promo_code_typography[
                      "text-transform"
                    ],
                  fontSize:
                    homeData.promotional_slider.promo_code_typography[
                      "font-size"
                    ] + homeData.promotional_slider.promo_code_typography.unit,
                  color:
                    homeData.promotional_slider.promo_code_typography.color,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    homeData.promotional_slider.promo_code &&
                    homeData.promotional_slider.promo_code,
                }}
              />

              <div
                className="block"
                style={{
                  textAlign:
                    homeData.promotional_slider.from_date_typography[
                      "text-align"
                    ],
                }}
              >
                {/* from date */}
                <span
                  className="inline-block mr-4"
                  style={{
                    fontWeight:
                      homeData.promotional_slider.from_date_typography[
                        "font-weight"
                      ],
                    fontStyle:
                      homeData.promotional_slider.from_date_typography[
                        "font-style"
                      ],
                    // textAlign: homeData.promotional_slider.from_date_typography['text-align'],
                    textTransform:
                      homeData.promotional_slider.from_date_typography[
                        "text-transform"
                      ],
                    fontSize:
                      homeData.promotional_slider.from_date_typography[
                        "font-size"
                      ] + homeData.promotional_slider.from_date_typography.unit,
                    color:
                      homeData.promotional_slider.from_date_typography.color,
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      homeData.promotional_slider.from_date &&
                      homeData.promotional_slider.from_date,
                  }}
                />
                {/* to date */}
                <span
                  className="inline-block"
                  style={{
                    fontWeight:
                      homeData.promotional_slider.to_date_typography[
                        "font-weight"
                      ],
                    fontStyle:
                      homeData.promotional_slider.to_date_typography[
                        "font-style"
                      ],
                    // textAlign: homeData.promotional_slider.to_date_typography['text-align'],
                    textTransform:
                      homeData.promotional_slider.to_date_typography[
                        "text-transform"
                      ],
                    fontSize:
                      homeData.promotional_slider.to_date_typography[
                        "font-size"
                      ] + homeData.promotional_slider.to_date_typography.unit,
                    color: homeData.promotional_slider.to_date_typography.color,
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      homeData.promotional_slider.to_date &&
                      homeData.promotional_slider.to_date,
                  }}
                />
              </div>
            </div>

            {/* product img */}
            <div className="col-span-3">
              <img
                className="max-w-[400px] max-h-[500px] object-cover"
                src={
                  homeData.promotional_slider.product_img &&
                  homeData.promotional_slider.product_img &&
                  homeData.promotional_slider.product_img.url
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div
        className="768:w-[100%] relative min-1024:hidden"
        style={{
          backgroundColor: homeData.promotional_slider.mb_background_color,
          backgroundImage: `url(${
            homeData.promotional_slider.mb_background_img.url &&
            homeData.promotional_slider.mb_background_img.url
          })`,
          backgroundSize: "cover",
          objectFit: "cover",
          minHeight: "100vh",
        }}
      >
        <div className="innaree-container pt-[100px] pb-[100px] min-576:pt-[200px]  min-576:pb-[200px]">
          <div className="grid grid-cols-6 items-center gap-4">
            {/* product img */}
            {homeData.promotional_slider.mb_product_img &&
              homeData.promotional_slider.mb_product_img && (
                <div className="col-span-6 mb-[10px]  min-576:mb-[30px]">
                  <img
                    className={`576:w-[300px] max-w-[400px] max-h-[500px] object-cover ${
                      homeData.promotional_slider.mb_title_1_typography[
                        "text-align"
                      ] == "center" && "mx-auto"
                    }`}
                    src={
                      homeData.promotional_slider.mb_product_img &&
                      homeData.promotional_slider.mb_product_img &&
                      homeData.promotional_slider.mb_product_img.url
                    }
                  />
                </div>
              )}
            <div className="col-span-6 ">
              {/* title 1 */}
              <span
                className="block mb-[20px]"
                style={{
                  fontWeight:
                    homeData.promotional_slider.mb_title_1_typography[
                      "font-weight"
                    ],
                  fontStyle:
                    homeData.promotional_slider.mb_title_1_typography[
                      "font-style"
                    ],
                  textAlign:
                    homeData.promotional_slider.mb_title_1_typography[
                      "text-align"
                    ],
                  textTransform:
                    homeData.promotional_slider.mb_title_1_typography[
                      "text-transform"
                    ],
                  fontSize:
                    homeData.promotional_slider.mb_title_1_typography[
                      "font-size"
                    ] + homeData.promotional_slider.mb_title_1_typography.unit,
                  color:
                    homeData.promotional_slider.mb_title_1_typography.color,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    homeData.promotional_slider.mb_title_1 &&
                    homeData.promotional_slider.mb_title_1,
                }}
              />

              {/* title 2 */}
              <span
                className="block mb-[20px]"
                style={{
                  fontWeight:
                    homeData.promotional_slider.mb_title_2_typography[
                      "font-weight"
                    ],
                  fontStyle:
                    homeData.promotional_slider.mb_title_2_typography[
                      "font-style"
                    ],
                  textAlign:
                    homeData.promotional_slider.mb_title_2_typography[
                      "text-align"
                    ],
                  textTransform:
                    homeData.promotional_slider.mb_title_2_typography[
                      "text-transform"
                    ],
                  fontSize:
                    homeData.promotional_slider.mb_title_2_typography[
                      "font-size"
                    ] + homeData.promotional_slider.mb_title_2_typography.unit,
                  color:
                    homeData.promotional_slider.mb_title_2_typography.color,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    homeData.promotional_slider.mb_title_2 &&
                    homeData.promotional_slider.mb_title_2,
                }}
              />

              {/* coupon code */}
              <span
                className="block mb-[20px]"
                style={{
                  fontWeight:
                    homeData.promotional_slider.mb_promo_code_typography[
                      "font-weight"
                    ],
                  fontStyle:
                    homeData.promotional_slider.mb_promo_code_typography[
                      "font-style"
                    ],
                  textAlign:
                    homeData.promotional_slider.mb_promo_code_typography[
                      "text-align"
                    ],
                  textTransform:
                    homeData.promotional_slider.mb_promo_code_typography[
                      "text-transform"
                    ],
                  fontSize:
                    homeData.promotional_slider.mb_promo_code_typography[
                      "font-size"
                    ] +
                    homeData.promotional_slider.mb_promo_code_typography.unit,
                  color:
                    homeData.promotional_slider.mb_promo_code_typography.color,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    homeData.promotional_slider.mb_promo_code &&
                    homeData.promotional_slider.mb_promo_code,
                }}
              />

              <div
                style={{
                  textAlign:
                    homeData.promotional_slider.mb_from_date_typography[
                      "text-align"
                    ],
                }}
              >
                {/* from date */}
                <span
                  className="inline-block mr-4"
                  style={{
                    fontWeight:
                      homeData.promotional_slider.mb_from_date_typography[
                        "font-weight"
                      ],
                    fontStyle:
                      homeData.promotional_slider.mb_from_date_typography[
                        "font-style"
                      ],
                    // textAlign: homeData.promotional_slider.from_date_typography['text-align'],
                    textTransform:
                      homeData.promotional_slider.mb_from_date_typography[
                        "text-transform"
                      ],
                    fontSize:
                      homeData.promotional_slider.mb_from_date_typography[
                        "font-size"
                      ] +
                      homeData.promotional_slider.mb_from_date_typography.unit,
                    color:
                      homeData.promotional_slider.mb_from_date_typography.color,
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      homeData.promotional_slider.mb_from_date &&
                      homeData.promotional_slider.mb_from_date,
                  }}
                />
                {/* to date */}
                <span
                  className="inline-block"
                  style={{
                    fontWeight:
                      homeData.promotional_slider.mb_to_date_typography[
                        "font-weight"
                      ],
                    fontStyle:
                      homeData.promotional_slider.mb_to_date_typography[
                        "font-style"
                      ],
                    // textAlign: homeData.promotional_slider.mb_to_date_typography['text-align'],
                    textTransform:
                      homeData.promotional_slider.mb_to_date_typography[
                        "text-transform"
                      ],
                    fontSize:
                      homeData.promotional_slider.mb_to_date_typography[
                        "font-size"
                      ] +
                      homeData.promotional_slider.mb_to_date_typography.unit,
                    color:
                      homeData.promotional_slider.mb_to_date_typography.color,
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      homeData.promotional_slider.mb_to_date &&
                      homeData.promotional_slider.mb_to_date,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
