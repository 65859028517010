import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NavbarLeft from "../components/navbar/NavbarLeft";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Login from "../components/login/Login";
import Searchbar from "../components/searchbar/Searchbar";
import Cart from "../components/cartpage/Cart";
import { Link, useParams } from "react-router-dom";
import { BsPatchCheck } from "react-icons/bs";
import axios from "axios";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";

export default function Success() {
  // https://innaree.softtech-it.com/wp-json/softtechit/v1/innaree/payment-success/448

  // Get the product param from the URL.
  let { id } = useParams();
  // let id = 448;

  // single product data
  const [successOrderDetails, setsuccessOrderDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  // functions
  // get single product data from api
  function fetchData() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/payment-success/${id}`)
      .then((res) => {
        setsuccessOrderDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <>
        <Helmet>
          <title>Innaree - payment success</title>
        </Helmet>
        <div className="overflow-hidden">
          <Header layout={"sm"} />
          <Searchbar />
          <Cart />
          <NavbarLeft />
          <div className="innaree-container py-[100px] flex justify-center items-center">
            <div className="register max-w-[700px] mx-auto p-8  shadow-2xl my-16 bg-white rounded-lg text-center">
              <h2 className=" text-[28px] capitalize font-bold">
                Thank you for your order!!
              </h2>
              <span className="text-[60px] mx-auto w-36  my-4 text-innaree-primary-color text-center flex justify-center">
                <BsPatchCheck />
              </span>


              <div className="mb-[50px] text-left">
                <div className="mb-4 320:px-4">
                  <p className="text-[20px] font-bold mb-3 text-center">
                    <strong>Your order details</strong>:
                  </p>

                  <p className="mb-2"> <strong>Order id </strong>: #{successOrderDetails.order_id}</p>
                  <p className="mb-2">
                    <strong>Name </strong>: <span className="mr-3">{successOrderDetails.order_billing_first_name}</span>
                    {""}
                    {successOrderDetails.order_billing_last_name}
                  </p>
                  <p className="mb-2"> <strong>Email </strong>: {successOrderDetails.order_billing_email}</p>
                  <p className="mb-2">
                    <strong>Address </strong>: <span className="mr-3">{successOrderDetails.orderorder_billing_address_1_id}</span>{" "}
                    {""}
                    {successOrderDetails.order_billing_address_2}
                  </p>
                  <p className="mb-2"> <strong>City </strong>: {successOrderDetails.order_billing_city}</p>
                  <p className="mb-2"> <strong>Country </strong>: {successOrderDetails.order_billing_country}</p>
                  <p className="mb-2">
                    <strong>Billing Phone number </strong>:{" "}
                    {successOrderDetails.order_billing_phone}
                  </p>
                  <p className="mb-2"> <strong>Order status </strong>: {successOrderDetails.order_status}</p>
                </div>

                {/* <div className="grid grid-cols-2 items-center mb-3">
                  <p className="text-right mr-4">
                    {" "}
                    <strong>Order id</strong>:
                  </p>
                  <p className="text-left"> #{successOrderDetails.order_id}</p>
                </div>

                <div className="grid grid-cols-2 items-center mb-3">
                  <p className="text-right mr-4">
                    {" "}
                    <strong>Name</strong>:
                  </p>
                  <p className="text-left">
                    {" "}
                    {successOrderDetails.order_billing_last_name}
                  </p>
                </div>

                <div className="grid grid-cols-2 items-center mb-3">
                  <p className="text-right mr-4">
                    {" "}
                    <strong> Email</strong>:
                  </p>
                  <p className="text-left">
                    {" "}
                    {successOrderDetails.order_billing_email}
                  </p>
                </div>

                <div className="grid grid-cols-2 items-center mb-3">
                  <p className="text-right mr-4">
                    {" "}
                    <strong>Address</strong>:
                  </p>
                  <span className="text-left">
                    <p>
                      {" "}
                      {successOrderDetails.orderorder_billing_address_1_id}
                    </p>
                    <p> {successOrderDetails.order_billing_address_2}</p>
                  </span>
                </div>

                <div className="grid grid-cols-2 items-center mb-3">
                  <p className="text-right mr-4">
                    {" "}
                    <strong> City</strong>:
                  </p>
                  <p className="text-left">
                    {" "}
                    {successOrderDetails.order_billing_city}
                  </p>
                </div>

                <div className="grid grid-cols-2 items-center mb-3">
                  <p className="text-right mr-4">
                    {" "}
                    <strong>Country</strong>:
                  </p>
                  <p className="text-left">
                    {" "}
                    {successOrderDetails.order_billing_country}
                  </p>
                </div>

                <div className="grid grid-cols-2 items-center mb-3">
                  <p className="text-right mr-4">
                    {" "}
                    <strong>Billing Phone number</strong>:
                  </p>
                  <p className="text-left">
                    {" "}
                    {successOrderDetails.order_billing_phone}
                  </p>
                </div>

                <div className="grid grid-cols-2 items-center mb-3">
                  <p className="text-right mr-4">
                    {" "}
                    <strong> Order status</strong>:
                  </p>
                  <p className="text-left">
                    {" "}
                    {successOrderDetails.order_status}
                  </p>
                </div> */}

                <table className="table-auto border-2 overflow-x-auto mt-[30px] px-4">
                  <tr className="border-2 px-4">
                    <th className="px-4 capitalize"> product name</th>
                    <th className="px-4 capitalize">image</th>
                    <th className="px-4 capitalize">qty</th>
                    <th className="px-4 capitalize">total</th>
                  </tr>
                  {successOrderDetails.order_items &&
                    successOrderDetails.order_items.map(
                      (order_product, index) => (
                        <tr className="border-2 px-4" key={`success_${index}`}>
                          <td className="px-4">{order_product.product_name}</td>
                          <td className="px-4"><img className="w-[50px]" src={order_product.product_img} /></td>
                          <td className="px-4">{order_product.quantity}</td>
                          <td className="px-4">£{order_product.subtotal}</td>
                        </tr>
                      )
                    )}
                  {/* dummy data start*/}
                  <tr className="border-2 px-4">
                    <th className="px-4 capitalize">Shipping Charge</th>
                    <th className="px-4 capitalize"></th>
                    <th className="px-4 capitalize"></th>
                    <th className="px-4 capitalize">£{successOrderDetails.shipping_charge}</th>
                  </tr>

                  <tr className="border-2 px-4">
                    <th className="px-4 capitalize">Discounted amount(-)</th>
                    <th className="px-4 capitalize"></th>
                    <th className="px-4 capitalize"></th>
                    <th className="px-4 capitalize">£{successOrderDetails.discount_amount}</th>
                  </tr>

                  <tr className="border-2 px-4">
                    <th className="px-4 capitalize">total</th>
                    <th className="px-4 capitalize"></th>
                    <th className="px-4 capitalize"></th>
                    <th className="px-4 capitalize">£{successOrderDetails.total_amount}</th>
                  </tr>
                  {/* dummy data ended*/}
                </table>

                {/* <p>  <strong>Order id</strong>: #{successOrderDetails.order_id}</p> */}
                {/* <p>
                  <strong>
                    Name
                  </strong>
                  : <span className="mr-2">{successOrderDetails.order_billing_first_name}</span>
                  {""}
                  <span>{successOrderDetails.order_billing_last_name}</span>
                </p> */}
                {/* <p>
                  <strong>
                    Email
                  </strong>: {successOrderDetails.order_billing_email}</p>
                <p> */}
                {/* <strong>
                  Address
                </strong>
                : {successOrderDetails.orderorder_billing_address_1_id}{" "}
                {""}
                {successOrderDetails.order_billing_address_2}
              </p> */}
                {/* <p>
                  <strong>
                    City
                  </strong>
                  : {successOrderDetails.order_billing_city}</p>
                <p> */}
                {/* <strong>
                    Country
                  </strong>
                  : {successOrderDetails.order_billing_country}</p>
                <p> */}
                {/* <strong>
                  Billing Phone number
                </strong>
                :{" "}
                {successOrderDetails.order_billing_phone}
              </p> */}
                {/* <p>
                <strong>
                  Order status
                </strong>
                : {successOrderDetails.order_status}</p> */}
              </div>
              <Link
                to="/"
                className="text-white font-semibold uppercase mt-[60px] bg-innaree-secondary-color text-left  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500"
              >
                back to home
              </Link>
              {/* <Link className='text-innaree-secondary-color text-[16px] capitalize mt-4 inline-block' to='/'>back to home</Link> */}
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
