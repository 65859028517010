import React from "react";
import { HiShoppingBag } from "react-icons/hi";
import { AiFillMessage } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Button.scss";

// circlebutton
export const CircleButton = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
}) => {
  return <button>{title}</button>;
};

// button one
export const TemplateButton = ({ title, customClasses }) => {
  return (
    <button
      type="button"
      className={`rounded-innaree-rounded font-red-hat text-white bg-innaree-primary-color border-2 border-transparent font-medium text-sm px-7 py-[7px] hover:shadow-in-boxShadow-primary-color hover:-translate-y-1 duration-500 ${customClasses}`}
    >
      {title}
    </button>
  );
};

// button one
export const ButtonOne = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`rounded-innaree-rounded font-red-hat text-white bg-innaree-primary-color border-2 border-transparent font-medium text-sm px-7 py-[7px] hover:drop-shadow-5xl hover:-translate-y-1 duration-500 ${hovercolor}`}
    >
      {title}
    </button>
  );
};

// button two
export const ButtonTwo = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
}) => {
  return (
    <button
      type="button"
      className="text-black  bg-white border-2 border-innaree-border-color-gray rounded-lg font-medium text-sm px-7 py-[7px] mb-2 hover:drop-shadow-5xl hover:-translate-y-1 duration-500"
    >
      VIEW
    </button>
  );
};

// button three
export const ButtonThree = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
}) => {
  return (
    <button
      type="button"
      className="text-white bg-black  border-2 border-transparent hover:border-innaree-border-color-gray rounded-lg hover:drop-shadow-2xl font-medium text-sm px-7 py-2.5 hover:text-black hover:bg-white"
    >
      Write a Review
    </button>
  );
};

// button four
export const ButtonFour = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
  customClass,
}) => {
  return (
    <button
      type="button"
      className={`${customClass} text-white bg-innaree-secondary-color border-2 border-transparent rounded-full font-medium text-sm px-8 py-2.5 flex items-center hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500`}
    >
      {" "}
      <HiShoppingBag className="mr-2 text-xl" /> Shop Now
    </button>
  );
};

// button five
export const ButtonFive = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
}) => {
  return (
    <button
      type="button"
      className="text-black hover:text-white hover:bg-black border-2 border-innaree-border-color-gray rounded-full shadow-2xl font-medium text-sm px-8 py-2.5 flex items-center"
    >
      {" "}
      <AiFillMessage className="mr-2 text-xl" /> Submit Now
    </button>
  );
};

// button six
export const ButtonSix = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
}) => {
  return (
    <button
      type="button"
      className="font-red-hat text-white bg-innaree-primary-color border-2 border-transparent h-[55px] w-[55px] rounded-full font-medium text-sm  hover:shadow-in-boxShadow-primary-color hover:-translate-y-1 duration-500"
    >
      ADD
    </button>
  );
};

// button seven
export const ButtonSeven = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
}) => {
  return (
    <button
      type="button"
      className="font-red-hat text-black bg-white border-2 border-innaree-secondary-color h-[55px] w-[55px] rounded-full font-medium text-sm hover:text-black hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500"
    >
      View
    </button>
  );
};

// button eight

export function ButtonEight({
  title = "Checkout",
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  buttonClass = "",
  onClick,
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 ${buttonClass}`}
    >
      {title}
    </button>
  );
}

// UnderLineBtn White

export function BtnUnderLineWhite({
  title = "Lab Report",
  btnUrl = "/",
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  buttonClass = "",
  onClick,
}) {
  return (
    <button
      onClick={() => onClick}
      type="button"
      className={`un-btn-white ont-red-hat font-bold border-b border-b-innaree-secondary-color text-[16px] text-white leading-[21.17px] ${buttonClass}`}
    >
      <Link to={btnUrl}>{title}</Link>
    </button>
  );
}
// UnderLineBtn Black

export function BtnUnderLineBlack({
  title = "Lab Report",
  btnUrl = "/",
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  buttonClass = "",
  onClick,
}) {
  return (
    <button
      onClick={() => onClick}
      type="button"
      className={`un-btn-black ont-red-hat font-bold border-b border-b-innaree-secondary-color text-[16px] text-white ${buttonClass}`}
    >
      <Link to={btnUrl}>{title}</Link>
    </button>
  );
}

// badge one
export const BadgeOne = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
}) => {
  return (
    <button className="text-black bg-innaree-bg-color1 font-medium hover:bg-white text-[14px] px-[14px] py-[2px] rounded border-2 border-transparent hover:border-innaree-border-color-gray transition ease-in-out">
      Best Seller
    </button>
  );
};

// badge two
export const BadgeTwo = ({
  title,
  color,
  bgColor,
  hover,
  hovercolor,
  hoverBgColor,
  imgUrl,
}) => {
  return (
    <button className="text-black font-medium bg-white hover:bg-innaree-bg-color1 text-[14px] border-2 border-innaree-border-color-gray hover:border-transparent rounded px-[14px] py-[2px]">
      High Strength
    </button>
  );
};
