import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { base_url } from "../../Base_url";
const Register = () => {
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    telephone: "",
    password: "",
    confirmPassword: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email address");
    } else {
      setError(null);
    }
  };

  const handleOnchange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  const handleChangeEmail = (e) => {
    setRegisterData({ ...registerData, ["email"]: e.target.value });
    validateEmail(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (registerData.password == registerData.confirmPassword) {
      const formData = new FormData();
      formData.append("first_name", registerData.firstName);
      formData.append("last_name", registerData.lastName);
      formData.append("user_login", registerData.username);
      formData.append("user_email", registerData.email);
      formData.append("telephone", registerData.telephone);
      formData.append("user_pass", registerData.password);

      // make post req
      axios
        .post(
          `${base_url}/wp-json/softtechit/v1/innaree/createuser/`,
          formData,
          {
            headers: {
              Authorization: "Bearer ",
            },
          }
        )
        .then((response) => {
          toast.custom(
            <div>
              <div
                id="toast-success"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Check icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  Registerd Successfully!
                </div>
              </div>
            </div>
          );
          navigate("/login");
        })
        .catch((err) => {
          // console.log(err);
          toast.custom(
            <div>
              <div
                id="toast-danger"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Error icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  {err.response.data.message}
                </div>
              </div>
            </div>
          );
        });
    } else {
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Please fill up all required fields!
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          duration: 1000,
        }}
      />
      <div className="register max-w-[500px] mx-auto p-16  shadow-2xl my-16 bg-white rounded-lg">
        <h2 className="text-[32px] text-center mb-[20px]">Register</h2>
        <from>
          <input
            name="firstName"
            onChange={handleOnchange}
            className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
            type="text"
            placeholder="First Name"
          />
          <input
            name="lastName"
            onChange={handleOnchange}
            className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
            type="text"
            placeholder="Last Name"
          />
          <input
            name="username"
            onChange={handleOnchange}
            className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
            type="text"
            placeholder="Username"
          />

          <input
            name="email"
            onChange={handleChangeEmail}
            className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
            type="email"
            placeholder="Email"
          />

          {error && <div className="text-red-500">{error}</div>}

          <input
            name="telephone"
            onChange={handleOnchange}
            className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
            type="text"
            placeholder="Telephone"
          />
          <input
            name="password"
            onChange={handleOnchange}
            className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
            type="password"
            placeholder="Password"
          />
          <input
            name="confirmPassword"
            onChange={handleOnchange}
            className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
            type="password"
            placeholder="Confirm Password"
          />
          {registerData.password != registerData.confirmPassword && (
            <div className="text-red-500">Password not matched</div>
          )}

          <button
            onClick={submitForm}
            type="submit"
            className={`text-white font-semibold uppercase bg-innaree-secondary-color text-left  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500`}
          >
            Submit
          </button>
        </from>
        <div className="flex items-center justify-between mt-[30px]">
          <Link className="hover:underline" to="/login">
            Login
          </Link>
          <Link className="hover:underline" to="/">
            Back to home
          </Link>
        </div>
      </div>
    </>
  );
};

export default Register;
