import React from "react";
// import helping_counting_bg from "../../assets/images/helping_counting_bg.webp";

export default function SingleCountCard({
  wrapperClass = "",
  headerClass = "",
  textClass = "",
  number = "",
  text = "",
}) {
  return (
    <div
      className={`${wrapperClass}
      1024:m-4 w-full 1024:block min-1024:w-[130px]  min-1024:h-[130px] flex flex-col items-center justify-center  text-center rounded-lg px-[10px] py-[20px]  text-white 
    `}
      data-aos="fade-down"
      data-aos-duration="1300"
      data-aos-anchor-placement="top-bottom"
      data-aos-easing="ease-in-back"
      // data-aos-delay="700"
    >
      <h4
        className={`${headerClass} text-[32px] leading-[26px] font-[900] mb-2`}
      >
        {number}
      </h4>
      <span
        className={`${textClass} text-[14px] leading-[20px] font-bold capitalize`}
      >
        {text}
      </span>
    </div>
  );
}
