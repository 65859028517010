import React, {
  useState,
  useEffect,
  createContext,
  useRef,
  useContext,
} from "react";
import { useQuery } from "react-query";
// import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import axios from "axios";
import { base_url } from "../Base_url";
import { StoreContext } from "./Store";

// creating context api
const HomepageStoreContext = createContext();

/******************************************
 *
 * GETTING CONTEXT VALUE
 *
 ******************************************/

const HomepageStoreProvider = ({ children }) => {
  // declare all states & functons here

  const [homeData, setHomeData] = useState([]);

  const {
    isLoading: homeIsLoading,
    error: homeError,
    data: queryHomeData,
  } = useQuery("homepage-query-key", async () => {
    const response = await axios.get(
      `${base_url}/wp-json/softtechit/v1/innaree/home/`
    );
    return response.data;
  });

  useEffect(() => {
    if (queryHomeData) {
      setHomeData(queryHomeData);
    }
  }, [queryHomeData]);

  // product type
  const [productTypesData, setproductTypesData] = useState([]);

  const filterdProductType = productTypesData.filter(
    (item) => item.categorie_slug != "uncategorized"
  );

  // get all about page data
  const {
    isLoading: productTypesIsLoading,
    error: productTypesError,
    data: queryproductTypesData,
  } = useQuery("productTypespage-query-key", async () => {
    const response = await axios.get(
      `${base_url}/wp-json/softtechit/v1/innaree/allcategories/`
    );
    return response.data;
  });

  useEffect(() => {
    if (queryproductTypesData) {
      setproductTypesData(queryproductTypesData);
    }
  }, [queryproductTypesData]);

  // about page data
  const [aboutData, setaboutData] = useState([]);

  // get all about page data
  const {
    isLoading: aboutIsLoading,
    error: aboutError,
    data: queryaboutData,
  } = useQuery("aboutpage-query-key", async () => {
    const response = await axios.get(
      `${base_url}/wp-json/softtechit/v1/innaree/about/`
    );
    return response.data;
  });

  useEffect(() => {
    if (queryaboutData) {
      setaboutData(queryaboutData);
    }
  }, [queryaboutData]);

  // footer page data
  const [footerData, setfooterData] = useState([]);
  const [footerLoading, setfooterLoading] = useState([]);

  // get all footer page data
  // get single product data from api
  function fetchFooterData() {
    setfooterLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/footer/`)
      .then((res) => {
        setfooterData(res.data);
        // console.log('footer data...', res.data);
        setfooterLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setfooterLoading(false);
      });
  }

  //   get data on load
  useEffect(() => {
    fetchFooterData();
  }, []);

  return (
    <HomepageStoreContext.Provider
      value={{
        // global values
        homeIsLoading,
        homeError,
        homeData,
        setHomeData,
        // producttype
        filterdProductType,
        // about page
        aboutData,
        setaboutData,
        aboutIsLoading,
        aboutError,
        // footer data
        footerData,
        setfooterData,
        footerLoading,
        setfooterLoading,
      }}
    >
      {children}
    </HomepageStoreContext.Provider>
  );
};

export { HomepageStoreContext, HomepageStoreProvider };
