import React from "react";
import { Link } from "react-router-dom";
export default function CommonBreadcrumb({ title, bg }) {
    return (
        <div
            style={{
                backgroundImage: `url(${bg})`,
            }}
            className={`text-white min-320:pt-[160px] min-768:pt-[270px] min-320:pb-[100px] min-768:pb-[260px] bg-center bg-[length:125%_145%] min-768:bg-[length:100%_100%] bg-no-repeat`}
        >
            <div className="grid  min-1024:grid-cols-2 innaree-container min-576:mt-[60px] min-768:mt-0">
                {/* breadcrumb menu */}
                <div className="text-white text-[20px] leading-[26px] capitalize inline-block font-semibold px-4 min-576:px-8">
                    <Link className="" to={"/"}>
                        Home
                    </Link>
                    <span className="inline-block mx-2">/</span>
                    <Link className="" to={"/contact"}>
                        {title}
                    </Link>
                </div>
            </div>
        </div>
    );
}
