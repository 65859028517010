import axios from "axios";
import { Helmet } from "react-helmet";
import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { base_url } from "../Base_url";
import Cart from "../components/cartpage/Cart";
import HelpCounting from "../components/helpingpage/HelpCounting";
import HelpingBreadcrumb from "../components/helpingpage/HelpingBreadcrumb";
import HelpingCta from "../components/helpingpage/HelpingCta";
import InfluenceSec from "../components/helpingpage/InfluenceSec";
import Brandpartner from "../components/homepage/Brandpartner";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Preloader from "../components/loading/Preloader";
import NavbarLeft from "../components/navbar/NavbarLeft";
import Searchbar from "../components/searchbar/Searchbar";
import { HomepageStoreContext } from "../context/HomepageStore";
export default function Helping() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeData, setHomeData } =
    useContext(HomepageStoreContext);
  const { business_partners_data } = homeData;

  /******************************************
   *
   * components functions and state
   *
   ******************************************/
  const [helpingData, sethelpingData] = useState([]);
  const [loading, setLoading] = useState(false);

  function fetchData() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/helping/`)
      .then((res) => {
        sethelpingData(res.data);
        //  console.log('check shop page data',res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  //   // distructure data from value
  const { header, blog, call_to } = helpingData;

  if (loading) {
    return (
      <>
        <Helmet>
          <title>Innaree - helping</title>
        </Helmet>
        <Preloader />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Innaree - helping</title>
        </Helmet>
        <div>
          <Header />
          <NavbarLeft />
          <Searchbar />
          <Cart />
          {header && <HelpingBreadcrumb brdData={header} />}
          {blog && <HelpCounting countingData={blog} />}
          {blog && <InfluenceSec influenceData={blog} />}
          {call_to && <HelpingCta ctaData={call_to} />}
          {business_partners_data && (
            <Brandpartner partnerData={business_partners_data} />
          )}
          <Footer />
        </div>
      </>
    );
  }
}
