import React from "react";
// import DeliveryCar from "../../assets/images/delivery-car.svg";
// import Support from "../../assets/images/support.svg";
// import Offer from "../../assets/images/offer.svg";
// import OnlinePayment from "../../assets/images/online-payment.svg";

const ShippingInfo = ({ shippingData }) => {
  return (
    <div className="shipping-info-area -mt-[80px]">
      <div className="innaree-container">
        <div className="grid min-576:grid-cols-2 min-1024:grid-cols-4 gap-5">
          {/* Single Info */}
          <div
            className="single-info w-[280px] min-576:w-auto mx-auto bg-white px-[23px] py-[30px] shadow-in-boxShadow rounded-2xl duration-500 hover:-translate-y-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            {shippingData.box_1_icon.url && (
              <img
                className="h-[33px] w-[33px] mb-[15px]"
                src={shippingData.box_1_icon.url}
                alt="Car"
              />
            )}

            {shippingData.box_1_title && (
              <strong className="text-[14px] mb-[5px] block">
                {/* {shippingData.box_1_title} */}
                {shippingData.box_1_title && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: shippingData.box_1_title,
                    }}
                  />
                )}
              </strong>
            )}

            {shippingData.box_1_description && (
              <p>
                {shippingData.box_1_description && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: shippingData.box_1_description,
                    }}
                  />
                )}
              </p>
            )}
          </div>

          {/* Single Info */}
          <div
            className="single-info w-[280px] min-576:w-auto mx-auto bg-white px-[23px] py-[30px] shadow-in-boxShadow rounded-2xl duration-500 hover:-translate-y-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            {shippingData.box_2_icon.url && (
              <img
                className="h-[33px] w-[33px] mb-[15px]"
                src={shippingData.box_2_icon.url}
                alt="Support"
              />
            )}
            {shippingData.box_2_title && (
              <strong className="text-[14px] mb-[5px] block">
                {shippingData.box_2_title}
                {shippingData.box_2_title && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: shippingData.box_2_title,
                    }}
                  />
                )}
              </strong>
            )}

            {shippingData.box_2_description && (
              <p>
                {shippingData.box_2_description && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: shippingData.box_2_description,
                    }}
                  />
                )}
              </p>
            )}
          </div>

          {/* Single Info */}
          <div
            className="single-info w-[280px] min-576:w-auto mx-auto bg-white px-[23px] py-[30px] shadow-in-boxShadow rounded-2xl duration-500 hover:-translate-y-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <img
              className="h-[33px] w-[33px] mb-[15px]"
              src={shippingData.box_3_icon.url}
              alt="Offer"
            />

            {shippingData.box_3_title && (
              <strong className="text-[14px] mb-[5px] block">
                {shippingData.box_3_title && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: shippingData.box_3_title,
                    }}
                  />
                )}
              </strong>
            )}

            {shippingData.box_3_description && (
              <p>
                {/* {shippingData.box_3_description} */}
                {shippingData.box_3_description && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: shippingData.box_3_description,
                    }}
                  />
                )}
              </p>
            )}
          </div>

          {/* Single Info */}
          <div
            className="single-info w-[280px] min-576:w-auto mx-auto bg-white px-[23px] py-[30px] shadow-in-boxShadow rounded-2xl duration-500 hover:-translate-y-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="900"
          >
            <img
              className="h-[33px] w-[33px] mb-[15px]"
              src={shippingData.box_4_icon.url}
              alt="OnlinePayment"
            />
            {shippingData.box_4_title && (
              <strong className="text-[14px] mb-[5px] block">
                {shippingData.box_4_title && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: shippingData.box_4_title,
                    }}
                  />
                )}
              </strong>
            )}

            {shippingData.box_4_description && (
              <p>
                {/* {shippingData.box_4_description} */}
                {shippingData.box_4_description && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: shippingData.box_4_description,
                    }}
                  />
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingInfo;
