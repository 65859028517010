import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
// import Prodcut9 from "../../assets/images/prodcut9.webp";
import { RiStarFill, RiStarHalfFill } from "react-icons/ri";

const CustomerReview = ({ leftSideContent }) => {
  // console.log('here is the culprit',leftSideContent);
  // return 'hello'
  if (leftSideContent) {
    return (
      <div className="customer-review mt-[100px] mb-[70px]">
        <div className="innaree-container">
          <div className="grid min-768:grid-cols-2 gap-4 place-content-start">
            {/* Customer Saying */}
            {leftSideContent && (
              <div>
                <Titlebar
                  smTitle={leftSideContent[0].sub_title}
                  lgTitle={leftSideContent[0].title}
                  ClassForWrapper={"max-w-[360px] mb-[30px]"}
                />
                {leftSideContent[0].image.url && (
                  <img
                    className="max-w-[250px] min-1024:max-w-[380px] min-1250:max-w-[470px] mb-[30px] min-768:mb-0"
                    src={leftSideContent[0].image.url}
                    alt="product"
                  />
                )}
              </div>
            )}

            {/* Ratings */}
            <div>
              {/* Rating 1 */}
              <div className="border-b border-innaree-secondary-color mb-[27px]">
                <div className="customer-name flex items-end mb-4">
                  <strong className="text-innaree-black-color text-[14px] font-bold mr-3 capitalize">
                    ahsam khan
                  </strong>
                  <div className="badge">
                    <span className="text-[12px]">Verified Buyer</span>
                  </div>
                </div>
                <div className="rating flex items-center mb-4">
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                </div>
                <h6 className="font-semibold text-[14px] leading-[24px] mb-2">
                  CBD Oil Drops
                </h6>
                <p className="font-[400] text-[14px] leading-[24px] mb-3">
                  Having tried a few CBD oils I discovered Innaree, and I have
                  to say it is a fantastic product with a variety of other items
                  such as the muscle rub that I think works wonders for pain and
                  muscle fatigue. It was important to find 0% thc and also
                  comforting to know this is halal certified too. The oil drops
                  are of a high quality and grade also the costs are much more
                  affordable than other brands. If you're looking for a quality
                  product in CBD then you should give Innaree a try.
                </p>
              </div>

              {/* Rating 2 */}
              <div className="border-b border-innaree-secondary-color mb-[27px]">
                <div className="customer-name flex items-end mb-4">
                  <strong className="text-innaree-black-color text-[14px] font-bold mr-3 capitalize">
                    tina ali
                  </strong>
                  <div className="badge">
                    <span className="text-[12px]">Verified Buyer</span>
                  </div>
                </div>
                <div className="rating flex items-center mb-4">
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                </div>
                <h6 className="font-semibold text-[14px] leading-[24px] mb-2">
                  CBD Oil Drops
                </h6>
                <p className="font-[400] text-[14px] leading-[24px] mb-3">
                  I’ve been suffering from having broken sleep which has left me
                  feeling low and tired throughout the day. I came across this
                  Innaree CBD and what made me go with this one over all the
                  others is the ingredients! Pure CBD organic without any THC
                  and 100% halal which made a huge difference for me. After
                  using it for just 2 days I have noticed a big difference in
                  the quality of my sleep. I wake up feeling so much more
                  refreshed and ready for the day. My colleagues have also
                  noticed the difference in my general mood. All in all it has
                  done wonders for me and I 200% recommend this!
                </p>
              </div>

              {/* Rating 3 */}
              <div className="border-b border-innaree-secondary-color mb-[27px]">
                <div className="customer-name flex items-end mb-4">
                  <strong className="text-innaree-black-color text-[14px] font-bold mr-3">
                    Jabber Mir
                  </strong>
                  <div className="badge">
                    <span className="text-[12px]">Verified Buyer</span>
                  </div>
                </div>
                <div className="rating flex items-center mb-4">
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                </div>
                <h6 className="font-semibold text-[14px] leading-[24px] mb-2">
                  CBD Oil Drops
                </h6>
                <p className="font-[400] text-[14px] leading-[24px] mb-3">
                  Outstanding product and service received from Innaree. Exactly
                  what I needed.
                </p>
              </div>

              {/* Rating 4 */}
              <div>
                <div className="customer-name flex items-end mb-4">
                  <strong className="text-innaree-black-color text-[14px] font-bold mr-3">
                    Lina P
                  </strong>
                  <div className="badge">
                    <span className="text-[12px]">Verified Buyer</span>
                  </div>
                </div>
                <div className="rating flex items-center mb-4">
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                  <RiStarFill className="text-in-rating-color-golden" />
                </div>
                <h6 className="font-semibold text-[14px] leading-[24px] mb-2">
                  Muscle Rub
                </h6>
                <p className="font-[400] text-[14px] leading-[24px] mb-3">
                  Love the Innaree lip balm and the muscle rub.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CustomerReview;
