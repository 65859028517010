import React from "react";
import { Link } from "react-router-dom";
import AboutBreadcrumbBg from "../../assets/images/about-breadcrumb-bg.webp";

const AboutBreadcrumb = ({ brdData }) => {
  return (
    <div
      // className="about-breadcrumb h-auto pt-[200px] min-768:pt-[280px] pb-[50px] min-768:pb-[297px] bg-no-repeat bg-[top_center] bg-cover  min-1024:bg-[length:200%_100%] min-1250:bg-[length:100%_100%] min-576:bg-[center_top] min-1024:bg-center bg-none"
      className="about-breadcrumb  pt-[200px] min-768:pt-[280px] pb-[50px] min-768:pb-[297px] bg-no-repeat bg-center bg-none"
      style={{
        backgroundImage: `url(${
          brdData.background.url ? brdData.background.url : AboutBreadcrumbBg
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <div className="innaree-container relative">
        <div className="grid grid-cols-12 gap-0 min-768:pl-12 min-1024:pl-0">
          <div className="breadcrumb-itmes col-span-12 min-1024:col-span-3 min-1440:col-span-2 text-white font-medium pl-0 min-1024:pl-[50px] min-1440:pl-0 mb-[30px] min-1024:mb-0">
            <Link className="text-[20px] font-semibold" to="/">
              Home /
            </Link>
            <span className="text-[20px] font-semibold"> About us</span>
          </div>
          {brdData.title_middle && (
            <div
              className="breadcrumb-contents col-span-12 min-1024:min-1024:col-span-6 min-1250:col-span-5 min-1024:pl-14 min-1250:pl-12 min-768:mt-[70px] bg-innaree-secondary-rgba backdrop-blur-[10px] rounded-b-2xl rounded-tr-2xl max-w-[412px] p-[25px] min-576:py-[52px] min-576:px-[48px]"
              data-aos="flip-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1300"
            >
              <h3
                className="text-white text-[20px] min-576:text-[28px] font-semibold"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                {brdData.title_middle && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: brdData.title_middle,
                    }}
                  />
                )}
              </h3>
            </div>
          )}
        </div>
        {brdData.title_left && (
          <div
            className="breadcrumb-contents2 bg-innaree-black-rgba backdrop-blur-[2px] rounded-2xl max-w-[320px] p-[25px] min-576:py-[52px] min-576:px-[40px] min-768:absolute right-0 min-1024:right-[100px] min-768:bottom-[-85%] min-1024:bottom-[-90%] min-1250:bottom-[-85%] mt-[20px] min-768:mt-0"
            data-aos="flip-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1300"
            data-aos-anchor-placement="top-bottom"
          >
            <h3
              className="text-white text-[20px] min-576:text-[25px] min-1250:text-[28px] font-semibold"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="500"
              data-aos-anchor-placement="top-bottom"
            >
              {brdData.title_left && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: brdData.title_left,
                  }}
                />
              )}
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutBreadcrumb;
