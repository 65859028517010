import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./animation.css";
import '../src/assets/css/wordpress-core.css';
import App from "./App";
import { StoreProvider } from "./context/Store";
import { HomepageStoreProvider } from "./context/HomepageStore";
import { QueryClient, QueryClientProvider } from 'react-query';
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <HomepageStoreProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HomepageStoreProvider>
      </StoreProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
