import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { base_url } from "../../Base_url";
import { Titlebar } from "../titlebar/Titlebar";
import { Fa500Px } from "react-icons/fa";
import { CgArrowRight } from "react-icons/cg";

export default function HomeBlogSection() {
  // single product data
  const [latestBlogs, setlatestBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  // get single product data from api
  function fetchBlodData() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/latestposts/`)
      .then((res) => {
        setlatestBlogs(res.data);
        // console.log("latest bloggss", res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // first get single post data ::end
  }

  //   get data on load
  useEffect(() => {
    fetchBlodData();
  }, []);

  {
    if (loading) {
      return null;
    } else {
      return (
        <div className="innaree-container mt-[100px] mb-[10px]">
          <Titlebar
            lgTitle="Our latest blogs"
            ClassForWrapper="text w-[300px] px-4 py-4 mb-4"
            ClassForsmTitle="text-innaree-secondary-color"
            ClassForlgTitle=""
          />
          <div className="grid min-768:grid-cols-2 min-1024:grid-cols-3 gap-10">
            {latestBlogs &&
              latestBlogs.map((item) => (
                <div key={`blog_key_1${item.ID}`}>
                  <div className="shadow-in-boxShadow p-4  transition-all delay-75 group">
                    <div className="object-contain">
                      <img
                        className="w-full h-[200px] object-cover group-hover:scale-[.98] transition-all delay-75"
                        src={item.post_thumnail[0] && item.post_thumnail[0]}
                      />
                    </div>
                    <div className="relative col-span-2 mt-4">
                      <div className="inline-flex items-center ">
                        <div className="flex items-center">
                          <span className=" mb-1 text-[16px] mr-4 text-innaree-secondary-rgba capitalize">
                            {item.post_categories && item.post_categories}
                          </span>
                          <p className=" opacity-50 text-xs">
                            <Moment format="YYYY/MM/DD">
                              {item.post_date && item.post_date}
                            </Moment>
                          </p>
                        </div>
                      </div>
                      <Link
                        to={`/blog/${item.slug}`}
                        className="leading-[30px] rounded-lg block mb-4 text-[24px] capitalize font-bold cursor-pointer hover:text-innaree-primary-color"
                      >
                        {item.post_title && item.post_title && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.post_title && item.post_title,
                            }}
                          />
                        )}
                      </Link>

                      <p className="mb-4  block">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.post_excerpt && item.post_excerpt,
                          }}
                        />
                        {/* {item.post_excerpt && item.post_excerpt} */}
                        <Link
                          to={`/blog/${item.slug}`}
                          className="text-[28px] text-innaree-secondary-color hover:text-black cursor-pointer mt-4 block group-hover:translate-x-[7px] transition-all delay-75"
                        >
                          <CgArrowRight />
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-center mt-[30px]">
            <Link
              to={"/blog"}
              className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500`}
            >
              View all
            </Link>
          </div>
        </div>
      );
    }
  }
}
