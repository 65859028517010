// local dev
// export let base_url = "http://localhost/innaree_wp";

// live
// export const base_url = "https://innaree.testapplication.xyz";
// export const base_url = "https://api.innaree.com";
export const base_url = "https://api.innaree.shop";

export const viva_demo_payment_url = "https://demo.vivapayments.com";
export const viva_live_payment_url = "https://www.vivapayments.com";

// https://www.vivapayments.com/web/checkout?ref=%7BOrderCode%7D
// https://demo.vivapayments.com/web/checkout?ref=%7BOrderCode%7D



