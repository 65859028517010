import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import "../components/shopdetailspage/shopdetails.scss";
import "../components/singleproduct/SingleProduct.scss";
import Footer from "../components/layout/Footer";
import Cart from "../components/cartpage/Cart";
import Searchbar from "../components/searchbar/Searchbar";
import axios from "axios";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";
import PrivacyPolicyImg from "../assets/images/cookie-policy-header.jpg";
import CommonBreadcrumb from "../components/commonBreadcrumb/CommonBreadcrumb";
import { Link } from "react-router-dom";

export default function CookiePolicy() {
  // single product data
  const [privacyPolicy, setprivacyPolicy] = useState([]);
  const [loading, setLoading] = useState(false);

  // functions
  // get single product data from api
  function fetchData() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/cookiepolicy`)
      .then((res) => {
        setprivacyPolicy(res.data);
        //  console.log('check shop page data',res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  }

  // get single product related data

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Innaree - Cookie Policy</title>
      </Helmet>

      {loading
        ? [<Preloader />]
        : [
          <div className="body-bg">
            <Header />
            <NavbarLeft />
            <Cart />
            <Searchbar />
            <CommonBreadcrumb bg={privacyPolicy.featured_img ? privacyPolicy.featured_img : PrivacyPolicyImg} title={"Cookie Policy"} />
            <div className="innaree-container my-[100px]">
              {privacyPolicy
                ? [
                  <div
                    className="privacy-content-style"
                    dangerouslySetInnerHTML={{
                      __html: privacyPolicy.content,
                    }}
                  />,
                ]
                : [
                  <>
                    <div className="flex flex-col align-middle justify-center">
                      <h1 className="text-center w-3/4 font-bold text-[24px] mx-auto">
                        Sorry but the page you are looking for does not
                        exist, may have been removed, the name may have been
                        changed or it is temporarily unavailable.
                      </h1>

                      <Link
                        to="/"
                        className="text-white font-semibold uppercase bg-innaree-secondary-color text-left mt-[50px] mx-auto px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500"
                      >
                        back to home
                      </Link>
                    </div>
                  </>,
                ]}
            </div>
            <Footer />
          </div>,
        ]}
    </>
  );
}
