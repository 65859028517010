import React, { useEffect, useState, useRef, useContext } from "react";
import { HiShoppingBag } from "react-icons/hi";
import { RiStarFill, RiStarHalfFill } from "react-icons/ri";
import { TemplateButton } from "../buttons/Button";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import parse from "html-react-parser";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { StoreContext } from "../../context/Store";
import StarRating from "../../helper/StarRating";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import ImageGallery from "../../package/react-image-gallery/build/image-gallery";
// import "../../package/react-image-gallery/styles/css/image-gallery.css";
import Scrollbars from "react-custom-scrollbars";

export default function AddToCartProduct({ singleProduct }) {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    // show cart
    showCart,
    setshowCart,
    cartItems,
    setcartItems,
    removeItemFromCart,
    incProductQty,
    decProductQty,
    addItemToCart,
    addItemToCartForSingleItem,
    subtotal,
    addNewItemToCartForSingleItem,
    addExiestingItemToCartForSingleItem,
  } = useContext(StoreContext);
  // local state
  const swiperAddToCartProductRef = useRef();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showMore, setshowMore] = useState(false);
  const [qty, setQty] = useState(1);
  const [sliderThumDir, setsliderThumDir] = useState('right');

  // check screen size
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  // handle add to cart (check if item already added to cart)
  const handleAddToCart = (product, qty) => {
    if (cartItems.some((item) => item.id == product.id)) {
      // console.log("hello cart is full");
      // toast("Product already added!!.");
      addExiestingItemToCartForSingleItem(product, qty);
    } else {
      addNewItemToCartForSingleItem(product, qty);
    }

    toast.custom(
      <div>
        <div
          id="toast-success"
          className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
          role="alert"
        >
          <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
            <svg
              aria-hidden="true"
              className="w-3 h-3 rounded-[50%]"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Check icon</span>
          </div>
          <div className="px-4 text-sm font-normal">
            Product Added Successfully
          </div>
        </div>
      </div>
    );
  };

  const handleIncrease = () => {
    setQty(qty + 1);
  };

  const handleDecrease = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  useEffect(() => {
    // for mobile deic
    if (screenWidth < 1023) {
      setsliderThumDir('bottom');
    }
    // 
    else {
      setsliderThumDir('right');
    }
  }, [screenWidth]);




  return (
    <div className="relative min-768:w-[720px] min-1024:w-[920px] min-1250:w-[980px] min-1440:w-[1140px] min-768:mt-[-220px]  mx-auto min-768:rounded-[20px]  bg-[#ffffffe5] shadow-[0_1px_1px_rgba(0,0,0,0.3)] min-768:shadow-[0_5px_20px_rgba(0,0,0,0.3)] backdrop-filter-20px overflow-hidden">
      <div className="grid grid-cols-12">
        {singleProduct.product_gallery && (
          <div className="col-span-12 min-1024:col-span-5 border-r  rounded-tr-[20px] rounded-br-[20px] 1024:mt-[60px]  react_img_gal_cls overflow-hidden">
            <ImageGallery
              items={singleProduct.product_gallery}
              fullscreen={true}
              showThumbnails={true}
              thumbnailPosition={sliderThumDir}
              // originalClass='w-[476px] h-[637px] object-cover'
              showPlayButton={false}
            />
          </div>
        )}
        {/* product description */}
        <div className="col-span-12 min-1024:col-span-7 relative  py-[40px] px-[40px] ">
          {/* <Scrollbars style={{ width: "100%", height: 400, overflowX: "hidden" }}> */}
          <div className=" absolute top-0 min-1024:top-[-2%] right-0 min-1024:right-[-7%]">
            <div className="w-[150px] h-[130px] min-1024:w-[240px] min-1024:h-[180px] relative">
              <span className="text-[14px] min-1024:text-[24px] leading-[29px] font-bold text-white absolute top-[35%] left-[34%]">
                {/* £48.99£ */}£{singleProduct.price && singleProduct.price}
              </span>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 185 183"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_1_835)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M91.3669 26.7031C103.841 24.6311 118.124 27.1398 127.468 34.2169C136.524 41.0759 135.401 52.5896 138.464 62.3747C141.279 71.371 146.405 79.906 144.638 89.0795C142.661 99.3385 137.444 109.279 128.078 116.282C118.03 123.795 104.957 128.362 91.3669 128.913C77.3277 129.483 62.0504 127.347 51.9141 119.372C42.1925 111.724 40.8618 99.5751 40.0684 88.5547C39.4295 79.6798 43.3701 71.4221 47.8798 63.3351C51.887 56.149 57.8158 50.3343 64.5403 44.6698C72.7946 37.7167 79.7614 28.6309 91.3669 26.7031Z"
                    fill="#4B3F72"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_1_835"
                    x="0.00012207"
                    y="0"
                    width="185"
                    height="183"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="14" />
                    <feGaussianBlur stdDeviation="20" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1_835"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1_835"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>

          <h2 className="text-[24px] font-[300] leading-[42px] capitalize mb-0">
            {singleProduct && (
              <span dangerouslySetInnerHTML={{ __html: singleProduct.title }} />
            )}
          </h2>
          <h2 className="text-[32px] font-bold leading-[42px] capitalize">
            {singleProduct.sub_title && (
              <span
                dangerouslySetInnerHTML={{ __html: singleProduct.sub_title }}
              />
            )}
          </h2>

          <div className="flex align-items-center mt-[30px]">
            <StarRating
              averageRating={singleProduct.average_ratings}
              review_count={singleProduct.review_count}
            />
          </div>

          {/* <h6 className="font-semibold text-[14px] leading-[24px] mb-2">
            Free yourself and start shaping your world
          </h6> */}
          <p className="font-[400] text-[14px] leading-[24px] mb-3">
            {" "}
            {/* 3.5 grams of exceptional CREATE Flowers rich in CBD that stimulate
            creativity and your capacity to break down boundaries, 100% natural
            and nicotine-free. */}
            {/* {singleProduct &&  parse(singleProduct.product_descriotion)} */}
            <Scrollbars
              style={{ width: "100%", height: 200, overflowX: "hidden" }}
            >
              {showMore
                ? [
                    singleProduct.product_descriotion && (
                      <>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: singleProduct.product_descriotion,
                          }}
                        />
                        <button
                          className="text-innaree-secondary-color capitalize mt-[20px] block cursor-pointer relative z-[99999]"
                          onClick={() => setshowMore(false)}
                        >
                          show less
                        </button>
                      </>
                    ),
                  ]
                : [
                    singleProduct.product_descriotion && (
                      <>
                        <span
                      dangerouslySetInnerHTML={{
                        __html: singleProduct.product_descriotion.split(' ').slice(0,40).join(' '),
                      }}
                    />
                        <button
                          className="text-innaree-secondary-color capitalize mt-[20px] block cursor-pointer  relative z-[99999]"
                          onClick={() => setshowMore(true)}
                        >
                          read more
                        </button>
                      </>
                    ),
                  ]}
            </Scrollbars>
            {/* console.log(singleProduct.product_descriotion); */}
            {/* {console.log(
              singleProduct.product_descriotion &&
                singleProduct.product_descriotion.length
            )} */}
          </p>

          <div className="flex items-center">
            <h5 className="font-bold text-[20px] leading-[26px]">Quantity</h5>
            <div className="w-[90px] h-[60px] rounded-[9px] ml-8 bg-innaree-primary-color flex justify-center items-center">
              <span
                onClick={handleDecrease}
                className="w-[15px] h-[15px] bg-innaree-secondary-color text-white rounded cursor-pointer flex justify-center items-center"
              >
                -
              </span>
              <span className="text-[32px] font-bold leading-[24px] text-white px-3">
                {/* {singleProduct.qty} */}
                {qty}
              </span>
              <span
                onClick={handleIncrease}
                className="w-[15px] h-[15px] bg-innaree-secondary-color text-white rounded cursor-pointer leading-[1px] flex justify-center items-center"
              >
                +
              </span>
            </div>
          </div>

          <button
            onClick={() => handleAddToCart(singleProduct, qty)}
            type="button"
            className={`rounded-[50px] mt-[60px] font-red-hat text-white bg-innaree-primary-color border-2 border-transparent font-medium text-[12px] px-[20px] min-1024:px-[80px] py-[7px]  hover:drop-shadow-5xl hover:-translate-y-1 duration-500 flex`}
          >
            <HiShoppingBag className="mr-2 text-[12px]" />
            <span>Add to Shopping Bag</span>
          </button>
          {/* </Scrollbars> */}
        </div>
      </div>
    </div>
  );
}
