import React from "react";

export function Titlebar({
  smTitle = "",
  lgTitle = "",
  ClassForWrapper = "",
  ClassForsmTitle = "text-innaree-secondary-color",
  ClassForlgTitle = "text-innaree-black-color",
}) {
  return (
    <div
      className={`${ClassForWrapper}`}
      data-aos="zoom-in"
      data-aos-easing="ease-out-cubic"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <span
        className={`font-semibold font-red-hat text-[20px] ${ClassForsmTitle}`}
      >
        {smTitle && (
          <span
            dangerouslySetInnerHTML={{
              __html: smTitle,
            }}
          />
        )}
      </span>
      <h3 className={`font-bold font-red-hat  text-[32px] ${ClassForlgTitle}`}>
        {lgTitle && (
          <span
            dangerouslySetInnerHTML={{
              __html: lgTitle,
            }}
          />
        )}
      </h3>
    </div>
  );
}
