import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { ButtonEight } from "../../buttons/Button";
// import Prodcut10 from "../../../assets/images/prodcut10.jpg";
import { StoreContext } from "../../../context/Store";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export default function Profile({ orders, userInfo }) {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { loading, setLoading, setprofileSteps } = useContext(StoreContext);

  /*********************************************
   *
   *  pagination functions start here
   *
   * *******************************************/
  // current items component
  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((order) => (
            <div key={order.order_id}>
              <div className="flex items-center mb-4">
                <Moment format="YYYY/MM/DD">{order.date_created.date}</Moment>

                <div className="ml-[30px] uppercase text-bold">
                  <span>order id: #{order.order_id}</span>
                </div>
              </div>

              {order.order_items &&
                order.order_items.map((order_items, index) => (
                  <div
                    key={`orders_items_22_${index}`}
                    className="cart-item mb-[30px] flex items-start min-1024:items-center justify-start mr-[30px]  border-b border-innnree-border-darkgray pb-[20px]"
                  >
                    <div className="product-img h-[100px] w-[100px] mr-[35px] min-1024:mr-[10px] flex justify-center items-center">
                      <img src={order_items.product_img} alt="product" />
                      {/* <img src={Prodcut10} alt="product" /> */}
                    </div>
                    <div className="prodcut-details  min-1024:flex grow items-center justify-between">
                      <div className="prodcut-heading">
                        <div className="prodcut-title text-[14px] font-bold mb-[5px]">
                          {order_items.product_name}
                        </div>
                        <div className="prodcut-category mb-[5px] min-1024:mb-0">
                          <span className="text-[14px]">
                            Quantity: {order_items.quantity}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="cart-final text-end">
                      <div className="prodcut-price">
                        <span className="text-[14px] font-bold">
                          £{order_items.total}
                        </span>
                      </div>
                      {/* <div className="item-remove">
                    <button className="text-[14px] font-normal text-black hover:text-in-dark-golden">
                      Cancel Order
                    </button>
                  </div> */}
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = orders.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(orders.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % orders.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
    };

    // pageCount = { 20}
    // pageRangeDisplayed = { 4}
    // marginPagesDisplayed = { 2}

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={4}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< previous"
          containerClassName="flex flex-wrap"
          previousClassName="m-4 capitalize hover:bg-innaree-secondary-color px-[15px] text-center hover:text-white rounded-full leading-[30px]"
          nextClassName="m-4 capitalize hover:bg-innaree-secondary-color px-[15px]  text-center hover:text-white rounded-full leading-[30px]"
          pageClassName="m-4  hover:bg-innaree-secondary-color w-[30px] h-[30px]  text-center hover:text-white rounded-full leading-[30px]"
          activeLinkClassName="bg-innaree-secondary-color  text-center text-white rounded-full  px-4 py-2"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  /*********************************************
   *
   *  pagination functions ends here
   *
   * *******************************************/

  return (
    <div className="profile-page py-[80px] px-[20px]">
      <div className="innaree-container">
        <h2 className="text-[48px] font-semibold">
          Hello,{" "}
          <span>
            {userInfo.first_name} {userInfo.last_name}
          </span>
        </h2>
        <div className="min-1024:grid grid-cols-12 gap-10">
          {/* Orders */}
          <div className="col-span-7">
            <h4 className="text-[24px] font-semibold mb-[30px]">Order list</h4>
            {/* if user dont place any order */}
            {/* <div>
              <p className="text-[16px] mb-[20px]">
                You haven't placed any orders yet.
              </p>
            </div> */}

            {/* if user orderd */}
            <div className="orders">
              <div className="added-prodcuts">
                {/* Single Item */}
                {/* {
                  orders && orders.map((order) => (
                    <div key={order.order_id}>

                      <div className="flex items-center mb-4">
                        <Moment format="YYYY/MM/DD">
                          {order.date_created.date}
                        </Moment>

                        <div className="ml-[30px] uppercase text-bold">
                          <span>order id: #{order.order_id}</span>
                        </div>
                      </div>

                      {order.order_items && order.order_items.map((order_items, index) => (

                        <div key={`orders_items_22_${index}`} className="cart-item mb-[30px] flex items-start min-1024:items-center justify-start mr-[30px]  border-b border-innnree-border-darkgray pb-[20px]">
                          <div className="product-img h-[100px] w-[100px] mr-[35px] min-1024:mr-[10px] flex justify-center items-center">
                            <img src={order_items.product_img} alt="product" />
                          </div>
                          <div className="prodcut-details  min-1024:flex grow items-center justify-between">
                            <div className="prodcut-heading">
                              <div className="prodcut-title text-[14px] font-bold mb-[5px]">
                                {order_items.product_name}
                              </div>
                              <div className="prodcut-category mb-[5px] min-1024:mb-0">
                                <span className="text-[14px]">Quantity: {order_items.quantity}</span>
                              </div>
                            </div>
                          </div>
                          <div className="cart-final text-end">
                            <div className="prodcut-price">
                              <span className="text-[14px] font-bold">£{order_items.total}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))
                } */}
                <PaginatedItems itemsPerPage={2} />
              </div>
            </div>

            <Link
              to={"/shop"}
              className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 inline-block my-[30px]`}
            >
              START SHOPPING
            </Link>
          </div>

          {/* Account Details */}
          <div className="col-span-5">
            <h4 className="text-[24px] font-semibold mb-[30px]">
              Account Details
            </h4>
            <h5 className="text-[20px] font-semibold">
              {userInfo.first_name && userInfo.first_name} {""}
              {userInfo.last_name && userInfo.last_name}
            </h5>

            {/* <p className="text-[16px]">{userInfo.billing_address_1 && userInfo.billing_address_1}</p>
            <p className="text-[16px]">{userInfo.billing_address_2 && userInfo.billing_address_2}</p>
            <p className="text-[16px]">{userInfo.billing_city && userInfo.billing_city}</p>
            <p className="text-[16px]">{userInfo.billing_country && userInfo.billing_country}</p>
            <p className="text-[16px]">{userInfo.billing_email && userInfo.billing_email}</p>
            <p className="text-[16px]">{userInfo.billing_phone && userInfo.billing_phone}</p>
            <p className="text-[16px]">{userInfo.billing_postcode && userInfo.billing_postcode}</p>
            <p className="text-[16px]">{userInfo.billing_state && userInfo.billing_state}</p>
            <p className="text-[16px]">{userInfo.city && userInfo.city}</p> */}
            <p className="text-[16px]">
              {userInfo.user_email && userInfo.user_email}
            </p>
            {/* <p className="text-[16px]">Village Vila</p>
            <p className="text-[16px]">Dhaka 1230</p>
            <p className="text-[16px]">United Kingdom</p> */}
            <button
              type="button"
              className="underline text-[20px] font-semibold mt-[20px] inline-block"
              onClick={() => setprofileSteps(2)}
            >
              View Addresses
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
