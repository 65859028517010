import React from "react";
import { Link } from "react-router-dom";
import FaqShape1 from "../../assets/images/faq-breadcrumb-shape.png";
import FaqShape2 from "../../assets/images/faq-breadcrumb-shape2.webp";
import ProdutShape from "../../assets/images/produt-shape.png";
import Logo from "../../assets/images/helping_logo.png";

const FaqBreadcrumb = ({ brdData }) => {
  return (
    <div
      // className="faq-area h-auto pt-[120px] min-576:pt-[245px] pb-[50px] min-768:pb-[145px] bg-no-repeat bg-cover bg-[length:123%_119%] min-1024:bg-[length:100%_100%] bg-top min-1024:bg-center bg-none"
      className="faq-area h-auto pt-[120px] min-576:pt-[245px] pb-[50px] min-768:pb-[145px] bg-no-repeat bg-center bg-none"
      style={{
        backgroundImage: `url(${
          brdData.background.url ? brdData.background.url : FaqShape1
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <div className="innaree-container relative">
        <img
          className="hidden min-768:block max-w-[163px] absolute right-0 min-1250:right-[14%] min-1440:right-[21%] bottom-[-10%] z-[20]"
          src={Logo}
          alt="Innaree"
          data-aos="zoom-in-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          data-aos-delay="1500"
        />
        <div className="breadcrumb-itmes text-white font-medium pl-0 min-1024:pl-[50px] min-1440:pl-0 mb-[13px] min-768:mb-[30px] min-1024:mb-0">
          <div
            className="text-[20px] min-768:absolute min-576:top-0 min-768:top-[17%] min-1024:top-[19%] min-768:left-[16%] min-1024:left-0"
            data-aos="fade-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-delay="800"
          >
            <Link to="/">Home</Link> / FAQ
          </div>
        </div>
        <div className="breadcrumb-contents min-1024:pl-14 min-1250:pl-12">
          <div
            className="breadcrumb-contents-wrapper min-576:bg-none min-576:w-[540px] min-768:w-[720px] min-1024:w-[862px] h-auto px-0 min-768:px-[100px] min-768:pt-[127px] min-768:pb-[127px] bg-no-repeat bg-[length:100%_100%] bg-center rounded-2xl bg-img-none-768"
            style={{ backgroundImage: `url(${FaqShape2})` }}
            data-aos="flip-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <h3
              className="text-white text-[28px] min-1024:text-[20px] min-1250:text-[32px] font-bold min-1024:absolute min-768:bottom-[-25%]  min-1024:bottom-[-29%] min-768:left-[-12px] min-1024:left-[15%] min-1250:left-[-3%] min-1440:left-[4%] mb-4 min-1024:mb-0"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="800"
            >
              {brdData.text_bottom && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: brdData.text_bottom,
                  }}
                />
              )}
            </h3>
            <p
              className="text-white text-[16px] min-1024:text-[28px] font-semibold"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="800"
            >
              {brdData.text_top && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: brdData.text_top,
                  }}
                />
              )}
            </p>
          </div>
        </div>
        <img
          className="hidden min-1024:block absolute min-1024:top-[1%] min-1250:top-[8%] min-1024:right-[-3%] min-1250:right-[-2%]  min-1024:w-[210px] min-1250:w-[341px] min-1440:w-[426px]"
          src={ProdutShape}
          alt="ProdutShape"
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          data-aos-delay="1500"
        />
      </div>
    </div>
  );
};

export default FaqBreadcrumb;
