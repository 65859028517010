import React, { useState, useEffect } from "react";
import flower from "../../assets/images/flower.webp";
import halal_logo_rotate from "../../assets/images/halal_logo_rotate.webp";
import buy_now from "../../assets/images/buy_now.png";
import { Link } from "react-router-dom";
import "./HeroSlide.scss";
export default function HeroSlide({
  key,
  section_wrapper_class,
  section_id,
  section_bg_color,
  // slider img
  mainImage,
  topLeftImg,
  topMidImg,
  topRightImg,
  btmLeftImg,
  btmRightImg,

  // product_slug
  product_slug,

  // slider img class
  mainImage_class,
  topLeftImg_class,
  topMidImg_class,
  topRightImg_class,
  btmLeftImg_class,
  btmRightImg_class,

  // translate3d
  topMidImg_translate3d,
  topRightImg_translate3d,
  btmLeftImg_translate3d,
  btmRightImg_translate3d,

  // slider content
  left_sm_title,
  left_lg_title,
  right_lg_title,
}) {
  return (
    <div
      style={{
        backgroundColor: section_bg_color,
      }}
      id={section_id}
      className={`hero-sec relative hero section w-full overflow-hidden h-[768px] min-768:h-[750px] min-1250:h-[750px] min-1440:h-screen pt-[100px] min-576:pt-[200px] pb-[100px] bg-fixed z-10 ${section_wrapper_class}`}
    >
      <div className="img-group">
        {/* topLeftImg */}
        {topLeftImg && (
          <img
            alt="topLeftImg"
            src={topLeftImg}
            className={`${topLeftImg_class}`}
          />
        )}
        {/* flower image */}
        <img
          data-val="3"
          alt="flower"
          src={flower}
          className="flower w-[100px] min-576:w-[100px] min-768:w-[200px] min-1440:w-[299px] absolute left-[2%] min-768:left-[12%] top-[23%] z-0 img-single"
        />
        {/* topMidImg */}
        {topMidImg && (
          <img
            style={{
              transform: `translate3d(${
                topMidImg_translate3d && topMidImg_translate3d.x
              }px, ${topMidImg_translate3d && topMidImg_translate3d.y}px,0)`,
            }}
            alt="topMidImg"
            data-val="3"
            src={topMidImg}
            className={`${topMidImg_class}`}
          />
        )}
        {/* topRightImg */}
        {topRightImg && (
          <img
            style={{
              transform: `translate3d(${
                topRightImg_translate3d && topRightImg_translate3d.x
              }px, ${
                topRightImg_translate3d && topRightImg_translate3d.y
              }px,0)`,
            }}
            data-val="3"
            alt="topRightImg"
            src={topRightImg}
            className={`${topRightImg_class}`}
          />
        )}
        {/* btmLeftImg */}
        {btmLeftImg && (
          <img
            style={{
              transform: `translate3d(${
                btmLeftImg_translate3d && btmLeftImg_translate3d.x
              }px, ${btmLeftImg_translate3d && btmLeftImg_translate3d.y}px,0)`,
            }}
            data-val="3"
            alt="btmLeftImg"
            src={btmLeftImg}
            className={`${btmLeftImg_class}`}
          />
        )}
        {/* btmRightImg */}
        {btmRightImg && (
          <img
            style={{
              transform: `translate3d(${
                btmRightImg_translate3d && btmRightImg_translate3d.x
              }px, ${
                btmRightImg_translate3d && btmRightImg_translate3d.y
              }px,0)`,
            }}
            data-val="3"
            alt="btmRightImg"
            src={btmRightImg}
            className={`${btmRightImg_class}`}
          />
        )}
        {/* halal_logo_rotate */}
        {halal_logo_rotate && (
          <img
            alt="halal_logo_rotate"
            src={halal_logo_rotate}
            className="halal_logo_rotate animate-rotate-circle w-[80px] min-768:w-[137px] absolute left-[75%] min-768:left-[80%] min-1024:left-[80%] min-1250:left-[50%] top-[25%] min-768:top-[35%] min-1024:top-[45%] min-1250:top-[25%]"
          />
        )}
      </div>

      <div className="grid grid-cols-12 place-items-center h-full innaree-container relative">
        {/* left side */}
        <div className="left-title col-span-12 min-1024:col-span-4 1024:mb-8 text-center flex flex-col min-1024:place-self-center relative top-[15%]">
          <span className="text-white font-red-hat font-normal leading-[26.46px] text-[16px] min-768:text-[20px]">
            {/* {left_sm_title} */}
            {left_sm_title && (
              <span dangerouslySetInnerHTML={{ __html: left_sm_title }} />
            )}
          </span>
          <span className="text-white font-red-hat font-bold leading-[30px] min-768:leading-[52.92px] text-[24px] min-768:text-[40px] mt-2 slide-text">
            {left_lg_title}
          </span>
        </div>
        {/* middle */}
        <div className="main-prodcut col-span-12 768:mb-8 min-576:col-span-5 min-1024:col-span-4 place-self-center ">
          {/* main product image */}
          <Link to={`/shop-details/${product_slug}`}>
            <img
              src={mainImage}
              className={`item-bouncec main_product_img relative z-[3000] hover:rotate-12 hover:scale-105  transition ease-linear ${mainImage_class}`}
              // className={``}
              alt=""
            />
          </Link>

          {/* buy now */}
          <Link
            className="w-[100px] min-768:w-[120px] min-1024:w-[150px] absolute bottom-[-70px] min-576:bottom-0 left-[-50px] min-576:left-0 z-[999999] cursor-pointer"
            to={`/shop-details/${product_slug}`}
          >
            <img
              src={buy_now}
              className="w-full item-bounce-3s cursor-pointer"
              alt=""
            />
          </Link>
        </div>
        {/* right side */}
        <div className="col-span-12 768:text-center min-576:col-span-7 min-1024:col-span-4 min-1024:place-self-start]">
          <h1 className="r_lg_title text-white font-red-hat max-w-[300px] min-576:max-w-full text-[18px] min-576:text-[28px] min-1250:text-[48px] leading-10 min-1250:leading-[66.15px] tracking-[-1px] font-bold">
            {right_lg_title && (
              <span
                dangerouslySetInnerHTML={{
                  __html: right_lg_title,
                }}
              />
            )}
          </h1>
        </div>
      </div>
    </div>
  );
}
