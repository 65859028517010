import axios from "axios";
import React, { useState, useEffect, createContext, useRef } from "react";
import { useQuery } from "react-query";
import { base_url } from "../Base_url";

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  /**********************************
   *
   * GLOABL STATES
   *
   **********************************/
  //loading
  const [loading, setLoading] = useState(false);

  // customer details
  const [customerDetails, setcustomerDetails] = useState(null);
  // const [customerDetails, setcustomerDetails] = useState({
  //   id: '',
  //   displayName: '',
  //   email: '',
  // });

  //loading
  const [showSearchbar, setshowSearchbar] = useState(false);

  //show cart
  const [showCart, setshowCart] = useState(false);

  //checkout steps
  const [checkoutSteps, setcheckoutSteps] = useState(1);

  //  profilesteps
  const [profileSteps, setprofileSteps] = useState(1);
  const [activeUserDetails, setactiveUserDetails] = useState([]);

  // show right / left sidebar
  const [showRightSidebar, setshowRightSidebar] = useState(false);
  const [showLeftSidebar, setshowLeftSidebar] = useState(false);

  // hero section menu slide trigger
  const [currentSlide, setcurrentSlide] = useState(0);
  const swiperHeroRef = useRef(null);

  const goToSlide = (index) => {
    swiperHeroRef.current.slideTo(index);
    setcurrentSlide(index + 1);
  };

  // get global header info
  const [headerData, setheaderData] = useState([]);
  // get all header data
  const {
    isLoading: headerIsLoading,
    error: headerError,
    data: queryheaderData,
  } = useQuery("headerData-query-key", async () => {
    const response = await axios.get(
      `${base_url}/wp-json/softtechit/v1/innaree/header/`
    );
    return response.data;
  });

  useEffect(() => {
    if (queryheaderData) {
      setheaderData(queryheaderData);
    }
  }, [queryheaderData]);

  // get coupon and discount info
  const [dicountAndShippingData, setdicountAndShippingData] = useState([]);
  // get discount data
  function featchDiscountShippingData() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/productcoupon/`)
      .then((res) => {
        setdicountAndShippingData(res.data);
        // console.log('product coupon data',res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    featchDiscountShippingData();
  }, []);

  /**********************************
   *
   * Add to cart
   *
   **********************************/
  const [cartItems, setcartItems] = useState([]);
  const [subtotal, setsubtotal] = useState(0);

  // get subtotal
  const getSubtitle = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += parseFloat(item.totalPriceWithQty);
      // total.;
    });
    setsubtotal(total.toFixed(2));
  };

  // add to cart for exiting
  const addItemToCart = (item) => {
    let newItem = {
      ...item,
      qty: 1,
      totalPriceWithQty: item.price,
    };
    setcartItems([...cartItems, newItem]);
  };

  // add to cart for single item NEW ITEM
  const addNewItemToCartForSingleItem = (product, qty) => {
    let newItem = {
      ...product,
      qty: qty,
      totalPriceWithQty: parseFloat(product.price) * parseFloat(qty),
    };
    setcartItems([...cartItems, newItem]);
  };

  // add to cart for single item EXIESTING ITEM
  const addExiestingItemToCartForSingleItem = (product, qty) => {
    let filterData = cartItems.map((cart_item) => {
      if (cart_item.id == product.id) {
        cart_item.qty = qty;
        cart_item.totalPriceWithQty = parseFloat(product.price) * parseFloat(qty);
        return cart_item;
      }
      return cart_item;
    });

    setcartItems(filterData);
  };

  // remove from cart
  const removeItemFromCart = (item) => {
    setcartItems(cartItems.filter((i) => i !== item));
  };

  //  increase product qty
  const incProductQty = (product) => {
    let filterData = cartItems.map((item) => {
      if (item.id == product.id) {
        product.qty = product.qty + 1;
        product.totalPriceWithQty = product.price * product.qty;
        return item;
      }
      return item;
    });
    setcartItems(filterData);
  };

  //  decrease product qty
  const decProductQty = (product) => {
    let filterData = cartItems.map((item) => {
      if (item.id == product.id) {
        if (product.qty == 1) {
          return item;
        } else {
          product.qty = product.qty - 1;
          product.totalPriceWithQty = product.totalPriceWithQty - product.price;
          return item;
        }
      }
      return item;
    });
    setcartItems(filterData);
  };

  useEffect(() => {
    getSubtitle();
  }, [cartItems]);

  return (
    <StoreContext.Provider
      value={{
        // global states
        loading,
        setLoading,
        // show search bar
        showSearchbar,
        setshowSearchbar,
        // show cart
        showCart,
        setshowCart,
        // show left/right sidebar
        showRightSidebar,
        setshowRightSidebar,
        showLeftSidebar,
        setshowLeftSidebar,

        // header data
        headerIsLoading,
        headerData,
        setheaderData,

        // user details
        customerDetails,
        setcustomerDetails,

        // checkout process/steps
        checkoutSteps,
        setcheckoutSteps,

        // profileSteps
        profileSteps,
        setprofileSteps,

        // hero  section homepage slider
        swiperHeroRef,
        goToSlide,
        currentSlide,
        setcurrentSlide,

        // add to cart
        cartItems,
        setcartItems,
        addItemToCart,
        removeItemFromCart,
        incProductQty,
        decProductQty,
        subtotal,
        addNewItemToCartForSingleItem,
        addExiestingItemToCartForSingleItem,

        // discount data
        dicountAndShippingData,
        setdicountAndShippingData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
