import React from "react";
// import AboutFaqImg from "../../assets/images/about-faq-img.png";
import { Accordion } from "../accordion/Accordion";

const AboutFaq = ({ faqData, faqImg }) => {
  return (
    <div className="about-faq my-[100px]">
      <div className="innaree-container">
        <div className="grid min-768:grid-cols-2 gap-4">
          <div className="mb-[30px] min-768:mb-0">
            {/* About Accordions */}
            {faqData &&
              faqData.map((accordion, index) => (
                <span key={`faqData_acc_${index}`}>
                  <Accordion
                    keyValue={`faqData_acc_${index}`}
                    accordionTitle={accordion.brand_faq_title}
                    accordionContent={accordion.brand_faq_text}
                    accordionId={`About_accordionId_0${index}`}
                  />
                </span>
              ))}
          </div>
          <div>
            {faqImg.url && (
              <img
                src={faqImg.url}
                alt="product"
                data-aos="zoom-in-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                data-aos-delay="500"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFaq;
