import React from "react";
import { Link } from "react-router-dom";
import ReportingShape1 from "../../assets/images/reporting-breadcrumb-shape1.webp";
import ReportingShape2 from "../../assets/images/reporting-breadcrumb-shape2.png";

export default function ReportingBreadcrumb({ brdData }) {
  return (
    <div
      // className="reporting-breadcrumb-area h-auto mmin-576:h-[590px] pt-[140px] min-576:pt-[200px] pb-[40px] min-576:pb-[65px] bg-no-repeat bg-cover min-1024:bg-[length:100%_100%] bg-[center_top] min-1024:bg-center"
      className="reporting-breadcrumb-area h-auto pt-[140px] min-576:pt-[200px] pb-[40px] min-576:pb-[65px] bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${
          brdData.background.url ? brdData.background.url : ReportingShape1
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <div className="innaree-container grid min-576:grild-cols-1 min-1024:grid-cols-12 z-[99] min-1024:mt-[100px]">
        <div
          className="breadcrumb-itmes text-white text-[20px] font-semibold min-1024:col-span-4 min-1250:col-span-3 pl-0 min-1024:pl-[50px] min-1440:pl-0 mb-[10px] min-576:mb-[30px] min-1024:mb-0"
          data-aos="fade-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          data-aos-delay="900"
        >
          <Link to="/">Home</Link>
          <span className="inline-block mx-2">/</span>
          Lab-reports
        </div>
        <div className="breadcrumb-contents min-1024:col-span-8 min-1250:col-span-9">
          <div
            className="breadcrumb-contents-wrapper max-w-[686px] h-auto min-1024:h-[311px] p-0 min-576:px-[30px] min-576:pt-[28px] min-576:pb-[67px] min-1024:py-[67px] min-768:px-[50px] bg-no-repeat bg-[length:100%_100%] bg-center rounded-2xl bg-img-none-576"
            style={{ backgroundImage: `url(${ReportingShape2})` }}
            data-aos="flip-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <h3
              className="text-white text-[32px] font-bold mb-[10px] min-576:mb-[17px]"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="1000"
            >
              {brdData.title}
            </h3>
            <p
              className="text-white text-[14px] font-semibold"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="1000"
            >
              {brdData.description && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: brdData.description,
                  }}
                />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
