import React, { useState, useEffect, useRef, useContext } from "react";
// import h_brand_logo_1 from "../../assets/images/h_brand_logo_1.webp";
// import h_brand_logo_2 from "../../assets/images/h_brand_logo_2.webp";
// import h_brand_logo_3 from "../../assets/images/h_brand_logo_3.webp";
// import h_brand_logo_4 from "../../assets/images/h_brand_logo_4.webp";
// import h_brand_logo_5 from "../../assets/images/h_brand_logo_5.webp";
import { HomepageStoreContext } from "../../context/HomepageStore";

export default function HomeBrandLogo() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeError, homeData, setHomeData } =
    useContext(HomepageStoreContext);

  useEffect(() => {}, [homeData.brand_parthner]);

  if (homeData.brand_parthner) {
    return (
      <div
        className="home-brand-logo bg-innaree-border-color-gray px-10"
        key={"HomeBrandLogo_1"}
      >
        <div className="innaree-container py-16">
          {/* <div className="flex justify-between flex-wrap"> */}
          <div className="flex justify-center flex-wrap">
            {homeData.brand_parthner.brand_parthner_logos &&
              homeData.brand_parthner.brand_parthner_logos.map(
                (logo, index) => (
                  <img
                    key={index}
                    src={logo.brand_parthner_logo}
                    className="h-[48px] m-4"
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="700"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="300"
                  />
                )
              )}
          </div>
        </div>
      </div>
    );
  } else {
    return <div>{/* <h1>data is loading</h1> */}</div>;
  }
}
