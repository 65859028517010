import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useQuery } from 'react-query';
import "../components/faqpage/faqpage.scss";
import FaqBreadcrumb from "../components/faqpage/FaqBreadcrumb";
import FaqAccordion from "../components/faqpage/FaqAccordion";
import Cta from "../components/faqpage/Cta";
import Footer from "../components/layout/Footer";
import Brandpartner from "../components/homepage/Brandpartner";
import NavbarRight from "../components/navbar/NavbarRight";
import Cart from "../components/cartpage/Cart";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import Searchbar from "../components/searchbar/Searchbar";
import { HomepageStoreContext } from "../context/HomepageStore";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";

export default function Faq() {
  /******************************************
*
* GETTING CONTEXT VALUE
*
******************************************/
  let { homeIsLoading,
    homeData, setHomeData } =
    useContext(HomepageStoreContext);
  const { business_partners_data } = homeData;

  /******************************************
*
* components functions and state
*
******************************************/
  const [faqData, setfaqData] = useState([]);

  // get all faq page data
  const { isLoading: faqIsLoading, error: faqError, data: queryfaqData } = useQuery(
    'faqpage-query-key',
    async () => {
      const response = await axios.get(`${base_url}/wp-json/softtechit/v1/innaree/faq/`);
      return response.data;
    }
  );

  useEffect(() => {
    if (queryfaqData) {
      setfaqData(queryfaqData);
    }
  }, [queryfaqData]);

  // distructure data from value
  const { header, faq_about, faq_usage_dosage, faq_medical_health, faq_gallery } = faqData;


  return (
    <>
      <Helmet>
        <title>Innaree - FAQ</title>
      </Helmet>
      {
        faqIsLoading ? [
          <Preloader />
        ] : [

          <div>
            <Header />
            <NavbarLeft />
            <Searchbar />
            {header &&
              <FaqBreadcrumb brdData={header} />
            }
            <Cart />
            {faq_about &&
              <FaqAccordion
                aboutFaq={faq_about}
                cbd_usage={faq_usage_dosage}
                medicalFaq={faq_medical_health}
              />
            }
            {faq_gallery &&

              <Cta ctaData={faq_gallery} />
            }
            {business_partners_data &&
              <Brandpartner partnerData={business_partners_data} />
            }
            <Footer />
          </div>

        ]
      }
    </>
  )

}
