import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
// import HFA from "../../assets/images/hfa.webp";
// import Delivery from "../../assets/images/delivery.webp";
import { ButtonFour } from "../buttons/Button";
import { Link } from "react-router-dom";
import { HiShoppingBag } from "react-icons/hi";

const BrandSection = ({ lababoutData }) => {
  return (
    <div className="mt-[100px] mb-[50px]">
      <div className="innaree-container">
        {/* title bar */}
        <Titlebar
          smTitle={lababoutData.sub_title}
          lgTitle={lababoutData.title}
          ClassForWrapper={
            "min-768:w-[420px] mx-0 min-1024:mx-auto text-start min-1024:text-center"
          }
        />
        <div className="brand-info mt-[50px] min-1024:mt-[70px] grid min-576:grid-cols-3 min-1024:grid-cols-3">
          <div className="min-576:col-span-12 min-1024:col-span-1 mb-[30px] min-1024:mb-0">
            <h2
              className="text-innaree-black-color text-[28px] min-1024:text-[48px] font-bold"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              {lababoutData.left_title}
            </h2>
            <p
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              {lababoutData.left_description}
            </p>
          </div>

          <div className="col-span-12 min-768:col-span-6 min-1024:col-span-1 mb-[30px] min-768:mb-0">
            {lababoutData.Logo.url && (
              <img
                className="mx-0 min-1024:mx-auto mb-[27px]"
                src={lababoutData.Logo.url}
                alt="hfa-logo"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              />
            )}
            <span
              className="text-start min-1024:text-center text-[20px] mb-[27px] block"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              {lababoutData.logo_description}
            </span>

            <div
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              <Link
                className="text-white mx-0 min-1024:mx-auto bg-innaree-secondary-color border-2 border-transparent rounded-full font-medium text-sm px-8 py-2.5 flex justify-center items-center hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500"
                to="/shop"
              >
                <HiShoppingBag className="mr-2 text-xl" /> Shop Now
              </Link>
            </div>
          </div>

          <div className="col-span-12 min-768:col-span-6 min-1024:col-span-1">
            {lababoutData.left_img.url && (
              <img
                src={lababoutData.left_img.url}
                alt="delivery"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="1000"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandSection;
