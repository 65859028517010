import React from "react";
import { Link } from "react-router-dom";
import HelpingBreadcrumbBg from "../../assets/images/helping_breadcrumb.webp";
import helping_bg_img_1 from "../../assets/images/helping_bg_img_1.png";
import helping_bg_img_2 from "../../assets/images/helping_bg_img_2.png";
import helping_logo from "../../assets/images/helping_logo.png";
// import helping_hand_vec1 from "../../assets/images/helping_hand_vec1.png";
// import helping_bottol_vec_1 from "../../assets/images/helping_bottol_vec_1.png";
// import helping_bottol_vec_2 from "../../assets/images/helping_bottol_vec_2.png";

export default function HelpingBreadcrumb({ brdData }) {
  return (
    <div
      style={{
        backgroundImage: `url(${
          brdData.helping_tab.background.url
            ? brdData.helping_tab.background.url
            : HelpingBreadcrumbBg
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
      className={`relative text-white pt-[170px] min-576:pt-[270px] pb-[170px] min-576:pb-[300px] bg-center bg-no-repeat`}
      // className={`relative text-white pt-[170px] min-576:pt-[270px] pb-[170px] min-576:pb-[300px] bg-center 1024:bg-cover min-1024:bg-[length:100%_100%] bg-no-repeat`}
    >
      {/* position image */}
      {/* top left img */}
      {brdData.helping_tab.image_left_1.url && (
        <img
          src={brdData.helping_tab.image_left_1.url}
          className="w-[340px] absolute top-[17%] left-[0%]"
          alt=""
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          data-aos-delay="1000"
        />
      )}
      {/* top right img */}
      {brdData.helping_tab.image_right_1.url && (
        <img
          src={brdData.helping_tab.image_right_1.url}
          className="w-[180px] absolute top-[12%] right-[0]"
          alt=""
          data-aos="fade-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          data-aos-delay="1000"
        />
      )}
      {/* btm right img */}
      {brdData.helping_tab.image_bottom.url && (
        <img
          src={brdData.helping_tab.image_bottom.url}
          className="hidden min-576:block w-[340px] absolute bottom-[22%] right-[10%]"
          alt=""
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          data-aos-delay="1000"
        />
      )}
      <span className="absolute top-[20%] right-[8%]">
        <svg
          width="108"
          height="429"
          viewBox="0 0 108 429"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M107 9.00001C52.5004 -11 -40.7996 -5.79999 22.0004 175C84.8004 355.8 48.167 419.333 22.0004 428.5"
            stroke="#B09253"
            strokeDasharray="5 5"
          />
        </svg>
      </span>

      {/* main content wrapper */}
      <div className="innaree-container">
        <div className="grid grid-cols-12">
          {/* breadcrumb content */}
          <div
            className="relative text-white text-[20px] leading-[26px] capitalize inline-block font-semibold col-span-12 min-1024:col-span-3 1440:px-4"
            data-aos="fade-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-delay="800"
          >
            <Link className="" to={"/"}>
              Home
            </Link>
            <span className="inline-block mx-2">/</span>
            How you’re Helping
          </div>
          {/* right content  */}
          {brdData.text_top && (
            <div
              style={{
                backgroundImage: `url(${helping_bg_img_1})`,
              }}
              className="relative 1024:mt-[50px] min-1024:top-[-80px] min-1024:left-[-76px] 
            min-1024:pt-[100px] min-1024:pb-[70px] min-1024:pl-[120px] min-1024:pr-[130px] bg-center 1024:bg-cover min-1024:bg-[length:100%_100%] bg-no-repeat col-span-12 min-1024:col-span-8 min-1250:col-span-7"
              data-aos="flip-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <h4
                className="font-semibold text-[20px] min-1024:text-[32px] leading-[42px] text-white relative z-10 1024:p-[30px]"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="1000"
              >
                {brdData.text_top && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: brdData.text_top,
                    }}
                  />
                )}
              </h4>
            </div>
          )}
          {brdData.text_bottom && (
            <div
              style={{
                backgroundImage: `url(${helping_bg_img_2})`,
              }}
              className="relative pt-[30px] min-1024:pt-[90px] pb-[116px] pl-[40px] pr-[40px] min-1024:pr-[130px] bg-center 1024:bg-cover min-1024:bg-[length:100%_100%] bg-no-repeat col-span-12 min-1024:col-span-8 min-1250:col-span-7 1024:mt-[60px]"
              data-aos="flip-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              <p
                className="font-medium text-[20px] leading-[30px] text-white relative z-10"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="1000"
              >
                {brdData.text_bottom && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: brdData.text_bottom,
                    }}
                  />
                )}
              </p>

              {/* logo*/}
              {brdData.helping_logo && (
                <img
                  className="absolute left-[50%] min-1024:left-[87%] 1024:translate-x-[-50%] bottom-[-20%] min-1024:right-0 w-[160px]"
                  src={helping_logo}
                  alt="innaree"
                  data-aos="zoom-in"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1500"
                  data-aos-delay="1100"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
