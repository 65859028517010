import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Titlebar } from "../titlebar/Titlebar";
// import CategoryProduct1 from "../../assets/images/category-product1.webp";
import { base_url } from "../../Base_url";
import { Link } from "react-router-dom";
import { HomepageStoreContext } from "../../context/HomepageStore";
export default function ProductTypes() {
  const swiperRef = useRef();
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { filterdProductType } = useContext(HomepageStoreContext);

  return (
    <div
      className="prodcut-types py-16 mt-[40px] overflow-x-hidden"
      key={"ProductTypes_1"}
    >
      {/* title bar */}
      <div className="innaree-container flex justify-between items-center">
        <Titlebar
          smTitle="Most Popular"
          lgTitle="Shop By CBD Product Type"
          ClassForWrapper="text w-[300px] px-4"
          ClassForsmTitle="text-innaree-secondary-color"
          ClassForlgTitle=""
        />
      </div>

      {/* product slider */}
      <div className="innaree-container  relative h-[410px] mt-[40px]">
        <div className="absolute left-[30px] ">
          <div className="product_slider_type_one">
            <Swiper
              //   spaceBetween={30}
              watchSlidesProgress={true}
              parallax={true}
              // navigation={true}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  width: 320,
                  slidesPerView: 1,
                },
                // when window width is >= 576px
                576: {
                  width: 576,
                  slidesPerView: 2,
                },
                // when window width is >= 1024px
                1024: {
                  width: 1024,
                  slidesPerView: 3,
                },
                // when window width is >= 1440px
                1440: {
                  width: 1440,
                  slidesPerView: 5,
                },
              }}
              modules={[Pagination, Navigation]}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {filterdProductType &&
                filterdProductType.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="product-cart-wrap w-[265px] h-[360px] py-9 relative border-2 border-innaree-border-color-gray transition-all duration-500 hover:text-white hover:bg-innaree-primary-color hover:shadow-2xl hover:border-innaree-primary-color flex items-center justify-center">
                        <div className="product-img-wrap px-6 pb-8 ">
                          <Link to={`/shop/${item.categorie_slug}`}>
                            {/* Category Product Image */}
                            <img
                              className="categoryImg w-auto m-auto "
                              // src={CategoryProduct1}
                              src={item.categorie_img}
                              alt="category-product"
                            />
                          </Link>
                          {/* <Tilt className="Tilt" options={{ max: 25 }}>
        </Tilt> */}
                        </div>
                        <div className="product-content-wrap text-center absolute bottom-[20px] left-[35%]">
                          <Link
                            to={`/shop/${item.categorie_slug}`}
                            className="block text-[16px] font-bold mb-4 leading-[1px]"
                          >
                            {item.categorie_name}
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
