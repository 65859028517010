import React, { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
// import Prodcut10 from "../../assets/images/prodcut10.jpg";
import { FiMinusCircle } from "react-icons/fi";
import { FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { ButtonEight } from "../buttons/Button";
import { Scrollbars } from "react-custom-scrollbars";
import { StoreContext } from "../../context/Store";
import toast, { Toaster } from "react-hot-toast";
import cart_empty from "../../assets/images/empty_cart.png";
import { Power2, gsap } from "gsap";
import { getCookie } from "../../helper/helper";
const Cart = () => {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    // show cart
    showCart,
    setshowCart,
    cartItems,
    setcartItems,
    setshowLeftSidebar,
    removeItemFromCart,
    incProductQty,
    decProductQty,
    subtotal,
  } = useContext(StoreContext);

  // local state
  const [checkTermsndConditions, setcheckTermsndConditions] = useState(true);

  useEffect(() => {}, []);

  return (
    <div className={`cart`} key={"cart_1"}>
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          duration: 1000,
        }}
      />
      <button
        onClick={() => setshowCart(false)}
        className={`overlay  cart_overlay fixed top-0 bottom-0 w-full bg-[rgba(0,0,0,.4)] backdrop-blur-md z-[999] transition-all duration-700  ${
          showCart == false ? "left-[100%]" : "left-0"
        }`}
      ></button>
      <div
        className={`cart-wrapper w-full min-768:w-[690px] min-1024:w-[750px] min-1250:w-[875px] h-screen bg-white  z-[1000] pt-[85px] pb-[60px] px-[20px] min-576:px-[30px] fixed top-0 transition-all duration-1000 ${
          showCart == false ? "right-[100%]" : "right-0"
        }`}
      >
        <div className="cart-header flex items-center justify-between mb-[50px]">
          <div className="cart-header-title">{cartItems.length} Items</div>
          <button onClick={() => setshowCart(false)} className="cross">
            <AiOutlineClose className="h-[30px] w-[30px] text-innaree-secondary-color" />
          </button>
        </div>
        <div className="cart-body">
          <Scrollbars style={{ width: "100%", height: "80vh" }}>
            {/* Single Item */}
            {cartItems &&
              cartItems.map((product) => {
                return (
                  <div
                    key={product.id}
                    className="cart-item mb-[30px] flex items-start min-1024:items-center justify-start mr-[30px]"
                  >
                    <div className="product-img h-[100px] w-[100px]  mr-[35px] min-1024:mr-[10px] flex justify-center items-center">
                      <img
                        className="h-[60px]"
                        //  src={Prodcut10}
                        // src={product.images[0].src}
                        src={product.product_thumnails}
                        // src={Prodcut10}
                        alt="product"
                      />
                    </div>
                    <div className="prodcut-details  min-1024:flex grow items-center justify-between">
                      <div className="prodcut-heading">
                        <div className="prodcut-title text-[14px] font-bold mb-[5px]">
                          {/* Oil Drops 30ML 3000MG CBD */}
                          {product.name}
                        </div>
                        <div className="prodcut-category mb-[5px] min-1024:mb-0">
                          {product.categories &&
                            product.categories.map((cat) => (
                              <span key={cat.id} className="text-[14px]">
                                {cat.name}
                              </span>
                            ))}
                          {/* // <span className="text-[14px]">CBD Oil</span> */}
                        </div>
                      </div>
                      <div className="prodcut-quantity  mr-[40px]">
                        <div className="qty-selector flex items-center">
                          <button
                            onClick={() => decProductQty(product)}
                            className="minus mr-[10px] text-[20px]"
                          >
                            <FiMinusCircle />
                          </button>
                          <div className="amount mr-[10px] text-[16px]">
                            <span>{product.qty}</span>
                          </div>
                          <button
                            onClick={() => incProductQty(product)}
                            className="plush text-[20px]"
                          >
                            <FiPlusCircle />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="cart-final min-1024:flex grow-0 itmes-center text-right min-1024:text-start">
                      <div className="prodcut-price mr-0 min-1024:mr-[40px]">
                        <span className="text-[14px] font-bold">
                          £{(product.price * product.qty).toFixed(2)}
                        </span>
                      </div>
                      <div className="item-remove">
                        <button
                          onClick={() => removeItemFromCart(product)}
                          className="text-[14px] font-normal text-black hover:text-in-dark-golden"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* if cart is empty */}
            {cartItems.length == 0 && (
              <div className="flex justify-center flex-col items-center">
                <h4 className="font-bold m-4">Cart is empty!!</h4>
                <img width={300} src={cart_empty} />
              </div>
            )}

            <div className="h-[210px] min-1600:h-[170px] block"></div>
          </Scrollbars>
        </div>
        <div className="cart-footer h-[170px] absolute bg-white left-0 bottom-0 w-full border-t-2 border-innaree-border-color-gray px-[20px] pt-[20px] pb-[20px] min-1024:pb-[40px]">
          <div className="cart-message text-center mb-[10px] min-1024:mb-[30px]">
            <em>Free tracked standard shipping within the UK</em>
          </div>
          <div className="cart-overview min-1024:flex justify-between items-center">
            {/* <div className="cart-consent mb-[20px] min-1024:mb-0 text-center min-1024:text-start">
              <input
                className="mr-[10px] focus:ring-0 focus:ring-offset-0"
                type="checkbox"
                id="terms"
                onChange={() => setcheckTermsndConditions(!checkTermsndConditions)}

              />
              <label className="cursor-pointer" htmlFor="terms">
                I agree with the{" "}
                <Link className="underline" to="">
                  Terms & Conditions
                </Link>
              </label>
            </div> */}
            <div className="cart-summary inline-block min-1024:block">
              <div className="summary-price">
                <span className="text-[14px] font-bold block min-576:inline-block mr-[10px] uppercase">
                  Subtotal
                </span>
                <span className="font-bold text-[20px] min-576:text-[32px]">
                  £{subtotal}
                </span>
              </div>
            </div>
            {/* <Link
              onClick={() => setshowCart(false)}
              to={"/checkout"}
              className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 float-right`}
            >
              checkout
            </Link> */}
            {cartItems.length > 0 ? (
              <Link
                onClick={() => setshowCart(false)}
                to={"/checkout"}
                className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 float-right`}
              >
                checkout
              </Link>
            ) : (
              <Link
                onClick={() => {
                  // setshowCart(false);
                  toast.custom(
                    <div>
                      <div
                        id="toast-danger"
                        className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
                        role="alert"
                      >
                        <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                          <svg
                            aria-hidden="true"
                            className="w-3 h-3 rounded-[50%]"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Error icon</span>
                        </div>
                        <div className="px-4 text-sm font-normal">
                          Please add to cart first!!
                        </div>
                      </div>
                    </div>
                  );
                }}
                className={`text-white font-semibold uppercase bg-innaree-secondary-color px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 float-right`}
              >
                checkout
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
