import React from "react";
import Cart from "../components/cartpage/Cart";
import InformationFirstStep from "../components/checkout/InformationFirstStep";
import PaymentThirdStep from "../components/checkout/PaymentThirdStep";
import ShippingSecondStep from "../components/checkout/ShippingSecondStep";
import HeroSection3 from "../components/homepage/HeroSection3";
import PromotionalSlider from "../components/homepage/PromotionalSlider";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import Searchbar from "../components/searchbar/Searchbar";
// import Login from "../components/login/Login";
// import Address from "../components/profilepage/Address";
// import Edit from "../components/profilepage/Edit";
// import Profile from "../components/profilepage/Profile";

export default function Test() {
  return (
    <div>
      <Header />
      <Searchbar />
      <NavbarLeft />
      <Cart />
      {/* <HeroSection3 /> */}
      <PromotionalSlider />
    </div>
  );
}
