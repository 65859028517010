import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { useQuery } from "react-query";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import AddToCartProduct from "../components/shopdetailspage/AddToCartProduct";
import ShopDetailsBreadcrumb from "../components/shopdetailspage/ShopDetailsBreadcrumb";
import "../components/shopdetailspage/shopdetails.scss";
import ShopDetailsFeatures from "../components/shopdetailspage/ShopDetailsFeatures";
import ProductVideo from "../components/shopdetailspage/ProductVideo";
import CustomerReview from "../components/shopdetailspage/CustomerReview";
import RelatedProdcut from "../components/shopdetailspage/RelatedProdcut";
import "../components/singleproduct/SingleProduct.scss";
import Footer from "../components/layout/Footer";
import Cart from "../components/cartpage/Cart";
import Searchbar from "../components/searchbar/Searchbar";
import axios from "axios";
import { base_url } from "../Base_url";
import { RiH2 } from "react-icons/ri";
import Preloader from "../components/loading/Preloader";

export default function ShopDetails() {
  // Get the product param from the URL.
  let { productId } = useParams();

  // single product data
  const [singleProduct, setsingleProduct] = useState([]);
  const [relatedProducts, setrelatedProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // functions
  // get single product data from api
  function fetchData() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/product/${productId}`)
      .then((res) => {
        setsingleProduct(res.data);
        //  console.log('check shop page data',res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // get single product related data
  function fetchrelatedData() {
    setLoading(true);
    axios
      .get(
        `${base_url}/wp-json/softtechit/v1/innaree/relatedproducts/${productId}`
      )
      .then((res) => {
        // console.log('show data',res);
        setrelatedProducts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // append slider button
  function appendSliderButton() {
    setLoading(true);

    // create button style
    const leftBtnStyle = `       
 <svg width="48" height="186" viewBox="0 0 48 186" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.5123 57.9645C25.0054 47.6923 7.37623 15.0414 0 0V186C3.31916 164.722 28.3986 135.74 30.9802 132.805C54.5841 114.462 50.896 70.8047 36.5123 57.9645Z" fill="#4B3F72"/>
<g clip-path="url(#clip0_16_75)">
<path d="M13.7386 89.0468L22.0467 80.7387C23.016 79.7694 24.5391 79.7694 25.5084 80.7387C26.4777 81.708 26.4777 83.2311 25.5084 84.2004L18.9081 90.7546L25.5084 97.3549C26.4777 98.3242 26.4777 99.8474 25.5084 100.817C25.0469 101.278 24.4007 101.555 23.7545 101.555C23.1083 101.555 22.5083 101.324 22.0005 100.817L13.6924 92.5085C12.7693 91.5392 12.7693 90.0161 13.7386 89.0468Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_16_75">
<rect width="13.2007" height="21.555" fill="white" transform="translate(13 80)"/>
</clipPath>
</defs>
</svg>
`;

    // create button style
    const rightBtnStyle = `
<svg  height="186" viewBox="0 0 48 186" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4877 57.9645C22.9946 47.6923 40.6238 15.0414 48 0V186C44.6808 164.722 19.6014 135.74 17.0198 132.805C-6.58408 114.462 -2.89597 70.8047 11.4877 57.9645Z" fill="#4B3F72"/>
<g clip-path="url(#clip0_16_77)">
<path d="M30.4621 91.0468L22.154 82.7387C21.1847 81.7694 19.6615 81.7694 18.6923 82.7387C17.723 83.708 17.723 85.2311 18.6923 86.2004L25.2926 92.7546L18.6923 99.3549C17.723 100.324 17.723 101.847 18.6923 102.817C19.1538 103.278 19.8 103.555 20.4462 103.555C21.0924 103.555 21.6924 103.324 22.2001 102.817L30.5083 94.5085C31.4314 93.5392 31.4314 92.0161 30.4621 91.0468Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_16_77">
<rect width="13.2007" height="21.555" fill="white" transform="matrix(-1 0 0 1 31.2007 82)"/>
</clipPath>
</defs>
</svg>
`;
    const leftGalleryArrow = document.querySelector(
      ".react_img_gal_cls .image-gallery-left-nav"
    );
    const rightGalleryArrow = document.querySelector(
      ".react_img_gal_cls .image-gallery-right-nav"
    );

    if (leftGalleryArrow) {
      leftGalleryArrow.innerHTML = leftBtnStyle;
    }

    if (rightGalleryArrow) {
      rightGalleryArrow.innerHTML = rightBtnStyle;
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    fetchrelatedData();
    appendSliderButton();
  }, [productId]);

  const {
    product_features,
    product_how_to_use,
    product_ingredients,
    product_reviews,
  } = singleProduct;

  return (
    <>
      <Helmet>
        <title>Innaree - product details</title>
      </Helmet>
      {loading
        ? [<Preloader />]
        : [
            <div className="body-bg">
              <Header />
              <NavbarLeft />
              <Cart />
              <Searchbar />
              <ShopDetailsBreadcrumb title={singleProduct.title} />
              <AddToCartProduct singleProduct={singleProduct} />
              {product_features && (
                <ShopDetailsFeatures
                  featuresData={product_features}
                  howto={product_how_to_use}
                  ingredients={product_ingredients}
                />
              )}
              {product_how_to_use && (
                <ProductVideo videoDetails={product_how_to_use} />
              )}
              {product_reviews && (
                <CustomerReview leftSideContent={product_reviews} />
              )}
              {relatedProducts && <RelatedProdcut products={relatedProducts} />}
              <Footer />
            </div>,
          ]}
    </>
  );
}
