import React from "react";
// import h_brand_logo_1 from "../../assets/images/h_brand_logo_1.webp";
// import h_brand_logo_2 from "../../assets/images/h_brand_logo_2.webp";
// import h_brand_logo_3 from "../../assets/images/h_brand_logo_3.webp";
// import h_brand_logo_4 from "../../assets/images/h_brand_logo_4.webp";
// import h_brand_logo_5 from "../../assets/images/h_brand_logo_5.webp";
import { Titlebar } from "../titlebar/Titlebar";
export default function Brandpartner({ partnerData }) {
  return (
    <div className="brandpartner py-[100px]" key={"Brandpartner_1"}>
      {/* title bar */}
      <Titlebar
        smTitle={partnerData.business_pt_slug}
        lgTitle={partnerData.business_pt_title}
        ClassForWrapper={"min-768:w-[420px] m-auto text-center"}
      />
      <div className="innaree-container px-[15px] mt-[30px]">
        {/* <div className="flex justify-between flex-wrap"> */}
        <div className="flex justify-center flex-wrap">
          {partnerData.business_pt_logos &&
            partnerData.business_pt_logos.map((logo, index) => (
              <img
                key={`brd_log_${index}`}
                src={logo.business_pt_logo}
                className="h-[48px] m-4"
                alt=""
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="300"
              />
            ))}
        </div>
      </div>
    </div>
  );
}
