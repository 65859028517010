import React from "react";
import { RiStarFill } from "react-icons/ri";
import { RiStarHalfFill } from "react-icons/ri";
import ".//SingleGoogleCard.scss";

export function SingleGoogleCard({
  wrapperClass,
  clientImg,
  review,
  clientName,
  postDate,
}) {
  return (
    <div
      className={`${wrapperClass}`}
      data-aos="zoom-in-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1500"
      data-aos-delay="7000"
    >
      <div className="max-w-screen-md bg-in-bg-light-gay w-auto sm:w-[300px] p-5 rounded-3xl relative">
        <div className="flex items-center mb-3 text-yellow-300">
          <div className="rating flex justify-center items-center">
            <RiStarFill className="text-in-dark-golden" />
            <RiStarFill className="text-in-dark-golden" />
            <RiStarFill className="text-in-dark-golden" />
            <RiStarFill className="text-in-dark-golden" />
            <RiStarFill className="text-in-dark-golden" />
          </div>
        </div>
        <blockquote>
          <p className="text-sm font-semibold text-innaree-black-color dark:text-white">
            {review}
            {/* <a className="block mt-1 text-gray-500 font-normal" href="/test">
              Read More
            </a> */}
          </p>
        </blockquote>
        <div className="blockquote-triangle w-9 h-6 bg-in-bg-light-gay absolute"></div>
      </div>
      <div className="flex items-center mt-6 space-x-3 ml-7">
        <img
          className="w-12 h-12 rounded-full"
          // src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
          src={clientImg}
          alt="profilePicture"
        />
        <div className="text-start">
          <div className="pr-3 font-medium text-gray-900 dark:text-white">
            {clientName}
          </div>
          <div className="text-sm font-light text-gray-500 dark:text-gray-400">
            {postDate}
          </div>
        </div>
      </div>
    </div>
  );
}
