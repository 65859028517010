import React from "react";
import { Link } from "react-router-dom";
import FeaturedImg from "../../../assets/images/video_img1.webp";
import BlogImg1 from "../../../assets/images/cta-products.png";
import RightBanner from "../../../assets/images/right-banner.png";
import BottomBanner from "../../../assets/images/bottom-banner.png";
import SinglePost from "./SinglePost";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { base_url } from "../../../Base_url";
import "../../../assets/css/wordpress-core.scss";
import "../../../assets/css/style-rtl.scss";
const SingleBlogContent = ({ postContent, postFeatureImg, relatedpostData, sidebarData, footerblogParam }) => {

  // console.log('related post data ', relatedpostData);
  return (
    <div className="single-blog-content innaree-container py-[30px]">
      {/* Feature Image */}
      {postFeatureImg && (
        <div className="blog-featured-img max-h-[600px] min-768:-mt-[350px] min-1024:-mt-[300px] mb-[65px]">
          <img
            className="max-h-[600px] w-full object-cover"
            src={postFeatureImg}
            alt="featureImage"
          />
        </div>
      )}

      <div className="min-768:grid grid-cols-12 gap-10">
        {/* left side */}
        <div className="min-768:col-span-8 min-1024:col-span-9 innaree_blog_css">
          {/* Articals */}
          <span
            dangerouslySetInnerHTML={{
              __html: postContent.post_content && postContent.post_content,
            }}
          />

          {/* Bottom */}
          <div className="social-share mt-4 flex items-center 576:mt-[30px]">
            <span className="text-[18px] inline-block mr-[20px]">Share</span>
            {/* react-share */}
            <FacebookShareButton
              className="text-[18px] inline-block mr-[10px]"
              url={`${base_url}/blog/${footerblogParam}`}
            >
              <FaFacebookF className="hover:text-facebook" />
            </FacebookShareButton>

            <TwitterShareButton
              className="text-[18px] inline-block mr-[10px]"
              url={`${base_url}/blog/${footerblogParam}`}
            >
              <AiOutlineTwitter className="hover:text-twitter" />
            </TwitterShareButton>
          </div>
          {/* <div className="bottom-banner mt-[30px]">
            <Link to="/shop">
              <img src={BottomBanner} alt="BottomBanner" />
            </Link>
          </div> */}
          {sidebarData.footer_banner && sidebarData.footer_banner.url && (
            <div className="bottom-banner mt-[30px] mb-4">
              <Link to="/shop">
                <img
                  src={
                    sidebarData.footer_banner.url &&
                    sidebarData.footer_banner.url
                  }
                  alt=""
                />
              </Link>
            </div>
          )}
        </div>

        {/* right side */}
        <div className="min-768:col-span-4 min-1024:col-span-3">
          <div className="block">
            {relatedpostData.length > 0 && (
              <h4 className="text-[24px]">Related Articles</h4>
            )}

            {/* Single Post */}
            {relatedpostData &&
              relatedpostData.map((relatedPost, index) => (
                <div key={`sprd_${index}`} className="mb-4">
                  <SinglePost
                    postThumbnail={
                      relatedPost.post_thumnail && relatedPost.post_thumnail[0]
                    }
                    postCat={
                      relatedPost.post_categories && relatedPost.post_categories
                    }
                    postDate={relatedPost.post_date && relatedPost.post_date}
                    postSlug={relatedPost.slug && relatedPost.slug}
                    postTitle={relatedPost.post_title && relatedPost.post_title}
                    postExcerpt={
                      relatedPost.post_excerpt && relatedPost.post_excerpt
                    }
                  />
                </div>
              ))}

            {/* Right Banner */}
            {sidebarData.right_banner && sidebarData.right_banner.url && (
              <div className="right-banner mt-[30px]">
                <Link to="/shop" className="hidden min-768:block">
                  <img
                    src={
                      sidebarData.right_banner.url &&
                      sidebarData.right_banner.url
                    }
                    alt=""
                  />
                </Link>

                {sidebarData.widget && (
                  <div className="mt-4 shadow p-4">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sidebarData.widget,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBlogContent;
