import Cookies from "universal-cookie";
//importing ends
const cookies = new Cookies();

export function toggleClassForAccordion(id) {
  const parent = document.getElementById(id);
  parent.classList.toggle("active");
}



// export function ScrollToTop() { }

//Get cookie of authentication
export const getCookie = () => {
  if (cookies.get("_user") !== undefined) {
    let token =
      cookies.get("_user") +
      cookies.get("sbb") +
      cookies.get("frr") +
      cookies.get("xss");
    return token;
  }
};
