import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
// import BenefitShape1 from "../../assets/images/benefit-shape1.png";
// import BenefitShape2 from "../../assets/images/benefit-shape2.png";
import BenefitShape3 from "../../assets/images/benefit-shape3.webp";
// import BenefitShape4 from "../../assets/images/benefit-shape4.png";
import BenefitIcon1 from "../../assets/images/benefit-icon1.png";
import BenefitIcon2 from "../../assets/images/benefit-icon2.png";
import BenefitIcon3 from "../../assets/images/benefit-icon3.png";
import BenefitIcon4 from "../../assets/images/benefit-icon4.png";
import BenefitIcon5 from "../../assets/images/benefit-icon5.png";
import BenefitIcon6 from "../../assets/images/benefit-icon6.png";
import BenefitIcon7 from "../../assets/images/benefit-icon7.png";
import BenefitIcon8 from "../../assets/images/benefit-icon8.png";
import "../../animation.css";
import "../../components/aboutpage/CbdBenefits.scss";
import { BenefitAccordion } from "../accordion/BenefitAccordion";
import Scrollbars from "react-custom-scrollbars";

const CbdBenefits = ({ benefits }) => {
  // console.log('benefit data ',typeof benefits.benefits_faq);
  return (
    <div className="cbd-benefits mt-[100px] min-768:mt-[200px] relative">
      <div className="benefits-shapes">
        {benefits.title_product_image_left.url && (
          <img
            className="hidden min-1440:block absolute left-0 top-[25%] min-1024:top-[35%] max-w-[100px] min-768:max-w-[180px] min-1250:max-w-[150px] min-1600:max-w-[262px]"
            src={benefits.title_product_image_left.url}
            alt="shape"
            data-aos="fade-up"
            data-aos-duration="1300"
            data-aos-easing="ease-in-back"
          />
        )}
        {benefits.product_image_right.url && (
          <img
            className="hidden min-1440:block absolute right-0 top-[25%] min-1024:top-[35%] max-w-[100px] min-768:max-w-[180px] min-1250:max-w-[150px] min-1600:max-w-[262px]"
            src={benefits.product_image_right.url}
            alt="shape"
            data-aos="fade-down"
            data-aos-duration="1300"
            data-aos-easing="ease-in-back"
          />
        )}
      </div>
      <div className="innaree-container relative">
        {/* Section Title */}
        <Titlebar
          smTitle={benefits.sub_title}
          lgTitle={benefits.title}
          ClassForWrapper="text-center min-320:mb-[30px] 576:mb-[64px] min-576:mb-[30px]"
        />
        <div className="min-1024:grid min-1024:grid-cols-12 min-1024:gap-8 align-baseline">
          <div
            //  className="mx-auto min-576:max-w-[511px] min-1024:max-w-[640px] relative"
            className="mx-auto min-576:max-w-[511px] min-1024:max-w-[640px] relative col-span-7"
          >
            {benefits.animation_img && benefits.animation_img.url && (
              <img
                className="min-768:animate-rotate-circle"
                src={benefits.animation_img.url}
                alt="animation-shape"
              />
            )}
            {benefits.product_image_middle.url && (
              <img
                className="absolute top-[46%] min-1024:top-[36%] min-1250:top-[46%] left-[50%] translate-x-[-50%] translate-y-[-50%] min-320:max-w-[200px] 576:max-w-[264px] min-576:max-w-[320px] min-1024:max-w-[340px] min-1250:max-w-[400px] mx-auto"
                src={benefits.product_image_middle.url}
                alt="animation-shape"
              />
            )}
          </div>

          {/* Benefits */}
          <div className="benefits col-span-5">
            <Scrollbars style={{ width: "100%", height: 740 }}>
              <div className="px-[30px]">
                {benefits &&
                  benefits.benefits_faq &&
                  benefits.benefits_faq.map((bf, index) => (
                    <span key={`bf_${index}`}>
                      <BenefitAccordion
                        imgProp={bf.icon.url}
                        showImg={true}
                        accordionId={`benefits_${index}`}
                        accordionTitle={bf.title}
                        accordionContent={bf.description}
                      />
                    </span>
                  ))}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CbdBenefits;
