import React, { useEffect } from "react";
import shop_details_fea_1 from "../../assets/images/shop_details_fea_1.webp";
// import shop_details_icon_1 from "../../assets/images/shop_details_icon_1.png";
// import shop_details_icon_2 from "../../assets/images/shop_details_icon_2.png";
// import shop_details_icon_3 from "../../assets/images/shop_details_icon_3.png";
// import flower_01 from "../../assets/images/flower_01.png";
import parse from "html-react-parser";
export default function ShopDetailsFeatures({
  featuresData,
  howto,
  ingredients,
}) {
  useEffect(() => {
    // console.log('console log',featuresData[0]);
  }, []);

  return (
    <div className="innaree-container mt-[100px] mb-[100px] grid gap-4 grid-cols-12 overflow-x-hidden">
      <div className="col-span-12 min-768:col-span-4">
        <img
          className="max-w-[300px] min-768:max-w-[220px]  min-1024:w-auto"
          src={shop_details_fea_1}
          alt="product"
        />

        <div className="flex flex-wrap mt-4">
          {featuresData[0].delivery_returns.url && (
            <a
              target="_blank"
              href={featuresData[0].delivery_returns.url}
              className="font-bold text-[16px] capitalize  border-b-2 border-border-color-golden inline-block mt-4 mr-8"
            >
              Delivery & Returns
            </a>
          )}
          {featuresData[0].lab_reports.url && (
            <a
              target="_blank"
              href={featuresData[0].lab_reports.url}
              className="font-bold text-[16px] capitalize  border-b-2 border-border-color-golden inline-block mt-4"
            >
              Lab Reports
            </a>
          )}
        </div>
      </div>
      <div className="col-span-12 min-768:col-span-6">
        {featuresData && (
          <h4 className="text-[24px] text-black  font-semibold leading-[29px] capitalize ">
            Features
          </h4>
        )}

        <p className="font-normal text-[14px] leading-[26px] mt-4">
          {/* {parse(featuresData && featuresData[0].description)} */}
          {featuresData && (
            <span
              dangerouslySetInnerHTML={{ __html: featuresData[0].description }}
            />
          )}
        </p>

        {/*  features list */}
        {featuresData &&
          featuresData[0].features_box.map((fea_list) => (
            <div className="flex items-center mt-8 w-80">
              <img className="w-[60px] mr-4" src={fea_list.icon_img.url} />
              <div>
                <h5 className="text-[16px] font-bold leading-[19px]">
                  {/* {fea_list.title} */}
                  <span
                    dangerouslySetInnerHTML={{ __html: fea_list.title }}
                  />
                </h5>
                <span className="text-[14px] font-[300] leading-[26px] mt-1 inline-block">
                  {/* {parse(fea_list.descrption)} */}
                  {fea_list.descrption && (
                    <span
                      dangerouslySetInnerHTML={{ __html: fea_list.descrption }}
                    />
                  )}
                </span>
              </div>
            </div>
          ))}

        {featuresData && (
          <h4 className="text-[24px] text-black font-semibold leading-[29px] capitalize mt-[80px]">
            How to Use
          </h4>
        )}

        <p className="font-normal text-[14px]  leading-[26px] mt-4">
          {/* {parse(howto && howto[0].description)} */}
          {howto && (
            <span dangerouslySetInnerHTML={{ __html: howto[0].description }} />
          )}
        </p>

        {ingredients && (
          <h4 className="text-[24px] text-black  font-semibold leading-[29px] capitalize mt-[80px]">
            Ingredients
          </h4>
        )}
        <span className="text-[14px] leading-[26px] font-semibold block mt-4">
          {/* {parse(ingredients && ingredients[0].title)} */}
          {ingredients && (
            <span dangerouslySetInnerHTML={{ __html: ingredients[0].title }} />
          )}
        </span>

        {ingredients && (
          <div className="rounded-md bg-white shadow-[0px_10px_50px_rgba(0,0,0,0.1)] py-[20px] px-[20px] inline-block mt-4">
            <img className="w-[77px]" src={ingredients[0].image.url} />
            <span className="text-[14px] font-normal  inline-block">
              {/* Peppermint */}
              {/* {parse(ingredients && ingredients[0].name)} */}
              {ingredients && (
                <span
                  dangerouslySetInnerHTML={{ __html: ingredients[0].name }}
                />
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
