import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { CgArrowRight } from "react-icons/cg";
import { base_url } from "../../../Base_url";

const SinglePost = ({
  postThumbnail, postCat, postDate, postSlug, postTitle, postExcerpt
}) => {

  return (
    <>
      <div className="shadow-in-boxShadow p-4  transition-all delay-75 group">
        <div className="object-contain">
          <img
            className="w-full group-hover:scale-[.98] transition-all delay-75"
            src={postThumbnail && postThumbnail}
          />
        </div>
        <div className="relative col-span-2 mt-4">
          <div className="inline-flex items-center ">
            <div className="flex items-center">
              <span className=" mb-1 text-[16px] mr-4 text-innaree-secondary-rgba capitalize">
                {postCat && postCat}
              </span>
              <p className=" opacity-50 text-xs">
                <Moment format="YYYY/MM/DD">
                  {postDate && postDate}
                </Moment>
              </p>
            </div>
          </div>
          <Link
            to={`/blog/${postSlug}`}
            className="leading-[30px] rounded-lg block mb-4 text-[20px] capitalize font-bold cursor-pointer hover:text-innaree-primary-color"
          >
            {postTitle && (
              <span
                dangerouslySetInnerHTML={{
                  __html: postTitle && postTitle,
                }}
              />
            )}
          </Link>

          <p className="mb-4  block">
            <span className="text-[14px]"
              dangerouslySetInnerHTML={{
                __html: postExcerpt && postExcerpt,
              }}
            />
            <Link
              to={`/blog/${postSlug}`}
              className="text-[28px] text-innaree-secondary-color hover:text-black cursor-pointer mt-4 block group-hover:translate-x-[7px] transition-all delay-75"
            >
              <CgArrowRight />
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default SinglePost;
