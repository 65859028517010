import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useQuery } from 'react-query';
import AboutBreadcrumb from "../components/aboutpage/AboutBreadcrumb";
import AboutFaq from "../components/aboutpage/AboutFaq";
import AboutFeature from "../components/aboutpage/AboutFeature";
import AboutSection from "../components/aboutpage/AboutSection";
import CbdBenefits from "../components/aboutpage/CbdBenefits";
import Mission from "../components/aboutpage/Mission";
import SelfCare from "../components/aboutpage/SelfCare";
import Testimonial from "../components/aboutpage/Testimonial";
import WorkingProgress from "../components/aboutpage/WorkingProgress";
import Brandpartner from "../components/homepage/Brandpartner";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import "../components/aboutpage/aboutpage.scss";
import Cart from "../components/cartpage/Cart";
import Searchbar from "../components/searchbar/Searchbar";
import { HomepageStoreContext } from "../context/HomepageStore";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";

export default function About() {
  /******************************************
  *
  * GETTING CONTEXT VALUE
  *
  ******************************************/
  let { homeIsLoading,
    homeData, setHomeData,
    aboutData, setaboutData,
    aboutIsLoading, aboutError } =
    useContext(HomepageStoreContext);
  const { business_partners_data } = homeData;

  /******************************************
  *
  * components functions and state
  *
  ******************************************/
  // const [aboutData, setaboutData] = useState([]);

  // // get all about page data
  // const { isLoading: aboutIsLoading, error: aboutError, data: queryaboutData } = useQuery(
  //   'aboutpage-query-key',
  //   async () => {
  //     const response = await axios.get(`${base_url}/wp-json/softtechit/v1/innaree/about/`);
  //     return response.data;
  //   }
  // );

  // useEffect(() => {
  //   if (queryaboutData) {
  //     setaboutData(queryaboutData);
  //   }
  // }, [queryaboutData]);

  // distructure data from value
  const { header, benefits, about_us, mission_vision, working_process, about_testimonials } = aboutData;
// console.log("check about data", about_us);
  return (
    <>
      <Helmet>
        <title>Innaree - about</title>
      </Helmet>
      {aboutIsLoading
        ? [<Preloader />]
        : [
            <div className="bg-body-bg">
              <Header />
              <NavbarLeft />
              <Searchbar />
              {header && <AboutBreadcrumb brdData={header} />}
              <Cart />
              {benefits && <CbdBenefits benefits={benefits} />}

              {about_us && <AboutSection aboutsectionData={about_us} />}

              {about_us && (
                <AboutFaq
                  faqImg={about_us.brand_accordion_left_img}
                  faqData={about_us.brand_accordion}
                />
              )}
              {about_us && (
                <SelfCare
                  sectionTitle={about_us.we_are_section_title}
                  title={about_us.we_are_title}
                  des={about_us.we_are_description}
                  linkto={about_us.we_are_button.url}
                  countCards={about_us.we_are_small_box}
                />
              )}

              {about_us && (
                <AboutFeature fea_details={about_us.we_are_big_box} />
              )}
              {mission_vision && <Mission missionData={mission_vision} />}
              {working_process && (
                <WorkingProgress workingData={working_process} />
              )}
              {about_testimonials && (
                <Testimonial testimonialData={about_testimonials} />
              )}
              {business_partners_data && (
                <Brandpartner partnerData={business_partners_data} />
              )}
              <Footer />
            </div>,
          ]}
    </>
  );
}