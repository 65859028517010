import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { StoreContext } from "../../context/Store";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import "../layout/Layout.scss";
import { base_url } from "../../Base_url";
import { getCookie } from "../../helper/helper";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function Header({ layout }) {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    setshowLeftSidebar,
    setshowSearchbar,
    setshowCart,
    cartItems,
    setcheckoutSteps,
    headerIsLoading,
    headerData, setheaderData,
  } = useContext(StoreContext);

  let customerDetailsFromLocalstorage = null;

  // usestate
  const [showProfileMenu, setshowProfileMenu] = useState(false);
  const [checkLogin, setcheckLogin] = useState(false);

  //logout
  const handleLogout = () => {
    const url = base_url + `/wp-json/softtechit/v1/innaree/logout/`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => {
        window.location.reload();
        if (cookies.get("_user") !== undefined) {
          cookies.remove("_user", { path: "/" });
        }
        if (cookies.get("sbb") !== undefined) {
          cookies.remove("sbb", { path: "/" });
        }
        if (cookies.get("frr") !== undefined) {
          cookies.remove("frr", { path: "/" });
        }
        if (cookies.get("xss") !== undefined) {
          cookies.remove("xss", { path: "/" });
        }
        localStorage.removeItem("customerDetails");
      })
      .catch(() => {
        window.location.reload();
        if (cookies.get("_user") !== undefined) {
          cookies.remove("_user", { path: "/" });
        }
        if (cookies.get("sbb") !== undefined) {
          cookies.remove("sbb", { path: "/" });
        }
        if (cookies.get("frr") !== undefined) {
          cookies.remove("frr", { path: "/" });
        }
        if (cookies.get("xss") !== undefined) {
          cookies.remove("xss", { path: "/" });
        }
        localStorage.removeItem("customerDetails");
      });
  };

  useEffect(() => {
    if (getCookie() !== undefined) {
      setcheckLogin(true);
    } else {
      setcheckLogin(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (window.scrollY > 500) {
        document.querySelector(".header").classList.add("header_fixed");
      } else {
        document.querySelector(".header").classList.remove("header_fixed");
      }
    });
  }, []);

  return (
    <div
      key={"header_1"}
      className={`header bg-transparentt w-full top-0 left-0 z-20 
    ${layout == "sm" ? "relative bg-innaree-primary-color" : "absolute"}`}
    >
      <div
        className={`innaree-container flex items-center justify-between px-[25px] min-1024:px-[50px] min-1440:px-0`}
      >
        <div>
          {/* left sidebar trigger */}
          <span onClick={() => setshowLeftSidebar(true)}>
            <svg
              className={`fixed_right_sidebar_menu w-[30px] min-576:w-[50px] cursor-pointer ${
                layout == "sm" ? "min-1024:w-[50px]" : "min-1024:w-[80px]"
              }`}
              viewBox="0 0 92 92"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_187_133)">
                <path
                  d="M46 90.5C70.5767 90.5 90.5 70.5767 90.5 46C90.5 21.4233 70.5767 1.5 46 1.5C21.4233 1.5 1.5 21.4233 1.5 46C1.5 70.5767 21.4233 90.5 46 90.5Z"
                  stroke="white"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
                <path
                  d="M17 41H74"
                  stroke="white"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
                <path
                  d="M17.5 56H74.5"
                  stroke="white"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
              </g>
              <defs>
                <clipPath id="clip0_187_133">
                  <rect width="92" height="92" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </div>
        {/* logo */}

        <Link
          onClick={() => setcheckoutSteps(1)}
          className={`max-w-[190px] 576:hidden  relative left-[27px] ${
            layout == "sm" ? "w-[150px]" : "min-1024:max-w-[250px]"
          }`}
          to={"/"}
        >
          <img
            src={headerData.main_logo ? headerData.main_logo : logo}
            className={`w-full logo`}
            alt="logo"
          />
        </Link>

        {/* right side menu */}
        <div className=" flex items-center relative 576:left-[27px]">
          {/* profile link */}
          <span className="cursor-pointer relative">
            <svg
              onClick={() => setshowProfileMenu(!showProfileMenu)}
              className=" 576:w-[15px] min-576:w-[21px]"
              viewBox="0 0 21 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7118 11.443C13.5123 11.443 15.7825 9.16344 15.7825 6.3514C15.7825 3.53937 13.5123 1.25977 10.7118 1.25977C7.91129 1.25977 5.64105 3.53937 5.64105 6.3514C5.64105 9.16344 7.91129 11.443 10.7118 11.443Z"
                stroke="#E9A930"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.423 21.6263C19.423 17.6854 15.5185 14.498 10.7115 14.498C5.90445 14.498 2 17.6854 2 21.6263"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {showProfileMenu && (
              <div className="absolute top-[130%] bg-white border-gray-200 px-2 md:px-4 py-2.5 dark:bg-gray-900 w-[200px]">
                {checkLogin == true
                  ? [
                      <>
                        <Link
                          className="block p-2 hover:bg-body-bg cursor-pointer capitalize"
                          onClick={handleLogout}
                        >
                          Log out
                        </Link>

                        <Link
                          className="block p-2 hover:bg-body-bg cursor-pointer capitalize"
                          to={`/profile`}
                        >
                          profile
                        </Link>
                      </>,
                    ]
                  : [
                      <>
                        <Link
                          className="block p-2 hover:bg-body-bg cursor-pointer capitalize"
                          to={"/register"}
                        >
                          Register
                        </Link>
                        <Link
                          className="block p-2 hover:bg-body-bg cursor-pointer capitalize"
                          to={"/login"}
                        >
                          Login
                        </Link>
                      </>,
                    ]}
              </div>
            )}
          </span>

          {/* divider */}
          <svg
            className="mx-4"
            width="2"
            height="14"
            viewBox="0 0 2 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.999999 1L0.999999 13.5"
              stroke="#F1E1B2"
              strokeOpacity="0.26"
              strokeLinecap="round"
            />
          </svg>

          {/* search option trigger */}
          <span
            onClick={() => setshowSearchbar(true)}
            className="cursor-pointer"
          >
            <svg
              className="576:w-[17px] min-576:w-[25px] text-white"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4583 20.8333C16.636 20.8333 20.8333 16.6359 20.8333 11.4583C20.8333 6.28058 16.636 2.08325 11.4583 2.08325C6.28064 2.08325 2.08331 6.28058 2.08331 11.4583C2.08331 16.6359 6.28064 20.8333 11.4583 20.8333Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.7186 21.5517C20.2707 23.2183 21.5311 23.385 22.4999 21.9267C23.3853 20.5933 22.802 19.4996 21.1978 19.4996C20.0103 19.4892 19.3436 20.4163 19.7186 21.5517Z"
                stroke="#E9A930"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          {/* divider */}
          <svg
            className="mx-4"
            width="2"
            height="14"
            viewBox="0 0 2 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.999999 1L0.999999 13.5"
              stroke="#F1E1B2"
              strokeOpacity="0.26"
              strokeLinecap="round"
            />
          </svg>
          {/* add to cart */}
          <span
            onClick={() => setshowCart(true)}
            className="cursor-pointer relative"
          >
            <span className=" absolute text-[10px] p-2 w-[10px] h-[10px] flex justify-center items-center rounded-[50%] bg-innaree-secondary-color text-black font-semibold right-0 top-[-3px] transition-all overflow-hidden hover:scale-[1.1]">
              {cartItems.length}
            </span>
            <svg
              className="576:w-[17px] min-576:w-[25px] text-white"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 4.12467C0 4.76092 0.439236 5.29843 0.959147 5.29843H3.62145L4.97502 16.6411C5.18119 18.3524 5.93416 19.3945 7.35048 19.3945H17.7666C18.2328 19.3945 18.6541 18.9557 18.6541 18.3085C18.6541 17.6723 18.2328 17.2335 17.7666 17.2335H7.58354C7.20705 17.2335 6.96502 16.9044 6.91124 16.4108L6.76782 15.2919H17.8652C19.2816 15.2919 20.0345 14.2497 20.2407 12.5165L20.8861 7.21813C20.904 7.06456 20.922 6.87807 20.922 6.74644C20.922 6.0334 20.5365 5.53977 19.8284 5.53977H5.62939L5.50389 4.4757C5.38736 3.45552 5.0288 2.93994 3.97105 2.93994H0.959147C0.439236 2.93994 0 3.47746 0 4.12467ZM6.46304 23.0036C6.46304 24.1115 7.18912 25.0001 8.09449 25.0001C8.99985 25.0001 9.72593 24.1115 9.72593 23.0036C9.72593 21.8956 8.99985 21.0071 8.09449 21.0071C7.18912 21.0071 6.46304 21.8956 6.46304 23.0036ZM14.6741 23.0036C14.6741 24.1115 15.4091 25.0001 16.3145 25.0001C17.2198 25.0001 17.9459 24.1115 17.9459 23.0036C17.9459 21.8956 17.2198 21.0071 16.3145 21.0071C15.4091 21.0071 14.6741 21.8956 14.6741 23.0036Z"
                fill="white"
              />
              <path
                d="M24.5 5C24.5 7.50855 22.5692 9.5 20.2381 9.5C17.907 9.5 15.9762 7.50855 15.9762 5C15.9762 2.49145 17.907 0.5 20.2381 0.5C22.5692 0.5 24.5 2.49145 24.5 5Z"
                fill="#E9A930"
                stroke="#171717"
              />
              <path
                d="M20.3241 7.44873C21.1216 7.44873 21.6053 6.73828 21.6053 5.60059V5.5957C21.6053 4.45801 21.1216 3.75 20.3241 3.75C19.5266 3.75 19.0476 4.45801 19.0476 5.5957V5.60059C19.0476 6.73828 19.5266 7.44873 20.3241 7.44873ZM20.3241 6.93848C19.8963 6.93848 19.6521 6.43555 19.6521 5.60059V5.5957C19.6521 4.76074 19.8963 4.2627 20.3241 4.2627C20.7519 4.2627 21.0007 4.76074 21.0007 5.5957V5.60059C21.0007 6.43555 20.7519 6.93848 20.3241 6.93848Z"
                fill="#171717"
              />
            </svg>
          </span>
        </div>

        {/* logo */}

        <Link
          className={`w-[100px] min-576:w-[150px] relative left-[27px] min-576:hidden`}
          to={"/"}
        >
          <img src={logo} className={`w-full`} alt="logo" />
        </Link>
      </div>
    </div>
  );
}
