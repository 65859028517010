import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useContext, useRef, useEffect, useState } from "react";
import { Pagination, Navigation } from "swiper";
import { Titlebar } from "../titlebar/Titlebar";
// import InnareeLeaf from "../../assets/images/innaree-leaf.png";
import { BadgeOne, BadgeTwo } from "../buttons/Button";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { RiStarFill } from "react-icons/ri";
import { RiStarHalfFill } from "react-icons/ri";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import Tilt from "react-tilt";
import { Link } from "react-router-dom";
import { StoreContext } from "../../context/Store";
import toast, { Toaster } from "react-hot-toast";
import { base_url } from "../../Base_url";
import StarRating from "../../helper/StarRating";
// init for woocommerce rest api

export default function MostPopularProduct() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    // add to cart
    cartItems,
    addItemToCart,
  } = useContext(StoreContext);

  const swiperRef = useRef();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${base_url}/wp-json/softtechit/v1/innaree/allproducts`
        );
        setProduct(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // handle add to cart (check if item already added to cart)
  const handleAddToCart = (product) => {
    if (cartItems.some((item) => item.id == product.id)) {
      // toast("Product already added!!.");
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Product already added!
            </div>
          </div>
        </div>
      );
    } else {
      addItemToCart(product);
      toast.custom(
        <div>
          <div
            id="toast-success"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Product Added Successfully
            </div>
          </div>
        </div>
      );
    }
  };


  return (
    <div
      key={"mostpopu"}
      className="most-popular-prodcut pt-16 mt-[40px] mostpopularproductSlider overflow-x-hidden"
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="10000"
    >
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          duration: 2000,
        }}
      />
      {/* title bar */}
      <div className="innaree-container flex 768:flex-wrap justify-between items-center">
        <Titlebar
          smTitle="Most Popular"
          lgTitle="Shop By Popular CBD Products"
          ClassForWrapper="text w-[300px] px-4 py-4"
          ClassForsmTitle="text-innaree-secondary-color"
          ClassForlgTitle=""
        />

        <div className="px-4 py-4">
          <button
            className="rounded-[12px] font-red-hat text-white bg-innaree-primary-color border-2 border-transparent font-medium text-sm px-4 text-[20px] py-[7px]  hover:drop-shadow-5xl  duration-500 mr-4"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <MdNavigateBefore />
          </button>

          <button
            className="rounded-[12px] text-black  bg-white border-2 border-innaree-border-color-gray  font-medium text-sm px-4 text-[20px] py-[7px] mb-2 hover:drop-shadow-5xl  duration-500"
            onClick={() => swiperRef.current?.slideNext()}
          >
            {" "}
            <MdNavigateNext />
          </button>
        </div>
      </div>

      {/* product slider */}
      <div className="innaree-container relative h-[610px]">
        <div className="absolute left-0 section_slider_one">
          <Swiper
            slidesPerView={5}
            watchSlidesProgress={true}
            parallax={true}
            breakpoints={{
              // when window width is >= 320px
              320: {
                width: 320,
                slidesPerView: 1,
                centeredSlides: true,
              },
              // when window width is >= 576px
              576: {
                width: 576,
                slidesPerView: 2,
              },
              // when window width is >= 1024px
              1024: {
                width: 1024,
                slidesPerView: 3,
              },
              // when window width is >= 1440px
              1440: {
                width: 1440,
                slidesPerView: 4,
              },
            }}
            pagination={false}
            modules={[Pagination, Navigation]}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
          >

            {product &&
              product.map((product) => (
                <SwiperSlide key={`mp_${product.id}`}>
                  <div className="product-cart-wrap 576:w-[300px] py-9 relative transition-all duration-500  hover:rounded-[20px] group">
                    <div className="product-img-wrap pb-4">
                      {/* Product One Hover Image */}
                      {/* <img
                        src={product.product_hover_image.url}
                        alt="innaree-leaf"
                        className="product-hover-imgg"
                      /> */}

                      <a>
                        <img
                          className="ww-[105px] max-w-[170px] max-h-[200px]  m-auto block group-hover:hidden"
                          // className="m-auto"
                          // src={Product1}
                          src={product.product_thumnails}
                          alt="product1"
                        />
                        <img
                          className="ww-[105px] max-w-[170px] max-h-[200px]  m-auto hidden group-hover:block"
                          // className="m-auto"
                          // src={Product1}
                          src={product.product_hover_image.url}
                          alt="product1"
                        />
                      </a>
                      {/* <Tilt className="Tilt" options={{ max: 25 }}>
                        </Tilt> */}
                    </div>
                    <div className="product-content-wrap text-center relative">
                      {/* category buttons*/}
                      <div className="product-badges mb-[20px]">
                        <BadgeOne className="mr-4" /> <BadgeTwo />
                      </div>
                      {/* add to cart buttons */}
                      <div className="action-buttons">
                        <button
                          type="button"
                          onClick={() => handleAddToCart(product)}
                          className={`rounded-innaree-rounded font-red-hat text-white bg-innaree-primary-color border-2 border-transparent font-medium text-sm px-7 py-[7px]  hover:shadow-in-boxShadow-primary-color hover:-translate-y-1 duration-500`}
                        >
                          Add
                        </button>
                        <Link
                          to={`/shop-details/${product.slug}`}
                          className="ml-4 inline-block text-black  bg-white border-2 border-innaree-border-color-gray rounded-lg font-medium text-sm px-7 py-[7px] mb-2 hover:shadow-in-boxShadow-dark hover:-translate-y-1 duration-500"
                        >
                          View
                        </Link>
                      </div>
                      <Link
                        className="block text-[16px] font-bold mb-4"
                        to={`/shop-details/${product.slug}`}
                      >
                        {parse(product.name)}
                      </Link>

                      <StarRating averageRating={product.average_ratings} review_count={product.review_count} />

                      <h4 className="product-price text-[24px] font-bold">
                        £{product.price}
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>
              ))}

            {/* {
              !product && [1, 2, 3, 4].map((pro_sk, index) => (
                <div key={`mpk_1_${index}`} role="status" class="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
                  <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                    <svg class="w-12 h-12 text-gray-200 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" /></svg>
                  </div>
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  <div class="flex items-center mt-4 space-x-3">
                    <svg class="text-gray-200 w-14 h-14 dark:text-gray-700" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                    <div>
                      <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                      <div class="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                  </div>
                  <span class="sr-only">Loading...</span>
                </div>

              ))
            } */}

          </Swiper>
        </div>
      </div>
    </div>
  );
}
