import React, { useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import HeroSection from "../components/homepage/HeroSection";
import Header from "../components/layout/Header";
import NavbarRight from "../components/navbar/NavbarRight";
import NavbarLeft from "../components/navbar/NavbarLeft";
import HomeBrand from "../components/homepage/HomeBrand";
import HomeBrandLogo from "../components/homepage/HomeBrandLogo";
import MostPopularProduct from "../components/homepage/MostPopularProduct";
import Footer from "../components/layout/Footer";
import GoogleCard from "../components/homepage/GoogleCard";
import LabReportSection from "../components/homepage/LabReportSection";
import ProductTypes from "../components/homepage/ProductTypes";
import Brandpartner from "../components/homepage/Brandpartner";
import "../components/homepage/homepage.scss";
import "../components/homepage/homepage.scss";
import Searchbar from "../components/searchbar/Searchbar";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Cart from "../components/cartpage/Cart";
import toast, { Toaster } from "react-hot-toast";
import { HomepageStoreContext } from "../context/HomepageStore";
import Preloader from "../components/loading/Preloader";
import HeroSection3 from "../components/homepage/HeroSection3";
import PromotionalSlider from "../components/homepage/PromotionalSlider";
import HomeBlogSection from "../components/homepage/HomeBlogSection";

gsap.registerPlugin(ScrollTrigger);


export default function Homepage() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeData, setHomeData } =
    useContext(HomepageStoreContext);

  const { home_gallery_data, business_partners_data, slider_condition, promotional_slider } = homeData;

  // console.log('home data', homeData);

  //=> for animation
  useEffect(() => {
    // slider move on scroll one
    gsap.to(".section_slider_one", {
      x: -400,
      scrollTrigger: {
        trigger: ".section_slider_one",
        start: "center center",
        scrub: true,
      },
    });
    gsap.to(".product_slider_type_one", {
      x: -400,
      scrollTrigger: {
        trigger: ".product_slider_type_one",
        start: "center center",
        scrub: true,
      },
    });
  }, []);


  if (homeIsLoading) {
    return (
      <>
        <Helmet>
          <title>Innaree - home</title>
          <meta
            name="description"
            content="lets find it"
          />
        </Helmet>
        <Preloader />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Innaree - Home</title>
          <meta
            name="description"
            content="jerald"
          />
        </Helmet>
        {/* {
        homeData
      } */}
        <div className="overflow-hidden" key={"home_wrap"}>
          <Header />
          <Searchbar />
          <NavbarLeft />
          {/* {homeData.home_slider && <NavbarRight />} */}
          <Cart />
          {/* <HeroSection /> */}

          {slider_condition && [
            slider_condition == "main_slider" ? (
              <HeroSection3 />
            ) : (
              <PromotionalSlider />
            ),
          ]}
          {/* <HeroSection3 />
<PromotionalSlider/> */}
          {homeData.brand_details && (
            <HomeBrand brandData={homeData.brand_details} />
          )}
          <HomeBrandLogo />
          <MostPopularProduct />
          <GoogleCard />
          <ProductTypes />
          {home_gallery_data && (
            <LabReportSection labData={home_gallery_data} />
          )}

          <HomeBlogSection />
          {business_partners_data && (
            <Brandpartner partnerData={business_partners_data} />
          )}

          <Footer />
        </div>
      </>
    );
  }
}
