import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useQuery } from "react-query";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
// import shopBg1 from "../assets/images/shop-bg.webp";
// import shopImg1 from "../assets/images/shopImg1.webp";
import Brandpartner from "../components/homepage/Brandpartner";
import ProductFilter from "../components/shoppage/ProductFilter";
import ShopBreadcrumb from "../components/shoppage/ShopBreadcrumb";
import Footer from "../components/layout/Footer";
import "../components/singleproduct/SingleProduct.scss";
import Cart from "../components/cartpage/Cart";
import Searchbar from "../components/searchbar/Searchbar";
import { HomepageStoreContext } from "../context/HomepageStore";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";

export default function ShopPage() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeData, setHomeData } =
    useContext(HomepageStoreContext);
  const { business_partners_data } = homeData;

  /******************************************
   *
   * components functions and state
   *
   ******************************************/
  const [shopData, setshopData] = useState([]);

  // get all about page data
  const {
    isLoading: shopIsLoading,
    error: shopError,
    data: queryshopData,
  } = useQuery("shoppage-query-key", async () => {
    const response = await axios.get(
      `${base_url}/wp-json/softtechit/v1/innaree/shop/`
    );
    return response.data;
  });

  useEffect(() => {
    if (queryshopData) {
      setshopData(queryshopData);
    }

    // console.log();
  }, [queryshopData]);

  // distructure data from value
  const { shop_header, categories,shop_categories, all_products } = shopData;
  // console.log("shop page data comming", shopData);
  return (
    <>
      <Helmet>
        <title>Innaree - shop</title>
      </Helmet>
      {shopIsLoading
        ? [<Preloader />]
        : [
            <div className="body-bg">
              <Header />
              <NavbarLeft />
              <Searchbar />
              <Cart />
              {shop_header && <ShopBreadcrumb brdData={shop_header} />}
              {categories && (
                <ProductFilter
                  cats={categories}
                  section_info={shop_categories}
                  a_product={all_products}
                />
              )}
              {business_partners_data && (
                <Brandpartner partnerData={business_partners_data} />
              )}
              <Footer />
            </div>,
          ]}
    </>
  );
}
