import React from "react";
import { Link } from "react-router-dom";
// import Ozil from "../../assets/images/ozil.webp";
import { BtnUnderLineBlack } from "../buttons/Button";

const InfluenceSec = ({ influenceData }) => {
  return (
    <div className="incluence-section my-[100px]">
      <div className="innaree-container">
        <div className="grid min-768:grid-cols-2 gap-4 min-768:items-start min-1024:items-center">
          {/* Ozil */}
          <div>
            {influenceData.blog_image.url && (
              <img
                className="max-w-[300px] min-1024:max-w-[390px] min-1250:max-w-[470px]"
                src={influenceData.blog_image.url}
                alt="Ozil"
                data-aos="zoom-in-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              />
            )}
          </div>

          <div>
            <h3
              className="text-[32px] font-bold mb-6"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="1000"
            >
              {influenceData.blog_title && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: influenceData.blog_title,
                  }}
                />
              )}
            </h3>
            <p
              className="min-768:text-[14px] min-1024:text-[20px] font-medium mb-6 min-768:mb-14"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="1100"
            >
              {influenceData.blog_description && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: influenceData.blog_description,
                  }}
                />
              )}
            </p>
            <div
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="800"
            >
              <a
                target="_blank"
                href={influenceData.blog_link.url}
                className={`un-btn-black ont-red-hat font-bold border-b border-b-innaree-secondary-color text-[16px] text-white`}
              >
                Find out more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluenceSec;
