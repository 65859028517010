import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
// import shopBg1 from "../assets/images/shopBg11.png";
// import shopImg1 from "../assets/images/shopImg1.webp";
import Brandpartner from "../components/homepage/Brandpartner";
import ProductFilter from "../components/shoppage/ProductFilter";
import ShopBreadcrumb from "../components/shoppage/ShopBreadcrumb";
import Footer from "../components/layout/Footer";
import "../components/singleproduct/SingleProduct.scss";
import Cart from "../components/cartpage/Cart";
import Searchbar from "../components/searchbar/Searchbar";
import { HomepageStoreContext } from "../context/HomepageStore";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";
import CategoryProduct from "../components/shoppage/CategoryProduct";

export default function ShopPageWithCategory() {
  // Get the product param from the URL.
  let { slug } = useParams();
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeData, setHomeData } =
    useContext(HomepageStoreContext);
  const { business_partners_data } = homeData;

  /******************************************
   *
   * components functions and state
   *
   ******************************************/
  const [shopData, setshopData] = useState([]);
  const [loading, setLoading] = useState(false);

  function fetchrelatedData() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/shop/`)
      .then((res) => {
        setshopData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchrelatedData();
  }, [slug]);

  // distructure data from value
  const { shop_header, categories, all_products } = shopData;
  return (
    <>
      <Helmet>
        <title>Innaree - shop by category</title>
      </Helmet>
      {loading
        ? [<Preloader />]
        : [
            <div className="body-bg">
              <Header />
              <NavbarLeft />
              <Searchbar />
              <Cart />
              {shop_header && <ShopBreadcrumb brdData={shop_header} />}
              <CategoryProduct slug={slug} />

              {business_partners_data && (
                <Brandpartner partnerData={business_partners_data} />
              )}
              <Footer />
            </div>,
          ]}
    </>
  );
}
