import React from "react";

export default function SingleFeatureCard({ icon, title, text }) {
  return (
    // <div className="bg-[#a5a5a51a] backdrop-blur-[18px] rounded-[15px] p-[30px] shadow transition-all hover:bg-white hover:shadow-lg">
    //   <img className="w-[82px]" src={icon} alt="" />
    //   <h4 className="text-[20px] leading-[26px] font-bold  mt-10 capitalize">
    //     {title}
    //   </h4>
    //   <p className="text-[14px] font-normal leading-[17px] mt-4">{text}</p>
    // </div>
    <div className="bg-white rounded-[15px] p-[30px] shadow duration-500 hover:-translate-y-3">
      <img className="w-[82px]" src={icon} alt="" />
      <h4 className="text-[20px] leading-[26px] font-bold  mt-10 capitalize">
        {title && (
          <span
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        )}
      </h4>
      <p className="text-[14px] font-normal leading-[17px] mt-4">
        {text && (
          <span
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
      </p>
    </div>
  );
}
