import React from "react";
import { Link } from "react-router-dom";
import about_selfcare_bg_1 from "../../assets/images/about_selfcare_bg_1.webp";
import SingleCountCard from "../singleCountCard/SingleCountCard";
import { BtnUnderLineWhite } from "../buttons/Button";
export default function SelfCare({ sectionTitle, title, des, countCards, linkto }) {
  return (
    <div
      style={{
        backgroundImage: `url(${about_selfcare_bg_1})`,
      }}
      className={`text-white py-[100px] mt-[100px] min-1024:pt-[150px] min-1024:pb-[220px] bg-center 1024:bg-cover min-1024:bg-[length:100%_100%] bg-no-repeat`}
    >
      <div className="innaree-container relative overflow-hidden">
        {/* svg as a bg */}
        <div
          className="selfcare-innare-shape"
          data-aos="flip-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1300"
        >
          <svg
            className="1024:hidden w-[1166px] h-[658px]"
            viewBox="0 0 1166 658"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_b_1_1529)">
              <path
                d="M263.881 10.7191V204.388C263.881 210.305 259.073 215.107 253.149 215.107H42.4549C36.5311 215.107 31.7232 219.909 31.7232 225.826V316.231C31.7232 322.148 26.9153 326.95 20.9915 326.95H10.7317C4.80791 326.95 0 331.753 0 337.669V533.575C0 539.492 4.80791 544.294 10.7317 544.294H206.126C212.05 544.294 216.858 539.492 216.858 533.575V490.145C216.858 484.228 221.666 479.426 227.589 479.426H288.145H737.617C743.541 479.426 748.349 484.228 748.349 490.145V647.281C748.349 653.198 753.156 658 759.08 658H949.24C955.164 658 959.971 653.198 959.971 647.281V457.33C959.971 451.413 955.164 446.611 949.24 446.611H876.642C870.718 446.611 865.91 441.808 865.91 435.892V323.128C865.91 317.211 870.718 312.409 876.642 312.409H1155.27C1161.19 312.409 1166 307.607 1166 301.69V109.51C1166 103.593 1161.19 98.7905 1155.27 98.7905H949.427C943.503 98.7905 938.695 93.9882 938.695 88.0714V10.7191C938.695 4.80226 933.887 0 927.964 0H274.605C268.681 0 263.881 4.79478 263.881 10.7191Z"
                fill="#0B3142"
                fillOpacity="0.7"
              />
            </g>
            <defs>
              <filter
                id="filter0_b_1_1529"
                x="-40"
                y="-40"
                width="1246"
                height="738"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_1_1529"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_1_1529"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>

        {/* main content */}
        <div className="flex 1024:flex-wrap min-1024:absolute top-[5%] left-[4%] ">
          <div className="min-1024:w-56 w-full">
            <h2
              className="text-[62px] font-bold leading-[82px] 1024:text-center"
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1300"
              data-aos-delay="500"
            >
              {sectionTitle}
            </h2>
          </div>

          <div
            className="min-1024:w-[40rem] 1024:mt-[30px] w-full min-1024:px-[47px] min-1024:pt-[10px] min-1024:pb-[40px] min-1024:pr-[100px]  1024:text-center"
            data-aos="fade-down"
            data-aos-duration="1300"
            data-aos-anchor-placement="top-bottom"
            data-aos-easing="ease-in-back"
            data-aos-delay="500"
          >
            <h4 className="text-[32px] leading-[42px] font-bold text-white">
              {/* {title} */}
              {title && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              )}
            </h4>
            <p className="text-[18px] leading-[26px] font-normal mt-4 mb-[10px]">
              {des && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: des,
                  }}
                />
              )}
            </p>

            <BtnUnderLineWhite btnUrl={linkto} title="About us" />
          </div>
        </div>

        {/* single counter wrapper */}
        <div className="1024:mt-[60px]">
          {/* single item */}

          <SingleCountCard
            wrapperClass={
              "min-1024:absolute bottom-[23%] left-[5%] 1024:mx-auto bg-innaree-secondary-color"
            }
            headerClass={""}
            textClass={""}
            number={countCards.box_1_number}
            text={countCards.box_1_title}
          />
          {/* single item */}
          <SingleCountCard
            wrapperClass={
              "min-1024:absolute top-[21%] right-[0%] min-1440:right-[9%] bg-innaree-secondary-color 1024:mx-auto"
            }
            headerClass={""}
            textClass={""}
            number={countCards.box_2_number}
            text={countCards.box_2_title}
          />
          {/* single item */}
          <SingleCountCard
            wrapperClass={
              "min-1024:absolute bottom-[5%] right-[3%] min-1250:right-[18%] min-1440:right-[24%] bg-innaree-secondary-color 1024:mx-auto"
            }
            headerClass={""}
            textClass={""}
            number={countCards.box_3_number}
            text={countCards.box_3_title}
          />
        </div>
      </div>
    </div>
  );
}
