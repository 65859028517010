import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
import LabReportBg from "../../assets/images/lab-report-bg.png";
// import AlBioservices from "../../assets/images/al-bioservices.png";
// import Product3 from "../../assets/images/product3.webp";

const LabReport = ({ labreportData, btmreportData }) => {
  return (
    <div className="lab-report pt-[50px] pb-[50px]">
      <div className="innaree-container">
        <div className="lb-top grid grid-cols-12 mb-[40px]">
          {/* title bar */}
          <Titlebar
            smTitle={labreportData.sub_title}
            lgTitle={labreportData.title}
            ClassForWrapper={"text-start col-span-12 min-1024:col-span-6"}
          />
          <p
            className="col-span-12 min-1024:col-span-6"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
          >
            {labreportData.description}
          </p>
        </div>
        <div
          // className="lb-bottom h-auto min-576:h-[318px] min-768:h-[390px] min-1024:h-[464px] p-[50px] min-1024:py-[125px] min-1024:px-[100px] bg-no-repeat bg-[length:100%_100%] bg-center relative rounded-2xl overflow-hidden"
          className="lb-bottom h-auto min-576:h-[318px] min-768:h-[390px] min-1024:h-[464px] p-[50px] min-1024:py-[125px] min-1024:px-[100px] bg-no-repeat bg-center relative rounded-2xl overflow-hidden"
          style={{
            backgroundImage: `url(${
              btmreportData.background.url
                ? btmreportData.background.url
                : LabReportBg
            })`,
            backgroundSize: "cover",
            objectFit: "cover",
          }}
        >
          {/* title bar */}
          <Titlebar
            smTitle={btmreportData.sub_title}
            lgTitle={btmreportData.title}
            ClassForWrapper={
              "min-768:w-[420px] text-white text-start col-span-6"
            }
            ClassForlgTitle={"text-white"}
          />
          {btmreportData.logo.url && (
            <img
              className="mt-[30px] max-w-[276px]"
              src={btmreportData.logo.url}
              alt="AlBioservices"
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="500"
            />
          )}
          {btmreportData.Image.url && (
            <img
              className="absolute right-0 bottom-[-85px] min-1024:bottom-[-22px] hidden min-768:block"
              src={btmreportData.Image.url}
              alt="Product3"
              data-aos="fade-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="500"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LabReport;
