import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useQuery } from 'react-query';
import ContactBreadCrumb from "../components/contactpage/ContactBreadCrumb";
import ContactSection from "../components/contactpage/ContactSection";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import "../../src/components/contactpage/contactpage.scss";
import Footer from "../components/layout/Footer";
import Cart from "../components/cartpage/Cart";
import Searchbar from "../components/searchbar/Searchbar";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";
export default function Contact() {
  /******************************************
*
* components functions and state
*
******************************************/
  const [contactData, setcontactData] = useState([]);

  // get all about page data
  const { isLoading: contactIsLoading, error: contactError, data: querycontactData } = useQuery(
    'contactpage-query-key',
    async () => {
      const response = await axios.get(`${base_url}/wp-json/softtechit/v1/innaree/contact`);
      return response.data;
    }
  );

  useEffect(() => {
    if (querycontactData) {
      setcontactData(querycontactData);
    }
  }, [querycontactData]);

  const { contact_header, contact_us_form } = contactData;
  return (
    <>
      <Helmet>
        <title>Innaree - contact</title>
      </Helmet>
      {contactIsLoading ? [
        <Preloader />
      ] : [
        <div className="body-bg">
          <Header />
          <NavbarLeft />
          <Cart />
          <Searchbar />
          {contact_header &&
            <ContactBreadCrumb brdData={contact_header} />
          }
          {
            contact_us_form &&
            <ContactSection formData={contact_us_form} />
          }
          <Footer />
        </div>
      ]}
    </>
  );
}
