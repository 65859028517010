import React from "react";

export default function Preloader() {
  return (
    <div className="flex justify-center items-center" key={"loading_innaree"}>
      <section className="h-[100vh] w-full flex justify-center items-center bg-innaree-primary-color">
        <div className="loading loading02 text-white">
          <span
            className="pr-4 text-[50px] min-576:text-[85px] inline-block"
            key={"I"}
            data-text="I"
          >
            I
          </span>
          <span
            className="pr-4 text-[50px] min-576:text-[85px] inline-block"
            key={"N1"}
            data-text="N"
          >
            N
          </span>
          <span
            className="pr-4 text-[50px] min-576:text-[85px] inline-block"
            key={"N2"}
            data-text="N"
          >
            N
          </span>
          <span
            className="pr-4 text-[50px] min-576:text-[85px] inline-block"
            key={"A"}
            data-text="A"
          >
            A
          </span>
          <span
            className="pr-4 text-[50px] min-576:text-[85px] inline-block"
            key={"R"}
            data-text="R"
          >
            R
          </span>
          <span
            className="pr-4 text-[50px] min-576:text-[85px] inline-block"
            key={"E1"}
            data-text="E"
          >
            E
          </span>
          <span
            className="text-[50px] min-576:text-[85px]"
            key={"E2"}
            data-text="E"
          >
            E
          </span>
        </div>
      </section>
    </div>
  );
}
