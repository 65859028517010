import React from "react";
import { Link } from "react-router-dom";
import BreadCrumbBg from "../../assets/images/contactBread_bg.png";
import ContantRightImg from "../../assets/images/contact_img_1.webp";
import { BiPhoneCall } from "react-icons/bi";
export default function ContactBreadCrumb({ brdData }) {
  return (
    <div
      style={{
        backgroundImage: `url(${
          brdData.background.url ? brdData.background.url : BreadCrumbBg
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
      // className={`text-white pt-[250px] pb-[160px] bg-center 1024:bg-cover min-1024:bg-[length:100%_100%] bg-no-repeat`}
      className={`text-white h-auto pt-[150px] min-576:pt-[250px] pb-[80px] min-576:pb-[160px] bg-no-repeat bg-bottom`}
    >
      <div className="innaree-container grid grid-cols-4 place-items-center">
        <div className="col-span-4 min-1024:col-span-2 576:text-center">
          <div
            className="text-white text-[20px] leading-[26px] capitalize inline-block font-semibold"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1300"
          >
            <Link className="" to={"/"}>
              Home
            </Link>
            <span className="inline-block mx-2">/</span>
            Contact Us
          </div>
          <h2
            className="font-bold text-[40px] min-768:text-[60px] leading-[79.38px] text-white"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1300"
          >
            {brdData.title}
          </h2>
        </div>
        <div
          style={{
            backgroundImage: `url(${ContantRightImg})`,
          }}
          className="relative bg-cover min-320:bg-none  min-576:bg-[length:100%_100%] min-576:pb-[80px] min-576:px-[70px] min-576:pt-[100px] min-768:px-[80px] min-768:py-[80px] min-768:pt-[115px] min-768:pb-[120px] min-768:pl-[60px] min-768:pr-[110px] col-span-4 min-1024:col-span-2 576:text-center"
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1300"
        >
          <span className="text-[40px] absolute min-1440:top-[12px] min-1440:right-[16px] min-1024:top-[21px] min-1024:right-[9px] min-768:top-[12px] min-768:right-[23px] min-576:top-[12px] min-576:right-[12px] 576:hidden">
            <BiPhoneCall />
          </span>
          <p
            className="font-semibold text-[16px] leading-[28px]"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1300"
            data-aos-delay="800"
          >
            {/* {brdData.description} */}
            {brdData.description && (
              <span dangerouslySetInnerHTML={{ __html: brdData.description }} />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
