import React from "react";
import { Link } from "react-router-dom";
import DeliveryShape1 from "../../assets/images/delivery-breadcrum-shape.webp";
import DeliveryShape2 from "../../assets/images/delivery-breadcrum-shape2.webp";

const DeliveryBreadcrumb = ({ brdData }) => {
  return (
    <div
      // className="delivery-breadcrumb-area h-auto pt-[150px] min-576:pt-[280px] pb-[160px] bg-no-repeat bg-cover min-768:bg-[length:100%_100%] bg-center"
      className="delivery-breadcrumb-area h-auto pt-[150px] min-576:pt-[280px] pb-[160px] bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${
          brdData.background.url ? brdData.background.url : DeliveryShape1
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <div className="innaree-container">
        <div className="min-768:grid grid-cols-2 gap-20">
          <div className="mb-[50px] min-768:mb-0">
            <div
              className="text-white text-[20px] font-semibold inline-block mb-[17px]"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              <Link to="/">Home</Link> / Delivery
            </div>
            {brdData.title && (
              <h3
                className="text-[32px] text-white font-bold mb-[15px]"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                {/* Delivery Policy */}
                {/* {brdData.title} */}
                {brdData.title && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: brdData.title,
                    }}
                  />
                )}
              </h3>
            )}
            {brdData.description && (
              <p
                className="text-white text-[14px]"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                {brdData.description}
                {brdData.description && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: brdData.description,
                    }}
                  />
                )}
              </p>
            )}
          </div>
          {brdData.img.url && (
            <div>
              <img
                src={brdData.img.url ? brdData.img.url : DeliveryShape2}
                alt="devliery-shape"
                data-aos="zoom-in-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="500"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryBreadcrumb;
