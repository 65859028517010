import React from "react";
import { Link } from "react-router-dom";
import CtaProducts from "../../assets/images/cta-products.png";
import CtaBg from "../../assets/images/cta-bg.webp";
import HelpingCtaBg from "../../assets/images/healping-cta.png";
import { BtnUnderLineWhite } from "../buttons/Button";

const HelpingCta = ({ ctaData }) => {
  return (
    <div
      // className="cta-ara mt-[100px] bg-center bg-no-repeat bg-auto min-768:bg-[length:100%_100%] relative pt-[57px] pb-[70px]"
      className="cta-ara mt-[100px] bg-center bg-no-repeat relative pt-[57px] pb-[70px]"
      style={{
        backgroundImage: `url(${HelpingCtaBg})`,
        // backgroundSize: "cover",
        // objectFit: "cover",
      }}
    >
      <div className="innaree-container z-20 relative">
        <div className="min-768:grid min-768:grid-cols-2 min-768:gap-4 items-center">
          <div className="acc-left mb-[50px] min-768:mb-0 text-center min-768:text-start">
            <h1
              className="text-white text-[38px] min-1024:text-[48px] font-bold mb-[20px] capitalize"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              {/* {ctaData.title} */}
              {ctaData.title && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: ctaData.title,
                  }}
                />
              )}
            </h1>
            <a
              target="_blank"
              href={ctaData.button_link.url}
              className={`ont-red-hat font-bold border-b border-b-innaree-secondary-color 
            text-[16px] text-white hover:text-in-rating-color-golden`}
            >
              Find out more
            </a>
          </div>
          <div className="acc-right">
            {ctaData.gallery && (
              <img
                src={ctaData.gallery[0]}
                alt=""
                data-aos="zoom-in"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="200"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpingCta;
