import "./App.css";
import { Routes, Route, Router } from "react-router-dom";
import HomePage from "./pages/Homepage";
import Test from "./pages/Test";
import ReportingPage from "./pages/ReportingPage";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import ShopPage from "./pages/ShopPage";
import Delivery from "./pages/Delivery";
import ShopDetails from "./pages/ShopDetails";
import Helping from "./pages/Helping";
import About from "./pages/About";
import Checkoutpage from "./pages/Checkoutpage";
import Loginpage from "./pages/Loginpage";
import Registerpage from "./pages/Registerpage";
import Profilepage from "./pages/auth/Profilepage";
import ShopPageWithCategory from "./pages/ShopPageWithCategory";
import Success from "./pages/Success";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Cancelled from "./pages/Cancelled";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import SearchedProduct from "./pages/SearchedProduct";
import ProtectedRoute from "./routes/ProtectedRoute";
import CheckoutProtectedRoute from "./routes/CheckoutProtectedRoute";
import { useEffect } from "react";
function App() {
  // useEffect(() => {
  //   var console = {};
  //   console.log = function () {};
  // }, []);
  return (
    <div className="App">
      {/* <Router> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/shop/:slug" element={<ShopPageWithCategory />} />
        <Route path="/searched/:slug" element={<SearchedProduct />} />
        <Route path="/shop-details/:productId" element={<ShopDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/reporting" element={<ReportingPage />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/about" element={<About />} />
        <Route path="/helping" element={<Helping />} />
        <Route path="/login" element={<Loginpage />} />
        <Route path="/forget-pass" element={<Loginpage />} />
        <Route path="/register" element={<Registerpage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/profile" exact element={<Profilepage />} />
        </Route>
        <Route element={<CheckoutProtectedRoute />}>
          <Route path="/checkout" element={<Checkoutpage />} />
        </Route>
        <Route path="/success/:id" element={<Success />} />
        <Route path="/cancel" element={<Cancelled />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<BlogDetails />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/return" element={<ReturnPolicy />} />
        <Route path="/cookie" element={<CookiePolicy />} />
        <Route path="/test" element={<Test />} />
      </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;
