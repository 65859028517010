import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DeliveryBreadcrumb from "../components/deliverypage/deliveryBreadcrumb";
import Brandpartner from "../components/homepage/Brandpartner";
import Footer from "../components/layout/Footer";
import ShippingInfo from "../components/deliverypage/ShippingInfo";
import BusinessPartner from "../components/deliverypage/BusinessPartner";
import DeliveryAccordion from "../components/deliverypage/DeliveryAccordion";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import "../components/deliverypage/deliverypage.scss";
import Cart from "../components/cartpage/Cart";
import Searchbar from "../components/searchbar/Searchbar";
import { HomepageStoreContext } from "../context/HomepageStore";
import { useQuery } from "react-query";
import axios from "axios";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";

export default function Delivery() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeData, setHomeData } =
    useContext(HomepageStoreContext);
  const { business_partners_data } = homeData;

  /******************************************
   *
   * components functions and state
   *
   ******************************************/
  const [deliveryData, setdeliveryData] = useState([]);

  // get all delivery page data
  const {
    isLoading: deliveryIsLoading,
    error: deliveryError,
    data: querydeliveryData,
  } = useQuery("deliverypage-query-key", async () => {
    const response = await axios.get(
      `${base_url}/wp-json/softtechit/v1/innaree/delivery/`
    );
    return response.data;
  });

  useEffect(() => {
    if (querydeliveryData) {
      setdeliveryData(querydeliveryData);
    }
  }, [querydeliveryData]);

  // distructure data from value
  const { delivery_header, delivery_business_partners, delivery_process } =
    deliveryData;

  return (
    <>
      <Helmet>
        <title>Innaree - delivery</title>
      </Helmet>
      {deliveryIsLoading
        ? [<Preloader />]
        : [
          <div>
            <Header />
            <NavbarLeft />
            <Cart />
            <Searchbar />
            {delivery_header && (
              <DeliveryBreadcrumb brdData={delivery_header} />
            )}
            {delivery_header && (
              <ShippingInfo shippingData={delivery_header.delivery_box} />
            )}

            {
              delivery_business_partners &&
              <BusinessPartner bspartnerData={delivery_business_partners} />
            }
            {delivery_process &&
              <DeliveryAccordion deliveryaccordionData={delivery_process} />
            }
            {business_partners_data && (
              <Brandpartner partnerData={business_partners_data} />
            )}
            <Footer />
          </div>,
        ]}
    </>
  );
}
