import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useRef, useContext } from "react";
import Products from "../../store/Product.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Titlebar } from "../titlebar/Titlebar";
import "../../components/singleproduct/SingleProduct.scss";
// import Product1 from '../../assets/images/product1.webp';
// import HoverImg2 from "../../assets/images/product-hover-img2.png";
import { BadgeOne, BadgeTwo, ButtonSeven, ButtonSix } from "../buttons/Button";
import Tilt from "react-tilt";
import { RiStarFill, RiStarHalfFill } from "react-icons/ri";
import { StoreContext } from "../../context/Store";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import StarRating from "../../helper/StarRating";
export default function RelatedProdcut({ products }) {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    // add to cart
    cartItems,
    addItemToCart,
  } = useContext(StoreContext);
  const swiperRef = useRef();

  // handle add to cart (check if item already added to cart)
  const handleAddToCart = (product) => {
    if (cartItems.some((item) => item.id == product.id)) {
      // toast("Product already added!!.");
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Product already added!
            </div>
          </div>
        </div>
      );
    } else {
      addItemToCart(product);
      toast.custom(
        <div>
          <div
            id="toast-success"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Product Added Successfully
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="single-details-slider mb-[100px]">
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          duration: 1000,
        }}
      />
      {/* title bar */}
      <div className="innaree-container flex 768:flex-wrap justify-between items-center">
        <Titlebar
          lgTitle="Looking for Something Else?"
          ClassForWrapper="text w-[300px] mx-auto px-4 py-4 text-center"
          ClassForsmTitle=""
          ClassForlgTitle=""
        />
      </div>

      {/* product slider */}
      <div className="innaree-container">
        <Swiper
          slidesPerView={1}
          // centeredSlides={true}
          // roundLengths={true}
          centeredSlidesBounds={true}
          // watchSlidesProgress={true}
          // parallax={true}
          // loop={true}
          // navigation={true}
          breakpoints={{
            // when window width is >= 320px
            320: {
              width: 320,
              slidesPerView: 1,
            },
            // when window width is >= 576px
            576: {
              width: 576,
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              // width: 768,
              slidesPerView: 2,
            },
            // when window width is >= 1024px
            1024: {
              // width: 1024,
              slidesPerView: 3,
              spaceBetween: 10,
            },
            // when window width is >= 1440px
            1250: {
              // width: 1250,
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {products &&
            products.map((product, index) => (
              <SwiperSlide key={index}>
                <div className="product-cart-wrap group w-[270px] min-576:w-[300px] py-9 relative transition-all duration-500  hover:rounded-[20px]">
                  <div className="product-img-wrap pb-4">
                    {/* Product Two Hover Image */}
                    {/* <img
                    src={HoverImg2}
                    alt="innaree-leaf"
                    className="product-hover-img 768:hidden w-[200px] transition-all duration-500"
                  /> */}

                    {/* <Tilt className="Tilt" options={{ max: 25 }}> */}
                    <a>
                      <img
                        className="max-w-[100%] max-h-[300px] ma m-auto block group-hover:hidden"
                        src={product.product_thumnails}
                        alt="product1"
                      />
                      <img
                        className="max-w-[100%] max-h-[300px] ma m-auto hidden group-hover:block"
                        src={product.product_hover_image.url}
                        alt="product1"
                      />
                    </a>
                    {/* </Tilt> */}
                  </div>
                  <div className="product-content-wrap text-center relative">
                    <div className="product-badges mb-[20px]">
                      <BadgeOne className="mr-4" /> <BadgeTwo />
                    </div>
                    <div className="action-buttons">
                      <button
                        onClick={() => handleAddToCart(product)}
                        type="button"
                        className="font-red-hat mr-2 text-white bg-innaree-primary-color border-2 border-transparent h-[55px] w-[55px] rounded-full font-medium text-sm  hover:shadow-in-boxShadow-primary-color hover:-translate-y-1 duration-500"
                      >
                        ADD
                      </button>

                      <Link
                        to={`/shop-details/${product.slug}`}
                        className="font-red-hat text-center inline-block ml-2 text-black bg-white border-2 border-innaree-secondary-color py-[15px]  h-[55px] w-[55px] rounded-full font-medium text-sm hover:text-black hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500"
                      >
                        View
                      </Link>
                    </div>
                    <Link
                      className="block text-[16px] font-bold mb-4"
                      to={`/shop-details/${product.slug}`}
                    >
                      {/* Oil Drops 10ML 500MG CBD */}
                      {product.name}
                    </Link>

                    <StarRating averageRating={product.average_ratings} review_count={product.review_count} />

                    {/* <h4 className="product-price text-[24px] font-bold">£22.99</h4> */}
                    <h4 className="product-price text-[24px] font-bold">
                      £{product.price}
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}
