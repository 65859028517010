import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
import BusinessPartnerShape from "../../assets/images/business-partner-shape.webp";
// import THCFree from "../../assets/images/thc-free.webp";
// import Organic from "../../assets/images/organic.webp";
// import Halal from "../../assets/images/halal.webp";
// import Gmo from "../../assets/images/gmo.webp";
// import Vegan from "../../assets/images/vegan.webp";
// import Product8 from "../../assets/images/product8.webp";
import { FaQuoteLeft } from "react-icons/fa";

const BusinessPartner = ({ bspartnerData }) => {
  return (
    <div
      // className="bg-no-repeat bg-cover min-1024:bg-[length:100%_100%] bg-center py-[65px] mt-[100px] relative min-768:h-[80vh]"
      className="py-[65px] mt-[100px] relative min-768:h-[80vh] bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${
          bspartnerData.background.url
            ? bspartnerData.background.url
            : BusinessPartnerShape
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <div className="innaree-container">
        {/* Section Title */}
        <Titlebar
          smTitle={bspartnerData.sub_title}
          lgTitle={bspartnerData.title}
          ClassForWrapper={"min-768:w-[420px] m-auto text-center"}
          ClassForsmTitle={"text-white"}
          ClassForlgTitle={"text-white"}
        />

        <div className="grid grid-cols-1 min-768:grid-cols-2 min-1024:grid-cols-3 place-items-center mt-[40px]">
          {/* Partner Logos */}
          <div className="mb-[30px] min-768:mb-0 flex justify-center flex-wrap place-items-center gap-4">
            {bspartnerData.bp_logos &&
              bspartnerData.bp_logos.map((logo, index) => (
                <img
                  key={`bs_p_key_${index}`}
                  className="h-[60px] min-1024:h-[80px] min-1600:h-[90px] w-[60px] min-1024:w-[80px] min-1600:w-[90px] rounded-lg inline-block"
                  src={logo.bp_logo}
                  alt="brand"
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1000"
                />
              ))}
          </div>

          {/* Product Image */}
          <div className="min-1024:pt-[62px]">
            {bspartnerData.product_img.url && (
              <img
                className="min-1024:absolute bottom-[10%] min-1024:left-[36%] min-1250:left-[36%] max-w-[300px] min-1600:max-w-[400px] min-1920::max-w-[587px]"
                src={bspartnerData.product_img.url}
                alt="Porduct"
                data-aos="zoom-in"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                data-aos-delay="1200"
              />
            )}
          </div>
          {/* Review */}
          <div
            className="min-768:col-span-2 min-1024:col-span-1 mt-[47px] min-1024:mt-0"
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <h4 className="text-[24px] text-white font-semibold mb-[15px] text-center min-1024:text-start">
              {bspartnerData.right_title && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: bspartnerData.right_title,
                  }}
                />
              )}
            </h4>
            <div className="flex items-center justify-center min-1024:justify-start">
              <FaQuoteLeft className="text-innaree-secondary-color text-[20px] inline-block mr-[10px]" />
              <p className="text-white text-[14px]">
                {" "}
                {/* {bspartnerData.quote_author} */}
                {bspartnerData.quote_author && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: bspartnerData.quote_author,
                    }}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessPartner;
