import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ButtonEight } from "../buttons/Button";
import CheckoutSteps from "../checkoutsteps/CheckoutSteps";
// import Prodcut10 from "../../assets/images/prodcut10.jpg";
import { FaDotCircle } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";
import { StoreContext } from "../../context/Store";

const ShippingSecondStep = ({ showDetails, checkShipingChargeByCountry, handleOnChangeShippingAmount, shi_Amount }) => {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    checkoutSteps,
    setcheckoutSteps,
    cartItems,
    subtotal,
    dicountAndShippingData,
  } = useContext(StoreContext);


  // useEffect(() => {
  //   console.log('data from store for innaree', dicountAndShippingData);
  //   console.log('data from store for innaree deep', dicountAndShippingData.shipping_charger.shipping_uk.amount_1);
  // }, []);
  return (
    <div className="shipping-page">
      <div className="innaree-container">
        <div className="min-1024:grid grid-cols-2 gap-10">
          {/* Leftside */}
          <div className="pt-[30px] min-576:pt-[80px] pb-[50px] bg-white min-1250:px-[50px]">
            <Link className="inline-block" to="/">
              <h1 className="text-[48px] text-black font-bold uppercase tracking-[5px]">
                Innaree
              </h1>
            </Link>
            {/* checkout step */}
            <div className="checkoutsteps flex items-center">
              <Link className="text-[14px] text-black " to="">
                Information
              </Link>
              <AiOutlineRight className="text-[14px] mx-[5px] inline-block" />
              <Link
                className="text-[14px] text-black steps-active font-bold "
                to=""
              >
                Shipping
              </Link>
              <AiOutlineRight className="text-[14px] mx-[5px] inline-block" />
              <Link className="text-[14px] text-black" to="">
                Payment
              </Link>
            </div>

            {/* Contact Info */}
            <div className="contact-info mt-[30px] rounded p-[12px] border border-innaree-border-darkgray">
              <div className="contact-wrapper grid grid-cols-12 items-start border-b border-innaree-border-darkgray pb-[10px]">
                <div className="320:col-span-9 col-span-10 items-center">
                  <div className="grid grid-cols-12 gap-4">
                    <span className="text-[#737373] text-[16px] block min-576:inline-block 320:col-span-12 col-span-3">
                      Contact
                    </span>
                    <span className="text-[14px] 320:col-span-12 col-span-9">
                      {showDetails.email && showDetails.email}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => setcheckoutSteps(1)}
                  className="text-[16px] text-in-rating-color-golden 320:col-span-3 col-span-2"
                >
                  Change
                </button>
              </div>
              <div className="ship-info grid grid-cols-12 items-start pt-[10px]">
                <div className="320:col-span-9 col-span-10">
                  <div className="grid grid-cols-12 gap-4">
                    <span className="text-[#737373] text-[16px] block min-576:inline-block 320:col-span-12 col-span-3">
                      Ship to
                    </span>
                    <span className="text-[14px] 320:col-span-12 col-span-9">
                      {showDetails.addressOne && showDetails.addressOne}
                      {showDetails.addressTwo && showDetails.addressTwo}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => setcheckoutSteps(1)}
                  className="text-[16px] text-in-rating-color-golden 320:col-span-3 col-span-2"
                >
                  Change
                </button>
              </div>
            </div>

            {/* Shipping Method */}
            <div className="shipping-method mt-[30px]">
              <h4 className="text-[24px] font-semibold mb-[20px]">
                Shipping Method
              </h4>

              {/* check shipping by country */}

              {checkShipingChargeByCountry == "United Kingdom" ? (
                <>
                  {dicountAndShippingData.shipping_charger.shipping_uk &&
                    <>
                      <div onClick={() => handleOnChangeShippingAmount(dicountAndShippingData.shipping_charger.shipping_uk.amount_1, dicountAndShippingData.shipping_charger.shipping_uk.days_1)} className="rounded cursor-pointer p-[12px] border border-innaree-border-darkgray">
                        <div className="ship-info grid grid-cols-12 pt-[10px]">
                          <span className={`${dicountAndShippingData.shipping_charger.shipping_uk.amount_1 == shi_Amount ? 'text-in-rating-color-golden' : 'text-gray-400'}  text-[16px] col-span-1 inline-block mt-[5px]`}>
                            <FaDotCircle />
                          </span>
                          <div className="mail-info col-span-11 flex justify-between items-start">
                            <div>
                              <span className="text-[14px] text-[#737373] ">
                                {dicountAndShippingData.shipping_charger.shipping_uk.days_1 &&
                                  dicountAndShippingData.shipping_charger.shipping_uk.days_1
                                }
                              </span>
                            </div>
                            <span className="text-[16px]">
                              £{dicountAndShippingData.shipping_charger.shipping_uk.amount_1 && dicountAndShippingData.shipping_charger.shipping_uk.amount_1}</span>
                          </div>
                        </div>
                      </div>

                      <div onClick={() => handleOnChangeShippingAmount(dicountAndShippingData.shipping_charger.shipping_uk.amount_2, dicountAndShippingData.shipping_charger.shipping_uk.days_2)} className="rounded cursor-pointer p-[12px] border border-innaree-border-darkgray mt-4">
                        <div className="ship-info grid grid-cols-12 pt-[10px]">
                          <span className={`${dicountAndShippingData.shipping_charger.shipping_uk.amount_2 == shi_Amount ? 'text-in-rating-color-golden' : 'text-gray-400'}  text-[16px] col-span-1 inline-block mt-[5px]`}>
                            <FaDotCircle />
                          </span>
                          <div className="mail-info col-span-11 flex justify-between items-start">
                            <div>
                              <span className="text-[14px] text-[#737373] ">
                                {dicountAndShippingData.shipping_charger.shipping_uk.days_2 &&
                                  dicountAndShippingData.shipping_charger.shipping_uk.days_2
                                }
                              </span>
                            </div>
                            <span className="text-[16px]">
                              £{dicountAndShippingData.shipping_charger.shipping_uk.amount_2 && dicountAndShippingData.shipping_charger.shipping_uk.amount_2}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </>
              ) : (
                [
                  dicountAndShippingData.shipping_charger.shipping_international &&
                  <div onClick={() => handleOnChangeShippingAmount(dicountAndShippingData.shipping_charger.shipping_international.amount_1)} className="rounded cursor-pointer p-[12px] border border-innaree-border-darkgray">
                    <div className="ship-info grid grid-cols-12 pt-[10px]">
                      <span className="text-in-rating-color-golden text-[16px] col-span-1 inline-block mt-[5px]">
                        <FaDotCircle />
                      </span>
                      <div className="mail-info col-span-11 flex justify-between items-start">
                        <div>
                          <p className="text-[16px]">International</p>
                          <span className="text-[14px] text-[#737373] ">
                            {dicountAndShippingData.shipping_charger.shipping_international.days_2 &&
                              dicountAndShippingData.shipping_charger.shipping_international.days_2
                            }
                          </span>
                        </div>
                        <span className="text-[16px]">
                          £{dicountAndShippingData.shipping_charger.shipping_international.amount_1 &&
                            dicountAndShippingData.shipping_charger.shipping_international.amount_1
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                ]
              )}
            </div>

            {/* Action Buttons */}
            <div className="actions hidden min-1024:flex items-center justify-between mt-[50px]">
              <Link
                onClick={() => setcheckoutSteps(1)}
                className="text-[16px] flex items-center"
                to=""
              >
                <FaChevronLeft className="inline-block mr-1 text-[14px]" />
                <span>Return to information</span>
              </Link>
              <button
                onClick={() => setcheckoutSteps(3)}
                type="button"
                className={`text-white font-semibold uppercase bg-innaree-secondary-color px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 `}
              >
                Continue to payment
              </button>
              {/* <ButtonEight buttonClass="" title="Continue to payment" /> */}
            </div>
          </div>

          {/* rightside */}
          <div className="bg-white min-576:bg-[#FAFAFA] min-576:pt-[80px] pb-[50px] min-576:px-[50px]">
            <div className="added-prodcuts border-b border-innnree-border-darkgray mb-[20px]">
              {/* Single Item */}
              {cartItems &&
                cartItems.map((product) => (
                  <div
                    key={product.id}
                    className="cart-item mb-[30px] flex items-start min-1024:items-center justify-start mr-[30px]"
                  >
                    <div className="product-img h-[100px] w-[100px] mr-[35px] min-1024:mr-[10px] flex justify-center items-center">
                      <img
                        className="h-[80px]"
                        src={product.product_thumnails}
                        alt="product"
                      />
                    </div>
                    <div className="prodcut-details  min-1024:flex grow flex-wrap items-center justify-between">
                      <div className="prodcut-heading flex flex-col">
                        <div className="prodcut-title text-[16px] font-bold mb-[5px]">
                          {product.name}
                        </div>
                        <div className="prodcut-category mb-[5px] min-1024:mb-0">
                          {product.categories &&
                            product.categories.map((cat) => (
                              <span key={cat.id} className="text-[16px]">
                                {cat.name}
                              </span>
                            ))}
                        </div>
                      </div>
                      <div className="cart-final w-full"></div>
                      <div className="prodcut-price mr-0 min-1024:mr-[40px] w-full">
                        <span className="text-[16px]">
                          {" "}
                          £{product.price} * {product.qty} = {"  "}
                          {"  "}
                          {"  "} £{product.totalPriceWithQty}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="sub-total border-b border-innnree-border-darkgray mb-[20px]">
              <div className="sub-total flex justify-between">
                <span>Subtotal</span>
                <span>£{subtotal}</span>
              </div>
              <div className="sub-total flex justify-between mb-[20px]">
                <span>Shipping</span>
                <span>Calculated at next step</span>
              </div>
            </div>
            <div className="total flex justify-between mb-[20px]">
              <span className="text-[16px]">Total</span>
              <span className="text-[14px]">
                GBP
                <strong className="text-[24px] inline-block ml-[10px]">
                  £{subtotal}
                </strong>
              </span>
            </div>

            {/* Action Buttons */}
            <div className="actions min-576:flex items-center justify-between mt-[50px] block min-1024:hidden">
              <Link
                onClick={() => setcheckoutSteps(1)}
                className="text-[16px] flex items-center"
                to=""
              >
                <FaChevronLeft className="inline-block mr-1 text-[14px]" />
                <span>Return to information</span>
              </Link>
              <ButtonEight
                onClick={() => setcheckoutSteps(3)}
                buttonClass="mt-[20px] min-576:mt-0 inline-block"
                title="Continue to payment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingSecondStep;
