import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { base_url } from "../Base_url";
import Cart from "../components/cartpage/Cart";
import InformationFirstStep from "../components/checkout/InformationFirstStep";
import PaymentThirdStep from "../components/checkout/PaymentThirdStep";
import ShippingSecondStep from "../components/checkout/ShippingSecondStep";
import Thankyou from "../components/checkout/Thankyou";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Preloader from "../components/loading/Preloader";
import NavbarLeft from "../components/navbar/NavbarLeft";
import Searchbar from "../components/searchbar/Searchbar";
import { StoreContext } from "../context/Store";
import { getCookie } from "../helper/helper";

export default function Checkoutpage() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { checkoutSteps, cartItems, dicountAndShippingData } =
    useContext(StoreContext);

  const navigate = useNavigate();

  // informatmion first step data
  const [shippingInfo, setshippingInfo] = useState({
    firstName: "",
    sureName: "",
    email: "",
    confirmEmail: "",
    telephone: "",
    // country: "",
    addressOne: "",
    addressTwo: "",
    town: "",
    post: "",
    paymentMethod: "Online payment", //Online_payment
  });
  const [country, setCountry] = useState("");

  // shipping amount (pass this data for value)
  const [shippingChargeAmount, setshippingChargeAmount] = useState(0);
  const [shippingChargeMethod, setshippingChargeMethod] = useState("");

  const handleShippingAmount = (amount, shippingMethod) => {
    setshippingChargeAmount(amount);
    setshippingChargeMethod(shippingMethod);
  };

  // set customer id
  const [activeCustomerDetails, setactiveCustomerDetails] = useState(null);
  const [isdemoVivaActive, setisdemoVivaActive] = useState('1');
  const [loading, setLoading] = useState(false);

  let customerDetailsFromLocalstorage;

  // get user data
  useEffect(() => {
    if (getCookie() !== undefined) {
      setLoading(true);
      customerDetailsFromLocalstorage = JSON.parse(
        localStorage.getItem("customerDetails")
      );
      setactiveCustomerDetails(customerDetailsFromLocalstorage.id);
      setLoading(false);
    }

    // console.log('customer_id', customerDetailsFromLocalstorage.id);
  }, []);

  // get customer profile details
  function getUserDetails() {
    setLoading(true);
    axios
      .get(
        `${base_url}/wp-json/softtechit/v1/innaree/updateuser/?user_id=${customerDetailsFromLocalstorage.id}`,
        {
          // headers: { Authorization: `Bearer ${getCookie()}` },
        }
      )
      .then((res) => {
        // setuserDetails(res.data);
        setshippingInfo({
          ...shippingInfo,
          ["firstName"]: res.data.first_name,
          ["sureName"]: res.data.last_name,
          ["email"]: res.data.user_email,
          ["confirmEmail"]: res.data.user_email,
          ["telephone"]: res.data.billing_phone,
          ["country"]: res.data.billing_country,
          ["addressOne"]: res.data.billing_address_1,
          ["addressTwo"]: res.data.billing_address_2,
          ["town"]: res.data.city,
          ["post"]: res.data.billing_postcode,
        });
        setLoading(false);
        // console.log('user data dekhai dkehai', res.data);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  }

  // get user data if login
  useEffect(() => {
    setLoading(true);
    if (getCookie() !== undefined) {
      getUserDetails();
    }
    setLoading(false);
  }, []);


  // get viva payment live link
  useEffect(() => {
    setLoading(true);
    axios.get(`${base_url}/wp-json/softtechit/v1/innaree/vivapaymentcondition`).
      then((res) => {
        // console.log(res.data);
        setisdemoVivaActive(res.data.viva_payment_condition);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // for information step
  const handleOnchangeForFirstStep = (e) => {
    setshippingInfo({ ...shippingInfo, [e.target.name]: e.target.value });
  };

  // select country and shipping method
  const selectCountry = (val) => {
    setCountry(val);

    // on country change set shipping amount and and shipping method
    if (val == "United Kingdom") {
      setshippingChargeAmount(
        dicountAndShippingData.shipping_charger.shipping_uk.amount_1
      );
      setshippingChargeMethod(
        dicountAndShippingData.shipping_charger.shipping_uk.days_1
      );
    } else {
      setshippingChargeAmount(
        dicountAndShippingData.shipping_charger.shipping_international.amount_1
      );
      setshippingChargeMethod(
        dicountAndShippingData.shipping_charger.shipping_international.days_1
      );
    }
  };

  // handle payment method //third step
  const handlepaymentMethod = (value) => {
    setshippingInfo({ ...shippingInfo, ["paymentMethod"]: value });
  };

  // display content
  const displayComponent = () => {
    switch (checkoutSteps) {
      case 1:
        return (
          <InformationFirstStep
            shippingInfo={shippingInfo}
            handleOnchange={handleOnchangeForFirstStep}
            selectCountry={selectCountry}
            selectedCountryName={country}
          />
        );
      case 2:
        return (
          <ShippingSecondStep
            shi_Amount={shippingChargeAmount}
            checkShipingChargeByCountry={country}
            handleOnChangeShippingAmount={handleShippingAmount}
            showDetails={shippingInfo}
          />
        );
      case 3:
        return (
          <PaymentThirdStep
            isVivaDemo={isdemoVivaActive}
            finalShippingAmount={shippingChargeAmount}
            finalShippingMethod={shippingChargeMethod}
            customer_id={activeCustomerDetails && activeCustomerDetails}
            handlepaymentMethod={handlepaymentMethod}
            payementDetails={shippingInfo}
            selectedCountryNameForPayment={country}
          />
        );
      case 4:
        return <Thankyou />;
      default:
        return <InformationFirstStep />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Innaree - checkout</title>
      </Helmet>
      {loading
        ? [[<Preloader />]]
        : [
          <>
            <Header layout={"sm"} />
            <Cart />
            <NavbarLeft />
            <Searchbar />
            <div className="py-16">{displayComponent()}</div>
            <Footer />
          </>,
        ]}
    </>
  );
}
