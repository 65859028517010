import React from "react";
import helping_counting_bg from "../../assets/images/helping_counting_bg.webp";
import SingleCountCard from "../singleCountCard/SingleCountCard";

export default function HelpCounting({ countingData }) {
  return (
    <div
      style={{
        backgroundImage: `url(${
          countingData.helping_box_image.url
            ? countingData.helping_box_image.url
            : helping_counting_bg
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
      className={`w-full min-1024:w-[80%] 1024:mt-[100px] min-1024:mt-[-190px] p-[50px] 1024:py-[60px] min-1024:h-[450px] min-1440:h-[650px] relative  1024:flex-wrap`}
      // className={`w-full min-1024:w-[80%] 1024:mt-[100px] min-1024:mt-[-190px] p-[50px] 1024:py-[60px] min-1024:h-[450px] min-1440:h-[650px] bg-[length:100%_100%] relative  1024:flex-wrap`}
    >
      {/* single item */}
      <SingleCountCard
        wrapperClass={
          "min-1024:absolute bottom-[20%] left-[13%] bg-[#261A4D] 1024:mx-auto"
        }
        headerClass={""}
        textClass={""}
        number={countingData.small_box.box_1_number}
        text={countingData.small_box.box_1_title}
      />
      {/* single item */}
      <SingleCountCard
        wrapperClass={
          "min-1024:absolute top-[15%] right-[-3%] bg-[#261A4D] 1024:mx-auto"
        }
        headerClass={""}
        textClass={""}
        number={countingData.small_box.box_2_number}
        text={countingData.small_box.box_2_title}
      />
      {/* single item */}
      <SingleCountCard
        wrapperClass={
          "min-1024:absolute bottom-4 right-[10%] bg-[#261A4D] 1024:mx-auto"
        }
        headerClass={""}
        textClass={""}
        number={countingData.small_box.box_3_number}
        text={countingData.small_box.box_3_title}
      />
    </div>
  );
}
