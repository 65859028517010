import React, { useState } from "react";
import { AiOutlinePlayCircle } from "react-icons/ai";
import ModalVideo from "react-modal-video";
import video_img1 from "../../assets/images/video_img1.webp";

const ProductVideo = ({ videoDetails }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="prodcut-video innaree-container">
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        // videoId="MSzIYGWRXbE" //go to youTube right click and copy emeder code then place it any text file only take the value after embed code
        videoId={videoDetails && videoDetails[0]?.video_link}
        onClose={() => setOpen(false)}
      />
      <div
        className="prodcut-video-bg col-span-2 h-[300px] min-576:h-[534px] bg-center bg-no-repeat flex align-items-center left-[10%] rounded-2xl"
        style={{
          backgroundImage: `url(${
            videoDetails &&
            videoDetails[0]?.background?.["background-image"]?.url
              ? videoDetails[0]?.background?.["background-image"]?.url
              : video_img1
          })`,
          // backgroundImage: `url(${video_img1})`,
        }}
      >
        <div className="min-320:w-[100%] min-320:h-[320px] min-576:w-[420px] min-576:h-[400px] relative min-576:top-[16%] left-[6%] min-576:left-[10%]">
          <svg
            className="w-[100%] h-[100%] absolute 576:left-[-6%] top-0 left-0"
            viewBox="0 0 436 296"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_bdii_1_489)">
              <rect
                x="18"
                y="8"
                width="400"
                height="260"
                rx="24"
                fill="#4B3F72"
                fillOpacity="0.6"
                shapeRendering="crispEdges"
              />
            </g>
            <defs>
              <filter
                id="filter0_bdii_1_489"
                x="-26"
                y="-36"
                width="488"
                height="348"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="22" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_1_489"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="10" />
                <feGaussianBlur stdDeviation="9" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_backgroundBlur_1_489"
                  result="effect2_dropShadow_1_489"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect2_dropShadow_1_489"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="-22" dy="22" />
                <feGaussianBlur stdDeviation="11" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect3_innerShadow_1_489"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="22" dy="-22" />
                <feGaussianBlur stdDeviation="11" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.646745 0 0 0 0 0.646745 0 0 0 0 0.646745 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect3_innerShadow_1_489"
                  result="effect4_innerShadow_1_489"
                />
              </filter>
            </defs>
          </svg>

          <div className="absolute top-[50%] left-[6%] min-576:left-[10%]  translate-y-[-50%]">
            <h4 className="text-white text-[24px] font-bold">
              How to Use CBD Oil
            </h4>
            <div
              className="text-white flex items-center capitalize font-semibold mt-4 cursor-pointer"
              onClick={() => setOpen(true)}
            >
              <span className="inline-block mr-4 text-[30px]">
                <AiOutlinePlayCircle />
              </span>
              <span>play video</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductVideo;
