import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
// import shop_details_breadcrumb from "../../assets/images/shop_details_breadcrumb.webp";
import shop_details_breadcrumb from "../../assets/images/shop-details-header.webp";
export default function ShopDetailsBreadcrumb({ title }) {
  return (
    <div
      style={{
        backgroundImage: `url(${shop_details_breadcrumb})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
      // className={`text-white min-320:pt-[160px] min-768:pt-[270px] min-320:pb-[100px] min-768:pb-[260px] bg-center bg-[length:125%_145%] min-768:bg-[length:100%_100%] bg-no-repeat`}
      className={`text-white min-320:pt-[160px] min-768:pt-[270px] min-320:pb-[100px] min-768:pb-[260px] bg-no-repeat bg-center`}
    >
      <div className="grid  min-1024:grid-cols-2 innaree-container min-576:mt-[60px] min-768:mt-0">
        {/* breadcrumb menu */}
        <div className="text-white text-[20px] leading-[26px] capitalize inline-block font-semibold px-4 min-576:px-8">
          <Link className="" to={"/"}>
            Home
          </Link>
          <span className="inline-block mx-2">/</span>
          <Link className="">Shop</Link>
          <span className="inline-block mx-2">/</span>
          <Link className="">
            {/* {title && parse(title)} */}
            {title && <span dangerouslySetInnerHTML={{ __html: title }} />}
          </Link>
        </div>
      </div>
    </div>
  );
}
