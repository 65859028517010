import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import Footer from "../components/layout/Footer";
import "../components/homepage/homepage.scss";
import "../components/homepage/homepage.scss";
import Searchbar from "../components/searchbar/Searchbar";
import Cart from "../components/cartpage/Cart";
import toast, { Toaster } from "react-hot-toast";
import { HomepageStoreContext } from "../context/HomepageStore";
import Preloader from "../components/loading/Preloader";
import BlogBreadcrumb from "../components/blog/BlogBreadcrumb";
import axios from "axios";
import { base_url } from "../Base_url";
import parse from "html-react-parser";
// import SingleBlog from "../components/blog/singleblog/SingleBlog";
import SingleBlog from "../components/blog/singleblog/SingleBlog";
import SinngleBlogBreadcrumb from "../components/blog/singleblog/SingleBlogBreadcrumb";
import SingleBlogContent from "../components/blog/singleblog/SingleBlogContent";

export default function BlogDetails() {
  // Get the product param from the URL.
  let { blogId } = useParams();
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeData, setHomeData } =
    useContext(HomepageStoreContext);

  const { home_gallery_data, business_partners_data } = homeData;

  //=> for animation

  // single product data
  const [singleBlogData, setsingleBlogData] = useState({});
  const [relatedBlogData, setrelatedBlogData] = useState([]);
  const [sidebarBanners, setsidebarBanners] = useState([]);
  const [loading, setLoading] = useState(false);

  // get single product data from api
  function fetchBlodData() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/singlepost/${blogId}`)
      .then((res) => {
        setsingleBlogData(res.data);
        // console.log('single product data from response ', res.data);
        setLoading(false);
        // console.log('product data from state', singleBlogData)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // fetch related posts
  function fetchRelatedPost() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/relatedposts/${blogId}`)
      .then((res) => {
        setrelatedBlogData(res.data);
        // console.log('console log from blog list parent', res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // fetch  sidebar and footer image
  function fetchSidebarBanner() {
    setLoading(true);
    axios
      .get(`${base_url}/wp-json/softtechit/v1/innaree/blogs/`)
      .then((res) => {
        setsidebarBanners(res.data);
        // console.log('console log from blog list parent', res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  //   get data on load
  useEffect(() => {
    fetchBlodData();
    fetchSidebarBanner();
    fetchRelatedPost();
  }, [blogId]);


  // console.log('post data ', singleBlogData.post_categories);


  if (loading) {
    return (

      <HelmetProvider>
        <Helmet>
          <title>Innaree - blog details</title>
        </Helmet>
        <Preloader />
      </HelmetProvider>

    )
  } else {
    return (
      <HelmetProvider>
        <Helmet>
          <title>Innaree - blog details</title>
        </Helmet>
        <div className="overflow-hidden" key={"home_blog_details_wrap"}>
          <Header />
          <Searchbar />
          <NavbarLeft />
          <Cart />
          {
            singleBlogData &&
            <SinngleBlogBreadcrumb
              blogParam={blogId}
              brd_category={singleBlogData.post_categories && singleBlogData.post_categories[0]}
              brd_title={singleBlogData.post_title}
              brd_auhor_img={singleBlogData.author_avatar}
              brd_post_author_name={singleBlogData.author_display_name}
              brd_post_date={singleBlogData.post_date}
            />
          }
          {singleBlogData &&
            <SingleBlogContent
            footerblogParam={blogId}
              sidebarData={sidebarBanners && sidebarBanners}
              postContent={singleBlogData}
              postFeatureImg={singleBlogData.post_thumnail && singleBlogData.post_thumnail[0]}
              relatedpostData={relatedBlogData}
            />
          }
          <Footer />
        </div>
      </HelmetProvider>
    )
  }
}
