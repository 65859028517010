import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Titlebar } from "../titlebar/Titlebar";
import { SingleProduct } from "../singleproduct/SingleProduct";
import { ButtonOne, ButtonTwo } from "../buttons/Button";
import { FcNext } from "react-icons/fc";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import SingleTestimonial from "../singleTestimonial/SingleTestimonial";

export default function Testimonial({ testimonialData }) {
  const swiperTestimonialRef = useRef();
  return (
    <div className="overflow-hidden">
      <div className="innaree-container pt-[100px] testimonilaSlider mostpopularproductSlider">
        <div className="grid grid-cols-12">
          <div className="col-span-12 1024:mb-[60px] min-1024:col-span-4">
            <Titlebar
              smTitle={testimonialData.sub_title}
              lgTitle={testimonialData.title}
              ClassForlgTitle={"mt-4"}
              ClassForWrapper={"min-768:w-[380px]"}
            />

            {/* navigation buttons */}
            <div className="flex mt-5">
              {/* prev button */}
              <div
                className="cursor-pointer mr-4 transition-all hover:opacity-[.31]"
                onClick={() => swiperTestimonialRef.current?.slidePrev()}
              >
                <svg
                  width="62"
                  height="50"
                  viewBox="0 0 62 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="62"
                    height="50"
                    // fill="#AD8C45"
                    fill="#B09253"
                  // fillOpacity="0.31"
                  />
                  <path d="M0 49H62" stroke="white" strokeWidth="1.5" />
                  <path
                    d="M24 24.5L38.5 15V19.5H36.5V30H38.5V35L24 24.5Z"
                    fill="white"
                  />
                  <path d="M8 0H0V8L8 0Z" fill="white" />
                </svg>
              </div>

              {/* next button */}
              <div
                className="cursor-pointer transition-all hover:opacity-[.31]"
                onClick={() => swiperTestimonialRef.current?.slideNext()}
              >
                <svg
                  width="62"
                  height="50"
                  viewBox="0 0 62 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="62"
                    height="50"
                    transform="matrix(-1 0 0 1 62 0)"
                    fill="#B09253"
                  />
                  <path d="M62 49H0" stroke="black" strokeWidth="1.5" />
                  <path
                    d="M38 24.5L23.5 15V19.5H25.5V30H23.5V35L38 24.5Z"
                    fill="white"
                  />
                  <path d="M54 0H62V8L54 0Z" fill="black" />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-span-12 min-1024:col-span-8 relative min-h-[400px] overflow-y-visible">
            <div className="min-1024:absolute left-[60px] min-1250:left-0">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                slidesPerView={1}
                loop={true}
                watchSlidesProgress={true}
                parallax={true}
                modules={[Pagination, Navigation]}
                onBeforeInit={(swiper) => {
                  swiperTestimonialRef.current = swiper;
                }}
                pagination={{
                  el: ".custom-pagination",
                  clickable: true,
                }}
                breakpoints={{
                  // when window width is >= 576px
                  576: {
                    width: 576,
                    slidesPerView: 2,
                  },
                  // when window width is >= 1024px
                  1024: {
                    width: 1024,
                    slidesPerView: 3,
                  },
                }}
              >
                {testimonialData.testimonials_reviews.map((review, index) => (
                  <SwiperSlide>
                    <SingleTestimonial
                      forKey={`rv_test_about_${index}`}
                      name={review.customer_name}
                      isVerified={true}
                      title={review.review_title}
                      des={review.review_description}
                      product_title={review.review_product_title}
                      // price={review.review_product_price}
                      price={23}
                      product_img={review.review_product_image.url}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          {/* <div className="col-span-12 1024:hidden">
            <div className="custom-pagination"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
