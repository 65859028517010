import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import THCImg from "../../assets/images/THC2.webp";
import { RiMessage3Fill } from "react-icons/ri";
import { BiMessageDetail } from "react-icons/bi";
import { base_url } from "../../Base_url";
import { getCookie } from "../../helper/helper";
import ReCAPTCHA from "react-google-recaptcha";

import toast, { Toaster } from "react-hot-toast";
export default function ContactSection({ formData }) {
  // states
  const [contactInfo, setcontactInfo] = useState({
    fullName: "",
    email: "",
    subject: "",
    number: "",
    msg: "",
  });

  // data loading
  const [dataLoading, setdataLoading] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  // on change function
  const onChangeContactInfo = (e) => {
    setcontactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };
  const handleRecaptchaResponse = (response) => {
    setRecaptchaResponse(response);
  };

  // on submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      fullName: contactInfo.fullName,
      email: contactInfo.email,
      subject: contactInfo.subject,
      number: contactInfo.number,
      msg: contactInfo.msg,
    };

    // console.log('formdata before submit ', formData);
    if (
      contactInfo.fullName === "" ||
      contactInfo.email === "" ||
      contactInfo.subject === "" ||
      contactInfo.number === "" ||
      contactInfo.msg === ""
      // recaptchaResponse === ""
    ) {
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Please fill out all fields before submitting
            </div>
          </div>
        </div>
      );
    } else {
      setdataLoading(true);
      // axios
      //   .post(`${base_url}/wp-json/softtechit/v1/innaree/sendmail/`, {
      //     data: formData,
      //   })
      axios
        .post(`${base_url}/wp-json/softtechit/v1/innaree/sendmail/`, formData)
        .then((response) => {
          // console.log(response);
          if (response.status == 200) {
            setdataLoading(false);
            toast.custom(
              <div>
                <div
                  id="toast-success"
                  className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
                  role="alert"
                >
                  <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
                    <svg
                      aria-hidden="true"
                      className="w-3 h-3 rounded-[50%]"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Check icon</span>
                  </div>
                  <div className="px-4 text-sm font-normal">
                    Email send Successfully!!
                  </div>
                </div>
              </div>
            );
          }
          setcontactInfo({
            fullName: "",
            email: "",
            subject: "",
            number: "",
            msg: "",
          });
        })
        .catch((error) => {
          console.log(error);
          setdataLoading(false);
          toast.custom(
            <div>
              <div
                id="toast-danger"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Error icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  Something went wrong!!
                </div>
              </div>
            </div>
          );
          setcontactInfo({
            fullName: "",
            email: "",
            subject: "",
            number: "",
            msg: "",
          });
        });
    }
  };

  return (
    <>
      {dataLoading && (
        <div
          id="loading-screen"
          className="w-full h-full fixed block top-0 left-0 bg-white opacity-[.5] z-50"
        >
          <span className="text-green-500  opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
            {/* <i className="fas fa-circle-notch fa-spin fa-5x"></i> */}
            <svg
              aria-hidden="true"
              className=" rotate-center-fullpage inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </span>
        </div>
      )}
      {/* toast init */}
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 1000,
        }}
      />
      {/* top  */}
      <div className="min-768:grid min-768:grid-cols-7 gap-[40px] min-1024:gap-20 innaree-container mt-[100px]">
        {/* main content */}
        <div className="min-1024:pt-[30px] min-1024:pl-[40px] min-1024:pr-[20px] min-1440:px-[40px]  col-span-7 min-1024:col-span-4 min-1250:col-span-3 relative">
          <svg
            className="absolute -z-10 left-0 top-0  w-full 1024:hidden"
            viewBox="0 0 471 398"
            fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.2871 213.176H31.284H15.642C7.28271 213.176 0.5 206.39 0.5 198.025V15.6505C0.5 7.28603 7.28271 0.5 15.642 0.5H362.373C370.732 0.5 377.515 7.28603 377.515 15.6505V79.12C377.515 88.0363 384.745 95.2706 393.657 95.2706H455.358C463.717 95.2706 470.5 102.057 470.5 110.421V382.349C470.5 390.714 463.717 397.5 455.358 397.5H325.875C317.516 397.5 310.733 390.714 310.733 382.349V230.963C310.733 222.081 303.57 214.866 294.692 214.812L31.2871 213.176Z"
              fill="white"
              stroke="#B09253"
            />
          </svg>
          <div className="min-1024:w-[75%] min-1024:h-[70%] 7">
            <h5 className="font-bold text-[32px] leading-[42px] min-1024:text-[24px] min-1024:leading-[29px] capitalize mb-[20px]">
              {formData.left_title}
            </h5>
            <p className="text-16px font-normal leading-[30px]">
              {formData.left_description}
            </p>
          </div>
        </div>

        <div className="col-span-7 min-1024:col-span-3 768:mt-[60px]">
          <h4 className="text-[32px] leading-[42px] mb-[20px] font-bold capitalize">
            {formData.right_title}
          </h4>
          <p className="text-[16px] leading-[30px] font-normal">
            {/* {formData.right_description} */}
            {formData.right_description && (
              <span
                dangerouslySetInnerHTML={{ __html: formData.right_description }}
              />
            )}
          </p>
        </div>
      </div>

      {/* bottom */}
      <div className="grid min-1250:grid-cols-7 innaree-container  min-1250:mt-[26px] relative mb-[100px] mt-[47px] min-1024:mt-0">
        <img
          src={THCImg}
          className="w-[100px] absolute top-[7%]  left-[0%] 1024:hidden item-bounce"
        />
        {formData.product_img.url && (
          <img
            src={formData.product_img.url}
            className="w-[288px] absolute bottom-0 left-[-7%] 1024:hidden"
            data-aos="zoom-in-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1300"
            data-aos-delay="500"
          />
        )}
        <div className="relative col-span-12 min-1024:col-start-3 min-1024:col-span-4">
          {/* background image */}
          <div
            className="w-full 768:hidden"
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1300"
          >
            <svg
              // width="648"
              // height="509"

              viewBox="0 0 648 509"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 150.805V496.771C0 503.523 6.11881 509 13.6617 509H564.597C572.14 509 578.258 503.523 578.258 496.771V441.423C578.258 434.671 584.377 429.194 591.92 429.194H634.338C641.881 429.194 648 423.716 648 416.964V70.8011C648 64.0491 641.881 58.5718 634.338 58.5718H99.2239C91.6811 58.5718 85.5623 53.0946 85.5623 46.3427V12.2292C85.5623 5.47725 79.4435 0 71.9006 0H13.978C6.4351 0 0.31629 5.47725 0.31629 12.2292V56.6392C0.31629 63.3912 6.4351 68.8684 13.978 68.8684H59.6814C67.2242 68.8684 73.343 74.3456 73.343 81.0976V126.198C73.343 132.934 67.261 138.403 59.7365 138.428L13.6157 138.576C6.09125 138.6 0 144.069 0 150.805Z"
                fill="#B09253"
              />
            </svg>
          </div>

          <span
            className="768:hidden absolute top-[14px] left-[23px] text-[40px] text-white"
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1300"
            data-aos-delay="1000"
          >
            <BiMessageDetail />
          </span>
          <form onSubmit={handleSubmit}>
            <div className="grid min-1024:grid-cols-4 768:mt-[60px] min-768:absolute top-[50%] left-[50%] min-768:translate-x-[-50%] min-768:translate-y-[-50%] gap-4 mt-[25px] bg-innaree-secondary-color min-768:bg-transparent p-[30px] min-576:p-[50px] min-768:p-0">
              <h4 className="col-span-4 text-[20px] font-bold leading-[29px] capitalize text-white min-768:text-black">
                Send us a message
              </h4>
              <input
                className="bg-white rounded-[5px] border-none shadow-in-boxShadow-golden min-768:col-span-2 col-span-4"
                type="text"
                placeholder="Full Name"
                name="fullName"
                value={contactInfo.fullName}
                onChange={onChangeContactInfo}
              />
              <input
                className="bg-white rounded-[5px] border-none shadow-in-boxShadow-golden min-768:col-span-2 col-span-4"
                type="email"
                placeholder="Email address"
                name="email"
                value={contactInfo.email}
                onChange={onChangeContactInfo}
              />
              <input
                className="bg-white rounded-[5px] border-none shadow-in-boxShadow-golden min-768:col-span-2 col-span-4"
                type="text"
                placeholder="Subject"
                name="subject"
                value={contactInfo.subject}
                onChange={onChangeContactInfo}
              />
              <input
                className="bg-white rounded-[5px] border-none shadow-in-boxShadow-golden min-768:col-span-2 col-span-4"
                type="number"
                placeholder="Number"
                name="number"
                value={contactInfo.number}
                onChange={onChangeContactInfo}
              />
              <textarea
                className=" bg-white rounded-[5px] border-none shadow-in-boxShadow-golden col-span-4"
                placeholder="Message"
                name="msg"
                value={contactInfo.msg}
                onChange={onChangeContactInfo}
              />
              {/* enable it */}
              {/* <div className="col-span-3 ">
                <ReCAPTCHA
                  sitekey="6Ldoo_QkAAAAAOXfml0pCLT2zACJZITBLJnv2DFT"
                  onChange={handleRecaptchaResponse}
                />
              </div> */}
              <div className="col-span-3 ">
                <button
                  type="submit"
                  className=" text-black bg-white border-2 border-transparent rounded-full font-medium text-sm px-8 py-2.5 flex items-center hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500 h-[50px] text-center"
                >
                  <RiMessage3Fill />
                  <span className="inline-block ml-4"> Submit now</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
