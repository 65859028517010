import React from "react";
import { RiStarFill } from "react-icons/ri";
// import test_img_1 from "../../assets/images/test_img_1.png";
export default function SingleTestimonial({
  forKey,
  name,
  isVerified,
  title,
  des,
  product_title,
  price = "0",
  product_img,
}) {
  return (
    <div
      key={forKey}
      className="bg-[#F4F1EA] transition-all single-slider-testimonial p-[30px] rounded-[20px]"
    >
      <div className="flex justify-between">
        <div className="customer-name mb-4">
          <strong className="text-innaree-black-color text-[14px] font-bold mr-3">
            {name}
          </strong>
          <div className="badge">
            {isVerified ? (
              <span className="text-[12px]">Verified Buyer</span>
            ) : null}
          </div>
        </div>
        <div className="rating flex items-center mb-4">
          <RiStarFill className="text-in-rating-color-golden" />
          <RiStarFill className="text-in-rating-color-golden" />
          <RiStarFill className="text-in-rating-color-golden" />
          <RiStarFill className="text-in-rating-color-golden" />
          <RiStarFill className="text-in-rating-color-golden" />
        </div>
      </div>
      <h6 className="font-semibold text-[14px] leading-[24px] mb-2">{title}</h6>
      <p className="font-[400] text-[14px] leading-[24px]">
        {des && <span dangerouslySetInnerHTML={{ __html: des.slice(0,140) + '...' }} />}
      </p>

      <div className="flex justify-between items-center">
        <div>
          <h5 className="text-[12px] font-semibold leading-[14px] capitalize">
            {product_title && (
              <span dangerouslySetInnerHTML={{ __html: product_title }} />
            )}
          </h5>
          {/* <h5 className="text-[16px] font-bold leading-[19px] mt-2">£{price}</h5> */}
        </div>
        <img className="w-[70px]" src={product_img} />
      </div>
    </div>
  );
}
