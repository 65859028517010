import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
// import brandImage from "../../assets/images/h-brand.webp";
import h_thc from "../../assets/images/h-thc.webp";
import h_brand_leaf1 from "../../assets/images/h-brand-leaf1.webp";
import h_brand_sec_logo from "../../assets/images/h-brand-sec-logo.webp";
import flower_white from "../../assets/images/slider_4_img_4.png";
import orange from "../../assets/images/slider_2_img_2.png";
import h_brand_halal_rotate_logo from "../../assets/images/h_brand_halal_rotate_logo.webp";

export default function HomeBrand({ brandData }) {
  return (
    <div
      key={"HomeBrand_1"}
      className="home-brand mt-[100px] mb-[100px] px-4 home_brand_section"
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1000"
    >
      {/* positons images */}
      <img src={h_thc} className="h_thc item-bounce" alt="" />
      <img
        src={h_brand_sec_logo}
        className="h_brand_sec_logo 1024:w-[80] 1024:h-[80]"
        alt=""
      />
      <img src={h_brand_leaf1} className="h_brand_leaf1 uporthekenice" alt="" />
      <img
        src={flower_white}
        className="h_brand_thumb hidden min-1024:block"
        alt=""
        data-aos="fade-up-right"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="300"
        data-aos-duration="1000"
      />
      <img
        src={orange}
        className="h_brand_target 1024:hidden"
        alt=""
        data-aos="fade-down-left"
        data-aos-easing="ease-out-cubic"
        data-aos-delay="300"
        data-aos-duration="1000"
      />
      <img
        src={h_brand_halal_rotate_logo}
        className="h_brand_halal_rotate_logo animate-rotate-circle"
        alt=""
      />
      <div className="min-1024:w-[800px] mx-auto ">
        {/* title bar */}
        <Titlebar
          smTitle={brandData.brand_details_slug}
          lgTitle={brandData.brand_details_title}
          ClassForWrapper={
            "home-brand-titlebar min-768:w-[420px] m-auto text-center"
          }
        />

        {/* main image */}
        <img
          src={brandData.brand_details_product_img}
          className="brand-img mt-[60px]"
          alt=""
        />

        <div
          className="text-center mt-[60px]"
          data-aos="fade-right"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="300"
          data-aos-duration="1000"
        >
          <p>
            {brandData.brand_details_about && (
              <span
                dangerouslySetInnerHTML={{
                  __html: brandData.brand_details_about,
                }}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
