import React, { useContext, useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import Innaree from "../../assets/images/helping_logo.png";
// import MenuBg from "../../assets/images/menu-bg.png";
// import MenuImg from "../../assets/images/leftmenu_img.png";
import MenuImg from "../../assets/images/muscle-rub.png";
import { StoreContext } from "../../context/Store";
import { HomepageStoreContext } from "../../context/HomepageStore";
const NavbarLeft = () => {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  // showLeftSidebar, setshowLeftSidebar;
  let { showLeftSidebar, setshowLeftSidebar, headerData } =
    useContext(StoreContext);

  let { footerData, filterdProductType, aboutData } =
    useContext(HomepageStoreContext);

  // distructure data from value
  const { about_us } = aboutData;

  // states
  const [activeMenu, setactiveMenu] = useState("page");

  useEffect(() => {}, []);

  return (
    <div
      key={"NavbarLeft_1"}
      className={`left_sidebar_nav overflow-hidden mobile-nav fixed w-full  top-0  h-screen  z-[999] transition-all duration-1000  ${
        showLeftSidebar ? "left-0" : "left-[-100%]"
      }`}
    >
      {/* overlay */}
      <button
        onClick={() => setshowLeftSidebar(false)}
        className={`overlay  overlay_left_sidebar_nav absolute -z-[1] top-0 bottom-0 w-full bg-[rgba(0,0,0,.4)] backdrop-blur-md transition-all duration-1000 ${
          showLeftSidebar ? "left-0" : "left-[-100%]"
        }`}
      ></button>
      {/* main content */}
      <div
        className={`mobile-nav-wrapper overflow-hidden overflow-y-auto h-screen relative z-10 bg-white w-full px-[15px] min-576:px-[80px] min-768:w-[700px] min-1024:w-[970px] transition-all duration-1000 delay-200 ${
          showLeftSidebar ? "left-0" : "left-[-100%]"
        }`}
      >
        {/* Menu Bottom Right Image */}
        <Link onClick={() => setshowLeftSidebar(false)} to="/">
          <img
            className="hidden min-768:block absolute right-0 bottom-0 max-w-[180px] min-1024:max-w-[380px]"
            src={MenuImg}
            alt="Innaree"
          />
        </Link>

        <div className="shop-menu">
          <Link onClick={() => setshowLeftSidebar(false)} to="/">
            <img
              className="absolute left-[2%] min-576:left-[5%] top-[2%] min-576:top-[5%] max-w-[100px] min-576:max-w-[130px]"
              src={Innaree}
              alt="Innaree"
            />
          </Link>
          <div className="shop-menu-header py-[50px] min-576:py-[70px] flex justify-end">
            <div className="close-nav ">
              <Link to="">
                <AiOutlineClose
                  onClick={() => setshowLeftSidebar(false)}
                  className="h-[40px] min-576:h-[60px] w-[40px] min-576:w-[60px] text-innaree-secondary-color border-2 border-innaree-secondary-color rounded-full p-[8px]"
                />
              </Link>
            </div>
          </div>
          <div className="shop-menu-body">
            <div className="grid grid-cols-2">
              <div className="shop-menu-bar">
                <ul>
                  <li>
                    <Link
                      onClick={() => setactiveMenu("page")}
                      className={`${
                        activeMenu == "page"
                          ? "text-innaree-secondary-color"
                          : "text-[#4B3F72]"
                      } text-[18px] min-576:text-[28px] min-768:text-[48px] font-semibold inline-block mb-[15px]`}
                      to=""
                    >
                      Pages
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setactiveMenu("shop")}
                      className={`${
                        activeMenu == "shop"
                          ? "text-innaree-secondary-color"
                          : "text-[#4B3F72]"
                      } text-[18px] min-576:text-[28px] min-768:text-[48px] font-semibold inline-block mb-[15px]`}
                      to=""
                    >
                      Shop
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setactiveMenu("")}
                      className={`${
                        activeMenu == ""
                          ? "text-innaree-secondary-color"
                          : "text-[#4B3F72]"
                      } text-[18px] min-576:text-[28px] min-768:text-[48px] font-semibold inline-block mb-[15px]`}
                      to=""
                    >
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="shop-menu-deatils">
                {activeMenu == "shop" ? (
                  <div className="shop-megamenu">
                    <h3 className="text-[16px] min-768:text-[32px] text-[#4B3F72] font-bold mb-[15px]">
                      Shop by Product
                    </h3>
                    <ul>
                      {filterdProductType &&
                        filterdProductType.map((item) => (
                          <li>
                            <Link
                              onClick={() => setshowLeftSidebar(false)}
                              className="text-[14px] min-576:text-[16px] text-innaree-black-rgba font-semibold hover:text-innaree-secondary-color  inline-block mb-[15px]"
                              to={`/shop/${item.categorie_slug}`}
                            >
                              {/* Oil Drops */}
                              {item.categorie_name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                ) : activeMenu == "page" ? (
                  <div className="shop-megamenu">
                    <h3 className="text-[16px] min-768:text-[32px] text-[#4B3F72] font-bold mb-[15px]">
                      All Page
                    </h3>
                    <ul>
                      {/* Menu Items */}
                      {[
                        { id: 1, menu_name: "Shop", menu_link: "/shop" },
                        { id: 2, menu_name: "About", menu_link: "/about" },
                        {
                          id: 3,
                          menu_name: "How you're helping",
                          menu_link: "/helping",
                        },
                        {
                          id: 4,
                          menu_name: "FAQ",
                          menu_link: "/faq",
                        },
                        {
                          id: 5,
                          menu_name: "Laboratory Testing",
                          menu_link: "/reporting",
                        },
                        {
                          id: 6,
                          menu_name: "Delivery",
                          menu_link: "/delivery",
                        },
                        {
                          id: 7,
                          menu_name: "Contact",
                          menu_link: "/contact",
                        },
                        {
                          id: 8,
                          menu_name: "Blog",
                          menu_link: "/blog",
                        },
                      ].map((menu_info) => (
                        <li key={`menu_${menu_info.id}`}>
                          <Link
                            onClick={() => setshowLeftSidebar(false)}
                            className="text-[14px] min-576:text-[16px] text-innaree-black-rgba font-semibold hover:text-innaree-secondary-color inline-block mb-[15px]"
                            to={menu_info.menu_link}
                          >
                            {menu_info.menu_name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="shop-megamenu">
                    <h3 className="text-[16px] min-768:text-[32px] text-[#4B3F72] font-bold mb-[15px]">
                      {/* {about_us && about_us.sub_title} */}
                      About us
                    </h3>
                    <p>{headerData && headerData.header_about}</p>
                    <Link
                      onClick={() => setshowLeftSidebar(false)}
                      to={"/about"}
                      className="text-innaree-secondary-color hover:text-innaree-primary-color font-semibold mt-4 inline-block"
                    >
                      Read more
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="shop-menu-footer absolute bottom-[40px]">
            {/* <div className="social flex">
              <a
                className="text-white text-[20px] p-[10px] h-[40px] w-[40px] border rounded-full mr-4 transition-all duration-500 bg-facebook border-none"
                href={footerData.footer_social_link[1].social_link_1}
              >
                <FaFacebookF />
              </a>

              <a
                className="text-white text-[20px] p-[10px] h-[40px] w-[40px] border rounded-full mr-4 transition-all duration-500 bg-youtube border-none"
                href={footerData.footer_social_link[3].social_link_1}
              >
                <FaYoutube />
              </a>
              <a
                className="text-white text-[20px] p-[10px] h-[40px] w-[40px] border rounded-full mr-4 transition-all duration-500 bg-twitter border-none"
                href={footerData.footer_social_link[4].social_link_1}
              >
                <AiOutlineTwitter />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarLeft;
