import React, { useState } from "react";
import { Link } from "react-router-dom";
import SingleBlog from "./singleblog/SingleBlog";
import Moment from "react-moment";
import parse from "html-react-parser";
import { CgArrowRight } from "react-icons/cg";
import { Scrollbars } from 'react-custom-scrollbars';
export default function BlogList({ posts, categories }) {

  const [activeCategoryIndex, setactiveCategoryIndex] = useState(0);
  const [activeCategoryName, setactiveCategoryName] = useState("all");

  return (
    <div className="innaree-container mt-[100px] mb-[100px]">

      <div className="flex flex-wrap justify-center overflow-x-scroll box-border">

        <div className="flex box-border min-w-0">
          {categories && categories.slice()
            .reverse().map((cate, index) => (
              <span
                onClick={() => {
                  setactiveCategoryIndex(index);
                  setactiveCategoryName(
                    cate.categorie_name == "Uncategorized"
                      ? "all"
                      : cate.categorie_name
                  );
                }}
                key={`blog_single_cate_list_${index}`}
                className={`${activeCategoryIndex == index && 'text-innaree-secondary-color'} mx-4 my-4 min-w-[110px] capitalize font-semibold  text-center cursor-pointer`}>{cate.categorie_name == "Uncategorized" ? "Home" : cate.categorie_name}</span>
            ))}
        </div>

      </div>
      <div className="block mt-[50px]">
        {activeCategoryName == "all" ? [
          posts.map((postEachCategory, idx) => (
            <div>
              <h2 className="text-[32px] capitalize font-semibold">  <span
                dangerouslySetInnerHTML={{
                  __html: postEachCategory.categorie_name && postEachCategory.categorie_name,
                }}
              />
              </h2>

              <div className="grid grid-cols-2 gap-4">
                {postEachCategory.posts && postEachCategory.posts.map((item, index) => (

                  <div key={`blog_key_1${item.ID}`} className={`${index == 0 ? 'col-span-2' : 'min-768:col-span-1 col-span-2'} mb-4`}>
                    <div className="shadow-in-boxShadow p-4  transition-all delay-75 group">
                      <div className="object-contain">
                        <img
                          className="w-full group-hover:scale-[.98] transition-all delay-75"
                          src={item.post_thumnail[0] && item.post_thumnail[0]}
                        />
                      </div>
                      <div className="relative col-span-2 mt-4">
                        <div className="inline-flex items-center ">

                          <div className="flex items-center">
                            <span className=" mb-1 text-[16px] mr-4 text-innaree-secondary-rgba capitalize">
                              {item.post_categories && item.post_categories}
                            </span>
                            <p className=" opacity-50 text-xs">
                              <Moment format="YYYY/MM/DD">
                                {item.post_date && item.post_date}
                              </Moment>
                            </p>
                          </div>
                        </div>
                        <Link
                          to={`/blog/${item.slug}`}
                          className={`${index == 0 ? 'min-768:text-[48px] min-768:leading-[60px] text-[24px] leading-[30px]' : 'text-[24px] leading-[30px]'}  rounded-lg block mb-4  capitalize font-bold cursor-pointer hover:text-innaree-primary-color`}
                        >
                          {item.post_title && item.post_title && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.post_title && item.post_title,
                              }}
                            />
                          )}
                        </Link>

                        <p className="mb-4 block">
                          {item.post_excerpt && item.post_excerpt}
                          <Link
                            to={`/blog/${item.slug}`}
                            className="text-[28px] text-innaree-secondary-color hover:text-black cursor-pointer mt-4 block group-hover:translate-x-[7px] transition-all delay-75"
                          >
                            <CgArrowRight />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
            </div>

          ))
        ] : [
          posts.filter((filterPost) => filterPost.categorie_name == activeCategoryName).map((postEachCategory, idx) => (
            <div>
              <h2 className="text-[32px] capitalize font-semibold">  <span
                dangerouslySetInnerHTML={{
                  __html: postEachCategory.categorie_name && postEachCategory.categorie_name,
                }}
              />
              </h2>

              <div className="grid grid-cols-2 gap-4">
                {postEachCategory.posts && postEachCategory.posts.map((item, index) => (

                  <div key={`blog_key_1${item.ID}`} className={`${index == 0 ? 'col-span-2' : 'min-768:col-span-1 col-span-2'} mb-4`}>
                    <div className="shadow-in-boxShadow p-4  transition-all delay-75 group">
                      <div className="object-contain">
                        <img
                          className="w-full group-hover:scale-[.98] transition-all delay-75"
                          src={item.post_thumnail[0] && item.post_thumnail[0]}
                        />
                      </div>
                      <div className="relative col-span-2 mt-4">
                        <div className="inline-flex items-center ">

                          <div className="flex items-center">
                            <span className=" mb-1 text-[16px] mr-4 text-innaree-secondary-rgba capitalize">
                              {item.post_categories && item.post_categories}
                            </span>
                            <p className=" opacity-50 text-xs">
                              <Moment format="YYYY/MM/DD">
                                {item.post_date && item.post_date}
                              </Moment>
                            </p>
                          </div>
                        </div>
                        <Link
                          to={`/blog/${item.slug}`}
                          className={`${index == 0 ? 'min-768:text-[48px] min-768:leading-[60px] text-[24px] leading-[30px]' : 'text-[24px] leading-[30px]'}  rounded-lg block mb-4  capitalize font-bold cursor-pointer hover:text-innaree-primary-color`}
                        >
                          {item.post_title && item.post_title && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.post_title && item.post_title,
                              }}
                            />
                          )}
                        </Link>

                        <p className="mb-4 block">
                          {item.post_excerpt && item.post_excerpt}
                          <Link
                            to={`/blog/${item.slug}`}
                            className="text-[28px] text-innaree-secondary-color hover:text-black cursor-pointer mt-4 block group-hover:translate-x-[7px] transition-all delay-75"
                          >
                            <CgArrowRight />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
            </div>

          ))
        ]}

      </div>
    </div>
  );
}
