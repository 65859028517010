import React from 'react'
import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { StoreContext } from '../context/Store';

export default function CheckoutProtectedRoute() {
    /******************************************
 *
 * GETTING CONTEXT VALUE
 *
 ******************************************/
    let { cartItems } = useContext(StoreContext);
    return (!Array.isArray(cartItems) || cartItems.length > 0 ? <Outlet /> : <Navigate to='/' />)
}
