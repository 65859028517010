import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ButtonEight } from "../buttons/Button";
// import Prodcut10 from "../../assets/images/prodcut10.jpg";
import { AiOutlineRight } from "react-icons/ai";
import { StoreContext } from "../../context/Store";
import toast, { Toaster } from "react-hot-toast";
import { getCookie } from "../../helper/helper";
// keep file size down
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
const InformationFirstStep = ({
  shippingInfo,
  handleOnchange,
  selectCountry,
  selectedCountryName,
}) => {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    checkoutSteps,
    setcheckoutSteps,
    cartItems,
    subtotal,
  } = useContext(StoreContext);

  const handleNextStep = () => {
    if (
      shippingInfo.firstName == "" ||
      shippingInfo.email == "" ||
      shippingInfo.addressOne == ""|| 
      shippingInfo.town == ""|| 
      shippingInfo.post == ""|| 
      selectedCountryName == ""
    ) {
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Please fill up all info!!
            </div>
          </div>
        </div>
      );
    } else {
      setcheckoutSteps(2);
    }
  };

  // const selectCountry = (val) => {
  //   console.log(val);
  // }
  // const handleCountry =(e) => {
  //   console.log(e);
  // }

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          duration: 1000,
        }}
      />
      <div className="checkout-page min-1024:mt-[100px] mb-[100px]">
        <div className="innaree-container">
          <div className="min-1024:grid grid-cols-2 gap-10">
            {/* Leftside */}
            <div className="pt-[30px] min-576:pt-[80px] pb-[50px] bg-white min-576:px-[50px]">
              <Link className="inline-block" to="/">
                <h1 className="text-[48px] text-black font-bold uppercase tracking-[5px]">
                  Innaree
                </h1>
              </Link>

              {/* show guest info */}
              {getCookie() == undefined ? (
                <div className="text-left mb-[30px]">
                  <span className="text-[24px] text-innaree-secondary-color capitalize block">
                    Guest
                  </span>
                  <span className="text-[20px]">Already have an account?</span>
                  <div className="flex  items-center mt-2">
                    <Link
                      to={"/login"}
                      className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500`}
                    >
                      Login
                    </Link>
                    <span className="inline-block mx-5 font-bold">Or</span>
                    <Link
                      to={"/register"}
                      className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500`}
                    >
                      Register
                    </Link>
                  </div>
                </div>
              ) : null}

              {/* show guest info */}
              <div className="checkoutsteps flex items-center">
                <Link
                  className="text-[14px] text-black steps-active font-bold"
                  to=""
                >
                  Information
                </Link>
                <AiOutlineRight className="text-[14px] mx-[5px] inline-block" />
                <Link className="text-[14px] text-black" to="">
                  Shipping
                </Link>
                <AiOutlineRight className="text-[14px] mx-[5px] inline-block" />
                <Link className="text-[14px] text-black" to="">
                  Payment
                </Link>
              </div>

              {/* Contact Info  no need*/}
              {/* <div className="contact-info mt-[30px]">
                <h4 className="text-[24px] font-semibold mb-[10px]">
                  Contact information
                </h4>
                <input
                  className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                  type="email"
                  id=""
                  name="email"
                  onChange={handleOnchange}
                  placeholder="Email"
                />
                <div className="checkbox-wrapper flex items-center">
                  <input
                    className="mr-[10px] text-in-rating-color-golden inline-block focus:ring-0 focus:ring-offset-0"
                    type="checkbox"
                    id="emailme"
                  />
                  <label htmlFor="emailme">
                    Email me with news and offers{" "}
                  </label>
                </div>
              </div> */}

              {/* Shipping address */}
              <div className="shipping-address mt-[30px]">
                <h4 className="text-[24px] font-semibold mb-[20px]">
                  Shipping address
                </h4>
                <div className="min-1250:grid grid-cols-2 gap-4">
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="text"
                    placeholder="Firstname"
                    name="firstName"
                    defaultValue={shippingInfo.firstName}
                    onChange={handleOnchange}
                  />
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="text"
                    placeholder="Surname"
                    name="sureName"
                    defaultValue={shippingInfo.sureName}
                    onChange={handleOnchange}
                  />
                </div>

                <div className="min-1250:grid grid-cols-2 gap-4">
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="email"
                    placeholder="Email"
                    name="email"
                    defaultValue={shippingInfo.email}
                    onChange={handleOnchange}
                  />
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="email"
                    placeholder="Confirm Email"
                    name="confirmEmail"
                    defaultValue={shippingInfo.confirmEmail}
                    onChange={handleOnchange}
                  />
                </div>

                <div className="min-1250:grid grid-cols-2 gap-4">
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="text"
                    placeholder="Telephone"
                    name="telephone"
                    defaultValue={shippingInfo.telephone}
                    onChange={handleOnchange}
                  />
                  {/* <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="text"
                    placeholder="Country"
                    name="country"
                    defaultValue={shippingInfo.country}
                    onChange={handleOnchange}
                  /> */}
                  <CountryDropdown
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    name="country"
                    value={selectedCountryName}
                    // onChange={handleCountry}
                    onChange={selectCountry}
                  />
                </div>

                <div className="min-1250:grid grid-cols-2 gap-4">
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="text"
                    placeholder="Address Line 1"
                    name="addressOne"
                    defaultValue={shippingInfo.addressOne}
                    onChange={handleOnchange}
                  />
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="text"
                    placeholder="Address Line 2"
                    name="addressTwo"
                    defaultValue={shippingInfo.addressTwo}
                    onChange={handleOnchange}
                  />
                </div>

                <div className="min-1250:grid grid-cols-2 gap-4">
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="text"
                    placeholder="Town"
                    name="town"
                    defaultValue={shippingInfo.town}
                    onChange={handleOnchange}
                  />
                  <input
                    className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
                    type="text"
                    placeholder="Post / Area / Zip Code"
                    name="post"
                    defaultValue={shippingInfo.post}
                    onChange={handleOnchange}
                  />
                </div>

                {/* no need */}
                {/* <div className="checkbox-wrapper flex items-center mt-[10px]">
                  <input
                    className="mr-[10px] text-in-rating-color-golden inline-block focus:ring-0 focus:ring-offset-0"
                    type="checkbox"
                    id="savethis"
                  />
                  <label htmlFor="savethis">
                    Save this information for next time
                  </label>
                </div>

                <div className="checkbox-wrapper flex items-center">
                  <input
                    className="mr-[10px] text-in-rating-color-golden inline-block focus:ring-0 focus:ring-offset-0"
                    type="checkbox"
                    id="newsandoffer"
                  />
                  <label htmlFor="newsandoffer">
                    Text me with news and offers
                  </label>
                </div> */}

                {/* Action Buttons */}
                <div className="actions hidden min-1024:flex items-center justify-between mt-[50px]">
                  <Link
                    className="text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500"
                    to="/"
                  >
                    Back
                  </Link>

                  <button
                    onClick={handleNextStep}
                    type="button"
                    className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 `}
                  >
                    Continue to shipping
                  </button>
                </div>
              </div>
            </div>

            {/* rightside */}
            <div className="bg-white min-576:bg-[#FAFAFA] min-576:pt-[80px] pb-[50px] min-576:px-[50px]">
              <div className="added-prodcuts border-b border-innnree-border-darkgray mb-[20px]">
                {/* Single Item */}
                {cartItems &&
                  cartItems.map((product) => (
                    <div
                      key={product.id}
                      className="cart-item mb-[30px] flex items-start min-1024:items-center justify-start mr-[30px]"
                    >
                      <div className="product-img h-[100px] w-[100px] mr-[35px] min-1024:mr-[10px] flex justify-center items-center">
                        <img
                          className="h-[80px]"
                          src={product.product_thumnails}
                          alt="product"
                        />
                      </div>
                      <div className="prodcut-details  min-1024:flex grow flex-wrap items-center justify-between">
                        <div className="prodcut-heading flex flex-col">
                          <div className="prodcut-title text-[16px] font-bold mb-[5px]">
                            {product.name}
                          </div>
                          <div className="prodcut-category mb-[5px] min-1024:mb-0">
                            {product.categories &&
                              product.categories.map((cat) => (
                                <span key={cat.id} className="text-[16px]">
                                  {cat.name}
                                </span>
                              ))}
                          </div>
                        </div>
                        <div className="cart-final w-full"></div>
                        <div className="prodcut-price mr-0 min-1024:mr-[40px] w-full">
                          <span className="text-[16px]">
                            {" "}
                            £{product.price} * {product.qty} = {"  "}
                            {"  "}
                            {"  "} £{product.totalPriceWithQty}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="sub-total border-b border-innnree-border-darkgray mb-[20px]">
                <div className="sub-total flex justify-between">
                  <span>Subtotal</span>
                  <span>£{subtotal}</span>
                </div>
                <div className="sub-total flex justify-between mb-[20px]">
                  <span>Shipping</span>
                  <span>Calculated at next step</span>
                </div>
              </div>
              <div className="total flex justify-between mb-[20px]">
                <span className="text-[16px]">Total</span>
                <span className="text-[14px]">
                  {/* GBP */}
                  <strong className="text-[24px] inline-block ml-[10px]">
                    £{subtotal}
                  </strong>
                </span>
              </div>

              {/* Action Buttons */}
              <div className="actions min-576:flex items-center justify-between mt-[50px] block min-1024:hidden">
                <Link
                  className="text-white font-semibold mt-8 mr-4 uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500"
                  to="/"
                >
                  Back
                </Link>

                <button
                  onClick={handleNextStep}
                  type="button"
                  className={`text-white font-semibold uppercase bg-innaree-secondary-color mt-8 px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 `}
                >
                  Continue to shipping
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationFirstStep;
