import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ButtonEight } from "../buttons/Button";
import CheckoutSteps from "../checkoutsteps/CheckoutSteps";
// import Prodcut10 from "../../assets/images/prodcut10.jpg";
import { SiKlarna } from "react-icons/si";
import { FaDotCircle } from "react-icons/fa";
import { getCookie, toggleClassForAccordion } from "../../helper/helper";
import { AiOutlineRight } from "react-icons/ai";
import { StoreContext } from "../../context/Store";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { base_url, viva_demo_payment_url, viva_live_payment_url } from "../../Base_url";

import toast, { Toaster } from "react-hot-toast";

const PaymentThirdStep = ({
  isVivaDemo,
  payementDetails,
  handlepaymentMethod,
  customer_id,
  finalShippingAmount,
  finalShippingMethod,
  selectedCountryNameForPayment
}) => {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { checkoutSteps, setcheckoutSteps, cartItems, setcartItems, subtotal, dicountAndShippingData,
    setdicountAndShippingData, } =
    useContext(StoreContext);


  // component state
  const [dataLoading, setdataLoading] = useState(false);
  const [couponCode, setcouponCode] = useState('');
  const [couponCodeError, setcouponCodeError] = useState(false);
  const [verifiedouponCode, setVerifiedouponCode] = useState({});//coupon data applied value



  // total with shipping chage
  let subtotalWithShippingCharge = parseFloat(subtotal) + parseFloat(finalShippingAmount);
  subtotalWithShippingCharge = subtotalWithShippingCharge.toFixed(2);


  // console.log(parseFloat(subtotal) + parseInt(finalShippingAmount));
  // console.log(subtotalWithShippingCharge);

  // find discounted amount for coupon code
  const findDiscountedAmount = () => {
    let discountDeductedAmount = 0;

    // check if verified coupon exiest or not
    if (Object.keys(verifiedouponCode).length > 0) {
      // if discount type is percentage
      if (verifiedouponCode[0].discount_type == "percent") {

        let convertedAmontToNumber = parseFloat(verifiedouponCode[0].discount_amount);
        discountDeductedAmount = (subtotalWithShippingCharge / 100) * convertedAmontToNumber;
      } else {
        discountDeductedAmount = parseFloat(verifiedouponCode[0].discount_amount);
      }
    }

    let deducted_value = discountDeductedAmount.toFixed(2);

    return deducted_value;
  }





  // find grand total value 
  const findGrandTotalAmount = () => {
    let grandTotal;
    // check if verified coupon exiest
    if (Object.keys(verifiedouponCode).length > 0) {

      let total = subtotalWithShippingCharge - findDiscountedAmount();
      grandTotal = total.toFixed(2);
    } else {
      grandTotal = subtotalWithShippingCharge;
    }

    // let total_value = total.toFixed(2);
    // return total_value;
    return grandTotal;

  }

  // check coupon code is valid or not
  const checkCouponCodeValid = () => {

    // check from frontend start
    if (dicountAndShippingData.product_coupon) {
      let couponExiest = dicountAndShippingData.product_coupon.filter(x => x.coupon_code == couponCode);
      if (couponExiest.length > 0) {

        setVerifiedouponCode(couponExiest);
        setcouponCodeError(false);
        // console.log('code ache ', couponExiest);
      } else {

        setVerifiedouponCode({})
        setcouponCodeError(true);
        // console.log('code nai ', couponExiest);
      }
    }
    // check from frontend end
  }

  // payments data and credentials
  let listProduct = [];
  cartItems.map((item) => {
    listProduct.push({
      product_id: item.id,
      quantity: item.qty,
    });
  });

  // const ds_amount = findDiscountedAmount() && findDiscountedAmount();

  const formDataForViva = {
    customer_id: customer_id,
    payment_method: "Viva Payment",
    payment_method_title: "Payment",
    shipping_method: finalShippingMethod,
    shipping_charge: finalShippingAmount,
    discount_amount: findDiscountedAmount(),
    grandTotal: findGrandTotalAmount(),
    coupon_code: couponCode,
    set_paid: true,
    billing: {
      first_name: payementDetails.firstName,
      last_name: payementDetails.sureName,
      address_1: payementDetails.addressOne,
      address_2: payementDetails.addressTwo,
      city: payementDetails.town,
      state: payementDetails.town,
      postcode: payementDetails.post,
      country: selectedCountryNameForPayment,
      email: payementDetails.email,
      phone: payementDetails.telephone,
    },
    shipping: {
      first_name: payementDetails.firstName,
      last_name: payementDetails.sureName,
      address_1: payementDetails.addressOne,
      address_2: payementDetails.addressTwo,
      city: payementDetails.town,
      state: payementDetails.town,
      postcode: payementDetails.post,
      country: selectedCountryNameForPayment,
    },
    line_items: listProduct,
    shipping_lines: [
      {
        method_id: "flat_rate",
        method_title: "Flat Rate",
        total: findGrandTotalAmount(),
      },
    ],
  };
  // handle viva waller
  const handleVivaWallet = () => {
    // console.log('form data', formDataForViva);
    setdataLoading(true);
    axios
      .post(
        `${base_url}/wp-json/softtechit/v1/innaree/vivapaymentfinal/`,
        formDataForViva
      )
      .then((res) => {
        if (res.data.orderCode) {
          window.location = `${isVivaDemo == "1" ? viva_demo_payment_url : viva_live_payment_url}/web/checkout?ref=${res.data.orderCode}`;
        } else {
          setdataLoading(false);
          toast.custom(
            <div>
              <div
                id="toast-danger"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Error icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  Something went wrong!!
                </div>
              </div>
            </div>
          );
        }
      })
      .catch((err) => {
        setdataLoading(false);
        toast.custom(
          <div>
            <div
              id="toast-danger"
              className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
              role="alert"
            >
              <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                <svg
                  aria-hidden="true"
                  className="w-3 h-3 rounded-[50%]"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Error icon</span>
              </div>
              <div className="px-4 text-sm font-normal">
                Something went wrong!!
              </div>
            </div>
          </div>
        );
      });
  };


  return (
    <div>
      {/* show while order is processing */}
      {dataLoading && (
        <div
          id="loading-screen"
          className="w-full h-full fixed block top-0 left-0 bg-white opacity-[.5] z-50"
        >
          <span className="text-green-500  opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
            {/* <i className="fas fa-circle-notch fa-spin fa-5x"></i> */}
            <svg
              aria-hidden="true"
              className=" rotate-center-fullpage inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </span>
        </div>
      )}
      {/* toast init */}
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 1000,
        }}
      />
      <div className="innaree-container">
        <div className="min-1024:grid grid-cols-2 gap-10">
          {/* Leftside */}
          <div className="pt-[30px] min-576:pt-[80px] pb-[50px] bg-white min-576:px-[50px]">
            <Link className="inline-block" to="/">
              <h1 className="text-[48px] text-black font-bold uppercase tracking-[5px]">
                Innaree
              </h1>
            </Link>
            {/* checkout step */}
            <div className="checkoutsteps flex items-center">
              <Link className="text-[14px] text-black " to="">
                Information
              </Link>
              <AiOutlineRight className="text-[14px] mx-[5px] inline-block" />
              <Link className="text-[14px] text-black" to="">
                Shipping
              </Link>
              <AiOutlineRight className="text-[14px] mx-[5px] inline-block" />
              <Link
                className="text-[14px] text-black steps-active font-bold"
                to=""
              >
                Payment
              </Link>
            </div>

            {/* Contact Info */}
            <div className="contact-info mt-[30px] rounded p-[12px] border border-innaree-border-darkgray">
              <div className="contact-wrapper grid grid-cols-12 items-start border-b border-innaree-border-darkgray pb-[10px]">
                <div className="320:col-span-9 col-span-10 items-center">
                  <div className="grid grid-cols-12 gap-4">
                    <span className="text-[#737373] text-[16px] block min-576:inline-block 320:col-span-12 col-span-3">
                      Contact
                    </span>
                    <span className="text-[14px] 320:col-span-12 col-span-9">
                      {payementDetails.email && payementDetails.email}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => setcheckoutSteps(2)}
                  className="text-[16px] text-innaree-secondary-color 320:col-span-3 col-span-2"
                >
                  Change
                </button>
              </div>
              <div className="ship-info grid grid-cols-12 items-start border-b border-innaree-border-darkgray mt-[10px] pb-[10px]">
                <div className="320:col-span-9 col-span-10">
                  <div className="grid grid-cols-12 gap-4">
                    <span className="text-[#737373] text-[16px] block min-576:inline-block 320:col-span-12 col-span-3">
                      Ship to
                    </span>
                    <span className="text-[14px] 320:col-span-12 col-span-9">
                      {payementDetails.addressOne && payementDetails.addressOne}
                      {payementDetails.addressTwo && payementDetails.addressTwo}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => setcheckoutSteps(2)}
                  className="text-[16px] text-innaree-secondary-color 320:col-span-3 col-span-2"
                >
                  Change
                </button>
              </div>
              <div className="ship-info grid grid-cols-12 items-start border-b border-innaree-border-darkgray mt-[10px] pb-[10px]">
                <div className="320:col-span-9 col-span-10">
                  <div className="grid grid-cols-12 gap-4 items-center">
                    <span className="text-[#737373] text-[16px] block min-576:inline-block 320:col-span-12 col-span-3">
                      Shipping Method
                    </span>
                    <span className="text-[14px] 320:col-span-12 col-span-9">
                      {finalShippingMethod}{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="ship-info grid grid-cols-12 items-start pt-[10px]">
                <div className="320:col-span-9 col-span-10">
                  <div className="grid grid-cols-12 gap-4 items-center">
                    <span className="text-[#737373] text-[16px] block min-576:inline-block 320:col-span-12 col-span-3">
                      Shipping Amount
                    </span>
                    <span className="text-[14px] 320:col-span-12 col-span-9">
                      £{finalShippingAmount}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="actions hidden min-1024:flex items-center justify-between mt-[50px]">
              <button
                onClick={() => setcheckoutSteps(2)}
                type="button"
                className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500mb-[20px] min-576:mb-0 block 320:w-full`}
              >
                Back
              </button>

              <button
                onClick={handleVivaWallet}
                type="button"
                className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500mb-[20px] min-576:mb-0 block 320:w-full`}
              >
                pay now
              </button>
            </div>
          </div>

          {/* rightside */}
          <div className="bg-white min-576:bg-[#FAFAFA] min-576:pt-[80px] pb-[50px] min-576:px-[50px]">
            <div className="added-prodcuts border-b border-innnree-border-darkgray mb-[20px]">
              {/* Single Item */}
              {cartItems &&
                cartItems.map((product) => (
                  <div
                    key={product.id}
                    className="cart-item mb-[30px] flex items-start min-1024:items-center justify-start mr-[30px]"
                  >
                    <div className="product-img h-[100px] w-[100px] mr-[35px] min-1024:mr-[10px] flex justify-center items-center">
                      <img
                        className="h-[80px]"
                        src={product.product_thumnails}
                        alt="product"
                      />
                    </div>
                    <div className="prodcut-details  min-1024:flex grow flex-wrap items-center justify-between">
                      <div className="prodcut-heading flex flex-col">
                        <div className="prodcut-title text-[16px] font-bold mb-[5px]">
                          {product.name}
                        </div>
                        <div className="prodcut-category mb-[5px] min-1024:mb-0">
                          {product.categories &&
                            product.categories.map((cat) => (
                              <span key={cat.id} className="text-[16px]">
                                {cat.name}
                              </span>
                            ))}
                        </div>
                      </div>
                      <div className="cart-final w-full"></div>
                      <div className="prodcut-price mr-0 min-1024:mr-[40px] w-full">
                        <span className="text-[16px]">
                          {" "}
                          £{product.price} * {product.qty} = {"  "}
                          {"  "}
                          {"  "} £{product.totalPriceWithQty}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {dicountAndShippingData.product_coupon.length > 0 ? (
              <div className="discount-code border-b border-innnree-border-darkgray mb-[20px]">
                {Object.keys(verifiedouponCode).length === 0 && (
                  <div className="min-576:grid grid-cols-12 gap-2 min-1250:gap-4 items-center mb-[20px]">
                    <input
                      onChange={(e) => setcouponCode(e.target.value)}
                      className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden focus:ring-in-rating-color-golden col-span-8 mb-[20px] min-576:mb-0"
                      type="text"
                      id=""
                      placeholder="Gift card or discount code"
                    />

                    <button
                      onClick={checkCouponCodeValid}
                      type="button"
                      className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 col-span-4 py-[10px] 320:w-full`}
                    >
                      Apply
                    </button>
                  </div>
                )}
                {/* validation */}
                <span
                  className={`${
                    couponCodeError ? "block" : "hidden"
                  } text-red-500 mb-4`}
                >
                  Sorry, this coupon code is no longer valid or has expired
                </span>
                <span
                  className={`${
                    Object.keys(verifiedouponCode).length > 0
                      ? "block"
                      : "hidden"
                  } text-innaree-secondary-color  mb-4`}
                >
                  Coupon code applied successfully
                </span>
              </div>
            ) : null}
            <div className="sub-total border-b border-innnree-border-darkgray mb-[20px]">
              <div className="sub-total flex justify-between">
                <span>Subtotal</span>
                <span>£{subtotal}</span>
              </div>
              <div className="sub-total flex justify-between mb-[20px]">
                <span>Shipping</span>
                <span>£{finalShippingAmount}</span>
              </div>
            </div>

            {/* if coupon is exiest  else show total amount*/}
            <div className="total flex justify-between mb-[20px]">
              <span className="text-[16px]">Total </span>
              <span className="text-[14px]">
                {/* make it to fixed amount */}£{subtotalWithShippingCharge}
              </span>
            </div>

            {/* it will be deducted */}
            {Object.keys(verifiedouponCode).length > 0 && (
              <div className="sub-total flex justify-between mb-[20px]">
                <span>
                  Discounted amount{" "}
                  {verifiedouponCode[0].discount_type == "percent" &&
                    `(${verifiedouponCode[0].discount_amount}%)`}
                </span>
                <span>£{findDiscountedAmount()}</span>
              </div>
            )}

            <div className="total flex justify-between mb-[20px]">
              <span className="text-[16px]">Grand Total</span>
              <span className="text-[14px]">
                <strong className="text-[24px] inline-block ml-[10px]">
                  {/* make it to fixed amount */}£{findGrandTotalAmount()}
                </strong>
              </span>
            </div>

            {/* mobile device */}
            {/* Action Buttons */}
            <div className="actions min-576:flex items-center justify-between mt-[50px] block min-1024:hidden">
              <button
                onClick={() => setcheckoutSteps(2)}
                type="button"
                className={`text-white font-semibold uppercase mb-4 bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500mb-[20px] min-576:mb-0 block 320:w-full`}
              >
                Back
              </button>
              {/* {payementDetails.paymentMethod == "COD" ? (
                <button
                  onClick={handlePayment}
                  type="button"
                  className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500mb-[20px] min-576:mb-0 block 320:w-full`}
                >
                  COD
                </button>
              ) : (
                <button
                  onClick={handleVivaWallet}
                  type="button"
                  className={`font-semibold text-white uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500mb-[20px] min-576:mb-0 block 320:w-full`}
                >
                  Viva wallet
                </button>
              )} */}
              <button
                onClick={handleVivaWallet}
                type="button"
                className={`font-semibold text-white uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500mb-[20px] min-576:mb-0 block 320:w-full`}
              >
                pay now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentThirdStep;
