import React, { useContext, useState } from "react";
import FooterShape1 from "../../assets/images/footer-shape-1.webp";
import PaymentsGateway from "../../assets/images/payments-gateway.png";
import GoldenLogo from "../../assets/images/Innaree-golden-logo.png";
import FooterBg from "../../assets/images/footer-bg.png";
import { HiArrowNarrowRight, HiShoppingBag } from "react-icons/hi";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { ButtonFour } from "../buttons/Button";
import "./Footer.scss";
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { HomepageStoreContext } from "../../context/HomepageStore";
import { base_url } from "../../Base_url";
import axios from "axios";
import Preloader from "../loading/Preloader";

export default function Footer() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { footerData, setfooterData, footerLoading, setfooterLoading } =
    useContext(HomepageStoreContext);

  // console.log('social icons', footerData.footer_social_link);

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);

  // email validation
  const validateEmail = (email) => {
    const regEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regEx.test(email);
  };

  // handle email change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };
  // https://innareewp.testapplication.xyz/wp-json/softtechit/v1/innaree/newsletters/

  const hanndleNewslettersubmit = (e) => {
    setLoading(true);
    axios
      .post(`${base_url}/wp-json/softtechit/v1/innaree/newsletters/`, {
        email: email,
      })
      .then((res) => {
        // console.log(res)
        setLoading(false);
        setEmail("");
        toast.custom(
          <div>
            <div
              id="toast-success"
              className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
              role="alert"
            >
              <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
                <svg
                  aria-hidden="true"
                  className="w-3 h-3 rounded-[50%]"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Check icon</span>
              </div>
              <div className="px-4 text-sm font-normal">
                Successfully subscribed! Thank you.
              </div>
            </div>
          </div>
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setEmail("");
        toast.custom(
          <div>
            <div
              id="toast-danger"
              className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
              role="alert"
            >
              <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                <svg
                  aria-hidden="true"
                  className="w-3 h-3 rounded-[50%]"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Error icon</span>
              </div>
              <div className="px-4 text-sm font-normal">
                Subscription Error: Please Try Again Later
              </div>
            </div>
          </div>
        );
      });
  };

  // console.log('check footer menu', footerData.footer_social_link[0].social_link_1);

  return (
    <div
      key={"Footer_1"}
      className="footer-area h-auto min-1250:h-[703px] w-full relative py-[50px] bg-black min-1024:bg-transparent bg-img-none-1024 bg-no-repeat bg-bottom"
      style={{
        backgroundImage: `url(${
          footerData.footer_bg &&
          footerData.footer_bg["url"] &&
          footerData.footer_bg["url"]
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      {loading && (
        <div
          id="loading-screen"
          className="w-full h-full fixed block top-0 left-0 bg-white opacity-[.5] z-50"
        >
          <span className="text-green-500  opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
            {/* <i className="fas fa-circle-notch fa-spin fa-5x"></i> */}
            <svg
              aria-hidden="true"
              className=" rotate-center-fullpage inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </span>
        </div>
      )}

      <div className="innaree-container">
        {/* footer-shapes */}
        <img
          className="footer-shape1 absolute top-[38%] min-576:top-[16%] right-[0] min-1024:right-[33%] z-10"
          src={FooterShape1}
          alt="footer-shape"
        />
        <img
          className="payments-gateway bg-white min-1250:bg-transparent rounded absolute min-1024:right-[63%] min-1250:right-[4%] min-1440:right-[8%] bottom-[2%] min-1024:bottom-[1%] 320:max-w-[180px] min-1250:max-w-[250px] min-1440:w-auto "
          src={
            footerData.footer_payment_gateway_img &&
            footerData.footer_payment_gateway_img
          }
          alt="payments-gateway"
        />

        {/* scroll to top */}
        <ScrollToTop></ScrollToTop>

        {/* footer-top */}
        <div className="footer-top grid min-576:grild-cols-1 min-1024:grid-cols-4 gap-4 mb-[10px] z-20 relative">
          <div className="ft-left pl-0 min-1024:pl-[50px] min-1440:pl-0">
            <a href="/" className="inline-block">
              <img
                className="footer-logo"
                src={footerData.footer_logo && footerData.footer_logo}
                alt="Innaree"
              />
            </a>
          </div>
          <div className="ft-middle min-1024:col-span-2 min-576:text-start min-1024:text-center">
            <p className="text-[20px] text-innaree-secondary-color font-red-hat leading-none">
              {footerData.footer_site_slug && footerData.footer_site_slug}
            </p>
            <h3 className="text-white text-[32px] font-bold min-1024:px-[100px] mt-4">
              {/* The World’s first 0.0% THC <br /> Halal CBD brand */}
              {footerData.footer_site_title && footerData.footer_site_title}
            </h3>
          </div>
          <div className="ft-right">
            <Link
              className={`max-w-[220px] mb-[33px] min-1024:mb-[100px] text-white bg-innaree-secondary-color border-2 border-transparent rounded-full font-medium text-sm px-8 py-2.5 flex items-center justify-center  hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500`}
              to="/shop"
            >
              <HiShoppingBag className="mr-2 text-xl" /> Shop Now
            </Link>
          </div>
        </div>

        {/* footer-bottom */}
        <div className="footer-bottom grid min-576:grild-cols-1 min-1024:grid-cols-4 gap-4  z-20 relative">
          <div className="fb-left min-1024:col-span-3 pl-0 min-1024:pl-[50px] min-1440:pl-0">
            {/* newsletter */}
            <div className="newsletter mb-[30px]">
              <p className="text-innaree-secondary-color mb-[15px]">
                Newsletter
              </p>
              {/* <h4 className="text-white text-[24px] min-576:w-[400px] min-1024:w-[auto] font-red-hat font-bold capitalize mb-[25px]">
                Subscribe to our newsletter to <br /> stay in touch with our
                latest
              </h4> */}
              <h4 className="text-white text-[24px] min-576:w-[400px] min-1024:w-[auto] font-red-hat font-bold capitalize mb-[25px]">
                Want us to email you <br/> occasionally with Innaree news?
              </h4>
              <form
                className="relative w-[250px]"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (email.length > 0 && isEmailValid) {
                    hanndleNewslettersubmit();
                  } else {
                    return null;
                  }
                }}
              >
                <input
                  className={`text-white px-0 py-1 border-b-2 border-t-0 border-l-0 border-r-0 ${
                    isEmailValid
                      ? "border-innaree-secondary-color focus:border-innaree-secondary-color"
                      : "border-red-500 focus:border-red-500"
                  }
                      focus:ring-0 focus:ring-offset-0 bg-transparent w-full `}
                  type="email"
                  placeholder="email address"
                  onChange={handleEmailChange}
                />

                <button type="submit">
                  <i
                    className={`${
                      isEmailValid
                        ? "text-innaree-secondary-color"
                        : "text-red-500"
                    } text-2xl absolute top-[6px] right-0 h-6 w-6`}
                  >
                    <HiArrowNarrowRight />
                  </i>
                </button>
                {!isEmailValid && (
                  <span className="text-[14px] text-red-500">
                    Please enter a valid email address
                  </span>
                )}
              </form>
            </div>

            {/* social-icons */}
            {/* {footerData.footer_social_link && (
              <div className="social flex mb-[46px]">
                <a
                  className="text-white text-[20px] p-[10px] h-[40px] w-[40px] border rounded-full mr-4 transition-all duration-500 hover:bg-facebook hover:border-facebook"
                  href={
                    footerData.footer_social_link[1].social_link_1 &&
                    footerData.footer_social_link[1].social_link_1
                  }
                >
                  <FaFacebookF />
                </a>
                <a
                  className="text-white text-[20px] p-[10px] h-[40px] w-[40px] border rounded-full mr-4 transition-all duration-500 hover:bg-tiktok hover:border-tiktok"
                  href={
                    footerData.footer_social_link[2].social_link_1 &&
                    footerData.footer_social_link[2].social_link_1
                  }
                >
                  <FaTiktok />
                </a>
                <a
                  className="text-white text-[20px] p-[10px] h-[40px] w-[40px] border rounded-full mr-4 transition-all duration-500 hover:bg-youtube hover:border-youtube"
                  href={
                    footerData.footer_social_link[3].social_link_1 &&
                    footerData.footer_social_link[3].social_link_1
                  }
                >
                  <FaYoutube />
                </a>
                <a
                  className="text-white text-[20px] p-[10px] h-[40px] w-[40px] border rounded-full mr-4 transition-all duration-500 hover:bg-twitter hover:border-twitter"
                  href={
                    footerData.footer_social_link[4].social_link_1 &&
                    footerData.footer_social_link[4].social_link_1
                  }
                >
                  <AiOutlineTwitter />
                </a>
                <a
                  className="text-white text-[20px] p-[10px] h-[40px] w-[40px] border rounded-full mr-4 transition-all duration-500 hover:bg-instagram hover:border-instagram"
                  href={
                    footerData.footer_social_link[0].social_link_1 &
                    footerData.footer_social_link[0].social_link_1
                  }
                >
                  <AiOutlineInstagram />
                </a>
              </div>
            )} */}
            {footerData.footer_social_link && (
              <div className="social flex mb-[46px] flex-wrap">
                {footerData.footer_social_link &&
                  footerData.footer_social_link.map((fl, index) => (
                    <>
                      <a
                        className="block border duration-500 h-[40px] leading-10 mr-4 rounded-full text-[20px] text-center text-white hover:text-black hover:bg-white transition-all w-[40px]"
                        href={fl.social_link_1}
                      >
                        <i className={fl.social_icon_1}></i>
                      </a>
                    </>
                  ))}
              </div>
            )}

            {/* footer-menu1 */}
            <div className="footer-menu1 max-w-[156px]">
              <Link
                className="text-white block min-1024:inline-block  mr-4 mb-4"
                to="/privacy"
              >
                Privacy Policy
              </Link>
              <Link
                className="text-white block min-1024:inline-block mr-4 mb-4"
                to="/cookie"
              >
                Cookie Policy
              </Link>
              <Link
                className="text-white block min-1024:inline-block mr-4 mb-4"
                to="/return"
              >
                Return
              </Link>
            </div>

            {/* credit */}
            <div className="my-5">
              <span className="text-white text-[14px]">
                WEBSITE BY -{" "}
                <a
                  target="_blank"
                  className="text-innaree-secondary-color"
                  href="https://www.mydigitalmarketer.co.uk/"
                >
                  MY DIGITAL MARKETER
                </a>
              </span>
            </div>
          </div>
          <div className="fb-right mb-[20px] min-1024:mb-0">
            {/* footer-menu2 */}
            <div className="footer-menu2 max-w-[156px]">
              <Link className="text-white mr-4 block mb-[20px]" to="/about">
                About
              </Link>
              <Link className="text-white mr-4 block mb-[20px]" to="/helping">
                How You’re Helping
              </Link>
              <Link className="text-white mr-4 block mb-[20px]" to="/delivery">
                Delivery
              </Link>
              <Link className="text-white mr-4 block mb-[20px]" to="/contact">
                Contact
              </Link>
              <Link className="text-white mr-4 block mb-[20px]" to="/faq">
                FAQ
              </Link>
              <Link className="text-white mr-4 block mb-[20px]" to="/reporting">
                Laboratory Testing
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
