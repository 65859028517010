import React from "react";
// import Product5 from "../../assets/images/product5.webp";
// import Product6 from "../../assets/images/product6.webp";
// import Product7 from "../../assets/images/product7.webp";
import { Accordion } from "../accordion/Accordion";

const FaqAccordion = ({ aboutFaq, cbd_usage, medicalFaq }) => {
  return (
    <div className="faq-accordion mt-[100px]">
      <div className="innaree-container">
        {/* About CBD */}
        {aboutFaq && (
          <div className="min-768:grid min-768:grid-cols-2 min-768:gap-4">
            <div className="acc-left">
              <h3
                className="text-innaree-black-color text-[32px] font-bold mb-[45px]"
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              >
                About CBD
              </h3>
              <img
                className="max-h-[400px]"
                src={aboutFaq.faq_product_img.url}
                alt="product"
                data-aos="zoom-in-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              />
            </div>
            <div className="acc-right">
              {/* FAQ Accordions */}
              {aboutFaq.about_faq_accordion.map((about_faq, index) => (
                <div key={`faq_abt_acc_${index}`}>
                  <Accordion
                    accordionTitle={about_faq.faq_title}
                    accordionContent={about_faq.faq_text}
                    accordionId={`accordionId_${index}`}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {/* CBD Usage and Dosage */}
        {cbd_usage && (
          <div className="min-768:grid min-768:grid-cols-2 min-768:gap-4 mt-[100px]">
            <div className="acc-left">
              <h3
                className="text-innaree-black-color text-[32px] font-bold mb-[45px]"
                data-aos="fade-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              >
                CBD Usage and Dosage
              </h3>
              <img
                className="max-h-[400px]"
                src={cbd_usage.faq_product_img.url}
                alt="product"
                data-aos="zoom-in-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              />
            </div>
            <div className="acc-right">
              {/* FAQ Accordions */}
              {cbd_usage.ud_faq_accordion.map((cbd_faq, index) => (
                <div key={`faq_abt_cbd_usage_acc_${index}`}>
                  <Accordion
                    accordionTitle={cbd_faq.faq_title}
                    accordionContent={cbd_faq.faq_text}
                    accordionId={`accordionId_usage_${index}`}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Medical & Health */}
        {medicalFaq && (
          <div className="min-768:grid min-768:grid-cols-2 min-768:gap-4 mt-[100px]">
            <div className="acc-left">
              <h3 className="text-innaree-black-color text-[32px] font-bold mb-[45px]">
                Medical & Health
              </h3>
              <img
                className="max-h-[400px]"
                src={medicalFaq.faq_product_img.url}
                alt="product"
                data-aos="zoom-in-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              />
            </div>
            <div className="acc-right">
              {/* FAQ Accordions */}
              {medicalFaq.mh_faq_accordion.map((medt_faq, index) => (
                <div key={`faq_cbd_medi_acc_${index}`}>
                  <Accordion
                    accordionTitle={medt_faq.faq_title}
                    accordionContent={medt_faq.faq_text}
                    accordionId={`accordionId_medi_${index}`}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqAccordion;
