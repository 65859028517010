import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import HeroSection from "../components/homepage/HeroSection";
import Header from "../components/layout/Header";
import NavbarRight from "../components/navbar/NavbarRight";
import NavbarLeft from "../components/navbar/NavbarLeft";
import HomeBrand from "../components/homepage/HomeBrand";
import HomeBrandLogo from "../components/homepage/HomeBrandLogo";
import MostPopularProduct from "../components/homepage/MostPopularProduct";
import Footer from "../components/layout/Footer";
import GoogleCard from "../components/homepage/GoogleCard";
import LabReportSection from "../components/homepage/LabReportSection";
import ProductTypes from "../components/homepage/ProductTypes";
import Brandpartner from "../components/homepage/Brandpartner";
import "../components/homepage/homepage.scss";
import "../components/homepage/homepage.scss";
import Searchbar from "../components/searchbar/Searchbar";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Cart from "../components/cartpage/Cart";
import toast, { Toaster } from "react-hot-toast";
import { HomepageStoreContext } from "../context/HomepageStore";
import Preloader from "../components/loading/Preloader";
import HeroSection3 from "../components/homepage/HeroSection3";
import BlogBreadcrumb from "../components/blog/BlogBreadcrumb";
import { Link } from "react-router-dom";
import BlogList from "../components/blog/BlogList";
import { base_url } from "../Base_url";
import axios from "axios";
import { useQuery } from "react-query";
// import "./css/style-rtl.css";
gsap.registerPlugin(ScrollTrigger);

// add to cart notification
const notify_add_to_cart = () => toast("Product already added!!.");

export default function Blog() {
    /******************************************
     *
     * GETTING CONTEXT VALUE
     *
     ******************************************/
    let { homeIsLoading, homeData, setHomeData } =
        useContext(HomepageStoreContext);

    const { home_gallery_data, business_partners_data } = homeData;


    /******************************************
    *
    * components functions and state
    *
    ******************************************/
    const [blogData, setblogData] = useState([]);
    const [blogCategory, setblogCategory] = useState([]);
    const [loading, setLoading] = useState(false);
    // get all about page data
    const { isLoading: blogIsLoading, error: blogError, data: queryblogData } = useQuery(
        'blogpage-query-key',
        async () => {
            const response = await axios.get(`${base_url}/wp-json/softtechit/v1/innaree/filterpostbycategories/`);
            return response.data;
        }
    );

    // get single product data from api
    function fetchCategory() {
        setLoading(true);
        axios
            .get(`${base_url}/wp-json/softtechit/v1/innaree/postcategories/`)
            .then((res) => {
                setblogCategory(res.data);
                //  console.log('check shop page data',res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }


    useEffect(() => {
        if (queryblogData) {
            setblogData(queryblogData);
        }

        fetchCategory();
    }, [queryblogData]);

    return (
        <>
            <Helmet>
                <title>Innaree - blog</title>
            </Helmet>

            {blogIsLoading
                ? [<Preloader />]
                : [
                    <div className="overflow-hidden" key={"home_wrap"}>
                        <Header layout={'sm'} />
                        <Searchbar />
                        <NavbarLeft />
                        <Cart />
                        {/* <BlogBreadcrumb /> */}
                        {blogData &&
                            <BlogList categories={blogCategory && blogCategory} posts={blogData} />
                        }
                        <div className="mb-[100px]">
                            <HomeBrandLogo />
                        </div>
                        <Footer />
                    </div>,
                ]}
        </>
    );
}
