import React from "react";
import { toggleClassForAccordion } from "../../helper/helper";

export function BenefitAccordion({
  accordionTitle = "",
  accordionContent = "",
  classForContent = "text-black",
  classForTitle = "text-black",
  accordionId = "",
  showImg = false,
  imgProp = "",
}) {
  return (
    <div id={accordionId} className="accordionWarapper">
      <h2 onClick={() => toggleClassForAccordion(accordionId)}>
        <button
          type="button"
          className={`flex items-center justify-between w-full py-5 font-bold text-left border-b border-border-color-golden ${classForTitle}`}
          data-accordion-target="#accordion-flush-body-1"
          aria-expanded="true"
          aria-controls="accordion-flush-body-1"
        >
          <div className="flex items-center">
            {showImg && (
              <div className="w-[40px] h-[40px] rounded-[50%] mr-4">
                <img className="w-full" src={imgProp && imgProp} alt="" />
              </div>
            )}
            <span className={`${classForTitle}`}>
              {accordionTitle && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: accordionTitle,
                  }}
                />
              )}
            </span>
          </div>
          <svg
            data-accordion-icon
            className="w-6 h-6 shrink-0"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
            ></path>
          </svg>
        </button>
      </h2>
      <div className="accordionContent">
        <div className="py-5 font-light border-b border-border-color-golden">
          <p className={`mb-2 text-[14px] font-[400] ${classForContent}`}>
            {accordionContent && (
              <span
                dangerouslySetInnerHTML={{
                  __html: accordionContent,
                }}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
