import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { StoreContext } from "../../../context/Store";
export default function Edit({
  oldData,
  handleOnChangeUpdateInfo,
  submitProfileInfo,
}) {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { setprofileSteps } = useContext(StoreContext);
  return (
    <div className="edit-info py-[80px] px-[20px]">
      <div className="innaree-container">
        <button
          type="button"
          onClick={() => setprofileSteps(2)}
          className="flex items-center text-[16px] mb-[20px]"
        >
          <FaChevronLeft className="text-[16px] inline-block mr-[10px]" /> Back
        </button>
        <h4 className="text-[24px] font-semibold mb-[20px]">
          Edit information
        </h4>

        <form onSubmit={submitProfileInfo}>
          <h4 className="underline capitalize font-semibold">user Info: </h4>
          <div className="min-1250:grid grid-cols-2 gap-4">
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="User firstname"
              name="first_name"
              defaultValue={oldData.first_name}
              onChange={handleOnChangeUpdateInfo}
            />
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="User lastname"
              name="last_name"
              defaultValue={oldData.last_name}
              onChange={handleOnChangeUpdateInfo}
            />
          </div>

          <div className="min-1250:grid grid-cols-2 gap-4">
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="User email"
              name="user_email"
              defaultValue={oldData.user_email}
              onChange={handleOnChangeUpdateInfo}
            />
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="User password"
              name="user_pass"
              defaultValue={""}
              onChange={handleOnChangeUpdateInfo}
            />
          </div>

          <h4 className="underline font-semibold capitalize">Billing Info</h4>

          <div className="min-1250:grid grid-cols-2 gap-4">
            {/* <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="city"
              name="city"
              defaultValue={oldData.city}
              onChange={handleOnChangeUpdateInfo}
            /> */}
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing first name"
              name="billing_first_name"
              defaultValue={oldData.billing_first_name}
              onChange={handleOnChangeUpdateInfo}
            />
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing last name"
              name="billing_last_name"
              defaultValue={oldData.billing_last_name}
              onChange={handleOnChangeUpdateInfo}
            />
          </div>

          <div className="min-1250:grid grid-cols-2 gap-4">
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing email"
              name="billing_email"
              defaultValue={oldData.billing_email}
              onChange={handleOnChangeUpdateInfo}
            />
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing city"
              name="billing_city"
              defaultValue={oldData.billing_city}
              onChange={handleOnChangeUpdateInfo}
            />
          </div>

          <div className="min-1250:grid grid-cols-2 gap-4">
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing phone"
              name="billing_phone"
              defaultValue={oldData.billing_phone}
              onChange={handleOnChangeUpdateInfo}
            />
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing address 1"
              name="billing_address_1"
              defaultValue={oldData.billing_address_1}
              onChange={handleOnChangeUpdateInfo}
            />
          </div>

          <div className="min-1250:grid grid-cols-2 gap-4">
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing address 2"
              name="billing_address_2"
              defaultValue={oldData.billing_address_2}
              onChange={handleOnChangeUpdateInfo}
            />
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing postcode"
              name="billing_postcode"
              defaultValue={oldData.billing_postcode}
              onChange={handleOnChangeUpdateInfo}
            />
          </div>

          <div className="min-1250:grid grid-cols-2 gap-4">
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing state"
              name="billing_state"
              defaultValue={oldData.billing_state}
              onChange={handleOnChangeUpdateInfo}
            />
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing country"
              name="billing_country"
              defaultValue={oldData.billing_country}
              onChange={handleOnChangeUpdateInfo}
            />
          </div>
          {/* 
          <div className="min-1250:grid grid-cols-2 gap-4">
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing city"
              name="billing_city"
              defaultValue={oldData.billing_city}
              onChange={handleOnChangeUpdateInfo}
            />
            <input
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Billing city"
              name="billing_city"
              defaultValue={oldData.billing_city}
              onChange={handleOnChangeUpdateInfo}
            />
          </div> */}
          <button
            type="submit"
            // set profile info and then profile page
            // onClick={submitProfileInfo}
            className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 inline-block my-[30px]`}
          >
            submit
          </button>
        </form>
      </div>
    </div>
  );
}
