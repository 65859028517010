import React from "react";
import poly_shape from "../../assets/images/h_lab_report_bg2.png";
// import lab_image_1 from "../../assets/images/lab_image_1.webp";
// import lab_image_2 from "../../assets/images/lab_image_2.webp";
// import lab_image_3 from "../../assets/images/lab_image_3.webp";
// import lab_image_4 from "../../assets/images/lab_image_4.webp";
// import lab_image_5 from "../../assets/images/lab_image_5.webp";
import { BtnUnderLineWhite } from "../buttons/Button";
export default function LabReportSection({ labData }) {
  return (
    <div
      key={"LabReportSection_1"}
      style={{
        backgroundImage: `url(${
          labData.home_gallery_bg && labData.home_gallery_bg.url
            ? labData.home_gallery_bg.url
            : poly_shape
        })`,
      }}
      // className={`lab-reporting-sec text-white bg-center 1024:bg-cover min-1024:bg-[length:100%_100%] bg-no-repeat `}
      className={`lab-reporting-sec text-white bg-no-repeat bg-center`}
    >
      <div className="innaree-container grid min-768:grid-cols-6 place-items-center 1024:justify-items-center gap-4 py-[100px] relative">
        {/* position image */}
        {labData.home_gallery_product_img && (
          <img
            className="absolute bottom-[-8%] right-[-1%]  w-[100px] h-[130px] min-1024:w-[190px] min-1024:h-[230px]"
            src={labData.home_gallery_product_img}
            alt=""
            data-aos="zoom-in-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-delay="7000"
          />
        )}

        <div className="columns-2 gap-10  min-768:col-span-2 min-1024:col-span-2 min-1024:pl-[30px]">
          {labData.home_gallery_img &&
            labData.home_gallery_img.map((gallery, index) => (
              <img
                key={`gal_la_${index}`}
                className="mb-4"
                src={gallery}
                alt=""
                data-aos="zoom-in-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="7000"
              />
            ))}
        </div>

        <div
          className="min-768:col-span-3 min-768:ml-[60px] min-1600:ml-[200px]"
          data-aos="zoom-in-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          <span className="mb-4 block font-red-hat font-normal text-[16px] text-innaree-secondary-color leading-[21.17px]">
            {/* Routine Based Course */}
            {labData.home_gallery_slug}
          </span>
          <h2 className="mb-4 block font-red-hat font-bold text-[24px] min-1024:text-[32px] text-white leading-[42px]">
            {/* Ready to Join the CBD Revolution? */}
            {labData.home_gallery_title}
          </h2>
          <p className="mb-4 block font-red-hat font-normal text-[18px] text-white leading-[26px]">
            {/* It’s all about feeling empowered by CBD, and finding blends that are
            easy to use; approved by doctors, scientists and wellness experts. */}
            {/* {labData.home_gallery_description} */}
            {labData.home_gallery_description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: labData.home_gallery_description,
                }}
              />
            )}
          </p>

          <BtnUnderLineWhite btnUrl="/reporting" title="Lab report" />
        </div>
      </div>
    </div>
  );
}
