import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Profile from "../../components/auth/customer/Profile";
import Address from "../../components/auth/customer/Address";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import Edit from "../../components/auth/customer/Edit";
import { StoreContext } from "../../context/Store";
import NavbarLeft from "../../components/navbar/NavbarLeft";
import Cart from "../../components/cartpage/Cart";
import Searchbar from "../../components/searchbar/Searchbar";
import { getCookie } from "../../helper/helper";
import { base_url } from "../../Base_url";
import Preloader from "../../components/loading/Preloader";
import toast, { Toaster } from "react-hot-toast";
export default function Profilepage() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { loading, setLoading, profileSteps, setprofileSteps } =
    useContext(StoreContext);

  const [userOrderDetails, setuserOrderDetails] = useState(null);
  const [userDetails, setuserDetails] = useState({});
  const [dataLoading, setdataLoading] = useState(false);
  const [customer_id, setcustomer_id] = useState(null);

  let customerDetailsFromLocalstorage;

  // get user data
  useEffect(() => {
    setLoading(true);
    customerDetailsFromLocalstorage = JSON.parse(
      localStorage.getItem("customerDetails")
    );
    setcustomer_id(customerDetailsFromLocalstorage);
    setLoading(false);
    // console.log('id', customerDetailsFromLocalstorage.id);
  }, []);
  // get customer order data
  function getAllOrders() {
    setLoading(true);
    axios
      .get(
        `${base_url}/wp-json/softtechit/v1/innaree/customerorderdetails/${customerDetailsFromLocalstorage.id}`,
        {
          // headers: { Authorization: `Bearer ${getCookie()}` },
        }
      )
      .then((res) => {
        setuserOrderDetails(res.data);
        setLoading(false);
        // console.log('user data', res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  // get customer profile details
  function getUserDetails() {
    setLoading(true);
    axios
      .get(
        `${base_url}/wp-json/softtechit/v1/innaree/updateuser/?user_id=${customerDetailsFromLocalstorage.id}`,
        {
          // headers: { Authorization: `Bearer ${getCookie()}` },
        }
      )
      .then((res) => {
        setuserDetails(res.data);

        setLoading(false);
        // console.log('user data', res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // call function to fetch data
  useEffect(() => {
    getAllOrders();
    getUserDetails();
  }, []);

  // handle on change for update info
  const handleOnChangeUpdateInfo = (e) => {
    setuserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  //update profile info
  const submitProfileInfo = (e) => {
    e.preventDefault();
    setdataLoading(true);
    axios
      .post(
        `${base_url}/wp-json/softtechit/v1/innaree/updateuser/${customer_id.id}`,
        {
          data: userDetails,
          // data: formDataOne
        },
        {
          headers: { Authorization: `Bearer ${getCookie()}` },
        }
      )
      .then((response) => {
        // console.log(response);
        // after order is placed
        if (response.status == 200) {
          setdataLoading(false);
          toast.custom(
            <div>
              <div
                id="toast-success"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Check icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  Profile updated successfully!!
                </div>
              </div>
            </div>
          );
          setprofileSteps(1);
        }
      })
      .catch((error) => {
        console.log(error);
        setdataLoading(false);
        toast.custom(
          <div>
            <div
              id="toast-danger"
              className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
              role="alert"
            >
              <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                <svg
                  aria-hidden="true"
                  className="w-3 h-3 rounded-[50%]"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Error icon</span>
              </div>
              <div className="px-4 text-sm font-normal">
                Something went wrong!!
              </div>
            </div>
          </div>
        );
      });
  };

  // display content
  const displayComponent = () => {
    switch (profileSteps) {
      case 1:
        return <Profile orders={userOrderDetails} userInfo={userDetails} />;
      case 2:
        return <Address useraddressInfo={userDetails} />;
      case 3:
        return (
          <Edit
            oldData={userDetails}
            handleOnChangeUpdateInfo={handleOnChangeUpdateInfo}
            submitProfileInfo={submitProfileInfo}
          />
        );
      default:
        return <Profile />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Innaree - profile</title>
      </Helmet>
      {loading
        ? [<Preloader />]
        : [
            <div>
              {dataLoading && (
                <div
                  id="loading-screen"
                  class="w-full h-full fixed block top-0 left-0 bg-white opacity-[.5] z-50"
                >
                  <span class="text-green-500  opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
                    {/* <i class="fas fa-circle-notch fa-spin fa-5x"></i> */}
                    <svg
                      aria-hidden="true"
                      class=" rotate-center-fullpage inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </span>
                </div>
              )}
              <Header layout={"sm"} />
              <NavbarLeft />
              <Searchbar />
              <Cart />
              {userOrderDetails && displayComponent()}
              <Footer />
            </div>,
          ]}
    </>
  );
}
