import React, { useRef } from "react";
import { Accordion } from "../accordion/Accordion";
// import DeliveryTime from "../../assets/images/delivery-time.webp";
import delviery_img_bg_1 from "../../assets/images/delviery_img_bg.webp";
// import DeliveryAccordionShape from "../../assets/images/delivery-accordion-shape.webp";
import { Scrollbars } from "react-custom-scrollbars";
const DeliveryAccordion = ({ deliveryaccordionData }) => {
  return (
    <div
      // className="delivery-accordion 768:text-black bg-img-none-768 min-768:mt-[100px] pt-[110px] bg-no-repeat bg-[length:100%_100%] bg-center relative"
      className="delivery-accordion 768:text-black bg-img-none-768 min-768:mt-[100px] pt-[110px] bg-no-repeat bg-center relative"
      // style={{ backgroundImage: `url(${DeliveryAccordionShape})` }}
      style={{
        backgroundImage: `url(${
          deliveryaccordionData.background.url
            ? deliveryaccordionData.background.url
            : delviery_img_bg_1
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <div className="innaree-container">
        <div className="min-768:grid min-768:grid-cols-2 min-768:gap-4">
          <div className="acc-left">
            <h3 className="text-white 768:text-black text-[32px] font-bold mb-[45px]">
              {deliveryaccordionData.title}
            </h3>
            {/* <img
              className="absolute left-[15%] bottom-0 w-[482px] rounded-2xl"
              src={DeliveryTime}
              alt="product"
            /> */}
          </div>
          <div className="acc-right min-768:pb-[200px]">
            <Scrollbars style={{ width: "100%", height: 400 }}>
              <div className="px-4">
                {/* FAQ Accordions */}
                {deliveryaccordionData.delivery_faq_accordion &&
                  deliveryaccordionData.delivery_faq_accordion.map(
                    (acc_data, index) => (
                      <div key={`deliver_pag_acc_o_${index}`}>
                        <Accordion
                          accordionTitle={acc_data.title}
                          accordionContent={acc_data.description}
                          accordionId={`accordionId_${index}`}
                          classForTitle="text-white 768:text-black"
                          classForContent="text-white 768:text-black"
                        />
                      </div>
                    )
                  )}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryAccordion;
