import React, { useContext } from "react";
import { ButtonEight } from "../../buttons/Button";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { StoreContext } from "../../../context/Store";
export default function Address({ useraddressInfo }) {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { setprofileSteps } = useContext(StoreContext);

  // console.log("file check", useraddressInfo);
  return (
    <div className="address-page py-[80px] px-[20px]">
      <div className="innaree-container">
        <h2 className="text-[32px] min-576:text-[48px] font-semibold">
          Your Address
        </h2>
        <div className="actions-btn flex items-center justify-between">
          <Link
            onClick={() => setprofileSteps(1)}
            className="flex items-center text-[16px]"
          >
            <FaChevronLeft className="text-[16px] inline-block mr-[10px]" />{" "}
            Back
          </Link>

          <button
            onClick={() => setprofileSteps(3)}
            type="button"
            className={`text-white font-semibold uppercase bg-innaree-secondary-color  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500 inline-block my-[30px]`}
          >
            Edit address
          </button>
        </div>
        <div className="min-576:grid grid-cols-12 gap-10">
          {/* Orders */}
          <div className="col-span-7">
            <div className="user-info">
              <h4 className="text-[24px] font-semibold mb-[10px] min-576:mb-[30px] m-4">
                Account Info
              </h4>
              <h5 className="text-[20px] font-semibold m-4">
                {useraddressInfo.first_name && useraddressInfo.first_name} {""}
                {useraddressInfo.last_name && useraddressInfo.last_name}
              </h5>

              <p className="text-[16px] m-4">
                {useraddressInfo.billing_address_1 &&
                  useraddressInfo.billing_address_1}
              </p>
              <p className="text-[16px] m-4">
                {useraddressInfo.billing_address_2 &&
                  useraddressInfo.billing_address_2}
              </p>
              <p className="text-[16px] m-4">
                {useraddressInfo.billing_city && useraddressInfo.billing_city}
              </p>
              <p className="text-[16px] m-4">
                {useraddressInfo.billing_country &&
                  useraddressInfo.billing_country}
              </p>
              <p className="text-[16px] m-4">
                {useraddressInfo.billing_email && useraddressInfo.billing_email}
              </p>
              <p className="text-[16px] m-4">
                {useraddressInfo.billing_phone && useraddressInfo.billing_phone}
              </p>
              <p className="text-[16px] m-4">
                {useraddressInfo.billing_postcode &&
                  useraddressInfo.billing_postcode}
              </p>
              <p className="text-[16px] m-4">
                {useraddressInfo.billing_state && useraddressInfo.billing_state}
              </p>
              {/* <p className="text-[16px] m-4">
                {useraddressInfo.city && useraddressInfo.city}
              </p> */}
              <p className="text-[16px] m-4">
                {useraddressInfo.user_email && useraddressInfo.user_email}
              </p>
              <div className="mt-[10px] min-576:mt-[30px]">
                {/* <button
                  type='button'
                  className="text-[16px] font-semibold underline inline-block mr-[40px]"
                  onClick={() => setprofileSteps(3)}
                >
                  Edit
                </button> */}
                {/* <Link className="text-[16px] font-semibold underline " to="">
                  Delete
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
