import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
// import Product4 from "../../assets/images/product4.png";
import { Link } from "react-router-dom";

const DropReport = ({ reportList }) => {
  return (
    <div className="drop-report pt-[50px] pb-[80px]">
      <div className="innaree-container">
        {/* title_bar */}
        <Titlebar
          lgTitle={reportList.title}
          ClassForWrapper={
            "text-center col-span-12 min-1024:col-span-6 mb-[17px] min-576:mb-[67px]"
          }
        />
        <div className="all-reports max-w-[1100px] mx-auto grid grid-rows-3 min-768:grid-flow-col gap-4">
          {reportList.faq_accordion &&
            reportList.faq_accordion.map((labList, index) => (
              <a
                // to={`/shop/${labList.category_slug}`}
                href={labList.link}
                target='_blank'
                key={`lab_list_one${index}`}
                // to=""
                className="single-report w-[280px] min-576:w-[338px] mx-auto min-768:mx-0 block min-576:flex items-center relative mb-[15px] min-768:mb-[75px]"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <strong className="text-[64px] stroke1 mr-4">
                  {index > 9 ? index : `0${index + 1}`}
                </strong>
                <div className="drop-content border-border-color-golden border-b-[1px] pb-[15px]">
                  <p className="text-innaree-black-color text-[20px] font-medium">
                    {labList.title} <sup>{index > 9 ? index : `0${index +1}`}</sup>
                  </p>
                  <span>
                    {labList.description && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: labList.description,
                        }}
                      />
                    )}
                  </span>
                </div>
                <img
                  className="drop-hover-img transition-all ease-in-out duration-700 -rotate-12 absolute min-1024:left-[101%] min-1250:left-[105%] top-0 min-1024:w-[120px] min-1250:w-[194px] rounded-2xl opacity-0 invisible"
                  src={labList.hover_img.url}
                  alt="Product4"
                />
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DropReport;
