import React from "react";
import { Titlebar } from "../titlebar/Titlebar";

const AboutSection = ({ aboutsectionData }) => {
  // console.log('check data',aboutsectionData);
  // console.log(
  //   "check data brand details",
  //   aboutsectionData.brand_detail && aboutsectionData.brand_detail
  // );
  return (
    <div className="about-section pt-[100px]">
      <div className="innaree-container">
        <div className="grid min-1024:grid-cols-2 gap-4">
          <div className="">
            <Titlebar
              smTitle={aboutsectionData.sub_title}
              lgTitle={aboutsectionData.title}
              ClassForWrapper="mb-[20px]"
            />
            {/* Partner Logos */}
            <div className="mb-[30px] min-768:mb-0 min-1024:text-start flex flex-wrap gap-4 min-1024:max-w-[335px]">
              {aboutsectionData.brand_gallery &&
                aboutsectionData.brand_gallery.map((gallery, index) => (
                  <img
                    key={`part_log_11_${index}`}
                    className="h-[100px] w-[100px] rounded-lg inline-block shadow-in-boxShadow"
                    src={gallery}
                    alt="brand"
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                  />
                ))}
            </div>
          </div>
          <div
            className=""
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <p className="text-[16px] block leading-7 mb-[17px] font-semibold">
              {aboutsectionData.brand_details && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: aboutsectionData.brand_details,
                  }}
                />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
