import React from "react";
import { Link } from "react-router-dom";
// import shop_dropper from "../../assets/images/shop_dropper.webp";
import Leaf2 from "../../assets/images/leaf2.png";
import shopBg1 from "../../assets/images/shop-bg.png";
export default function ShopBreadcrumb({ brdData }) {
  return (
    <div
      // className={`text-white pt-[150px] min-576:pt-[270px] min-320:pb-[60px] min-768:pb-[160px] bg-bottom min-1024:bg-center bg-[length:251%_150%] min-576:bg-[length:251%_150%] min-1024:bg-[length:100%_100%] bg-no-repeat relative`}
      className={`text-white pt-[150px] min-576:pt-[270px] min-320:pb-[60px] min-768:pb-[160px] bg-no-repeat bg-center relative`}
      style={{
        backgroundImage: `url(${
          brdData.background.url ? brdData.background.url : shopBg1
        })`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <img
        className="w-[140px] absolute bottom-[5%] left-[14%] hidden min-768:block"
        src={Leaf2}
        alt="leaf"
      />
      <div className="grid grid-cols-12 place-items-center innaree-container relative">
        {/* background bg */}
        <svg
          className="innaree-container 1024:hidden z-1"
          viewBox="0 0 1169 486"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-aos="flip-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1300"
          data-aos-anchor-placement="top-bottom"
        >
          <g filter="url(#filter0_bii_1_186)">
            <path
              d="M0 181.601H46.7356C54.3585 181.601 60.5363 175.951 60.5363 168.981V11.1355C60.5363 4.98869 65.9917 0 72.7134 0H1105.26C1111.98 0 1117.43 4.98869 1117.43 11.1355V58.1753C1117.43 64.3221 1122.89 69.3108 1129.61 69.3108H1169V428.482H837.288C830.566 428.482 825.111 433.47 825.111 439.617V474.865C825.111 481.011 819.656 486 812.934 486H0V181.601Z"
              fill="#4B3F72"
              fillOpacity="0.6"
            />
          </g>
          <defs>
            <filter
              id="filter0_bii_1_186"
              x="-132.696"
              y="-132.696"
              width="1434.39"
              height="751.392"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="66.348" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1_186"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1_186"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-61.4333" dy="61.4333" />
              <feGaussianBlur stdDeviation="30.7167" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.096 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect2_innerShadow_1_186"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="61.4333" dy="-61.4333" />
              <feGaussianBlur stdDeviation="30.7167" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.76 0 0 0 0 0.76 0 0 0 0 0.76 0 0 0 0.096 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_innerShadow_1_186"
                result="effect3_innerShadow_1_186"
              />
            </filter>
          </defs>
        </svg>

        <div className="col-span-12 min-1024:col-span-7 px-4 z-10 relative">
          {/* breadcrumb menu */}
          <div
            className="text-white text-[20px] leading-[26px] capitalize inline-block"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-delay="1100"
            data-aos-anchor-placement="top-bottom"
          >
            <Link className="" to={"/"}>
              Home
            </Link>
            <span className="inline-block mx-2">/</span>
            <span className="">Shop</span>
          </div>
          {/* left side main content */}
          <h4
            className="text-[32px] leading-[42px] mb-[20px] font-bold capitalize mt-[30px]"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-delay="1400"
            data-aos-anchor-placement="top-bottom"
          >
            {brdData.title}
          </h4>
          <p
            className="text-[16px] leading-[30px] font-semibold"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            data-aos-delay="1700"
            data-aos-anchor-placement="top-bottom"
          >
            {/* {brdData.description} */}
            {brdData.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: brdData.description,
                }}
              />
            )}
          </p>
        </div>

        <div className="1024:hidden col-span-12 min-1024:col-span-3 1024:mt-[60px] min-1024:absolute right-0 top-[50%] min-1024:translate-y-[-50%]">
          <div className="relative">
            {/* position image */}
            {brdData.img_1.url && (
              <img
                src={brdData.img_1.url}
                alt=""
                className="absolute top-0 left-[-16%] w-[140px]"
                data-aos="zoom-in-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="1700"
                data-aos-anchor-placement="top-bottom"
              />
            )}
            {brdData.img_2.url && (
              <img
                src={brdData.img_2.url}
                className="w-[320px]"
                alt=""
                data-aos="zoom-in-down"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="1700"
                data-aos-anchor-placement="top-bottom"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
