import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavbarLeft from "../components/navbar/NavbarLeft";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Register from "../components/register/Register";
import Searchbar from "../components/searchbar/Searchbar";
import Cart from "../components/cartpage/Cart";

// import ScrollSmoother from "gsap/ScrollSmoother";

export default function Registerpage() {
  return (
    <>
      <Helmet>
        <title>Innaree - register</title>
      </Helmet>
      <div className="overflow-hidden">
      <Header layout={'sm'} />
        <Searchbar />
        <Cart />
        <NavbarLeft />
        <Register />
        <Footer />
      </div>
    </>
  );
}
