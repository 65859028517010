import React from "react";
import { RiStarFill, RiStarHalfFill } from "react-icons/ri";

const StarRating = ({ averageRating = 0,review_count=0 }) => {
  const rating = Number(averageRating);
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(<RiStarFill key={i} className="text-in-rating-color-golden" />);
    } else if (rating > i - 1 && rating < i) {
      stars.push(<RiStarHalfFill key={i} className="text-in-rating-color-golden" />);
    } else {
      stars.push(<RiStarFill key={i} className="text-in-rating-color-golden opacity-25" />);
    }
  }

  return (
    <div className="rating flex justify-center items-center mb-4">
      {stars}
      <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
        ({review_count && review_count})
      </p>
    </div>
  );
};

export default StarRating;
