import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AuthorImg from "../../../assets/images/helping_logo.png";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import Moment from "react-moment";
import { FacebookShareButton, TwitterShareButton, InstapaperShareButton } from "react-share";
import { base_url } from "../../../Base_url";

const SinngleBlogBreadcrumb = ({ blogParam, brd_category, brd_title, brd_auhor_img, brd_post_author_name, brd_post_date }) => {

  return (
    <div>
      <div className="single-blog-breadcrumb bg-innaree-secondary-color">
        <div className="innaree-container h-auto min-768:h-[880px] 768:pb-[50px]">
          <div className="breadcrumb-links pt-[130px] min-576:pt-[200px] min-1024:pt-[240px] text-white mb-[20px]">
            <Link to="/">Home</Link> / <Link to="">{brd_category && brd_category}</Link>
          </div>
          <h1 className="text-[32px] min-768:text-[60px] text-white font-bold leading-[40px] min-768:leading-[70px]">
            {brd_title && brd_title}
          </h1>
          <div className="mt-[30px] min-576:mt-[50px] min-576:flex items-center justify-between">
            <div className="flex items-center">
              <img
                className="author-info h-[55px] w-[55px] rounded-[50%]"
                // src={brd_auhor_img && brd_auhor_img}
                src={AuthorImg}
                alt="author"
              />
              <div className="ml-[15px]">
                {/* <span className="block text-white">Posted by : {brd_post_author_name && brd_post_author_name}</span> */}
                <span className="block text-white">Posted by : Innaree</span>
                <span className="block text-white">
                  <Moment format="YYYY/MM/DD">
                    {brd_post_date && brd_post_date}
                  </Moment>
                </span>
              </div>
            </div>
            <div className="social-share text-white flex items-center 576:mt-[30px]">
              <span className="text-[18px] inline-block mr-[20px]">Share</span>
              {/* react-share */}
              <FacebookShareButton
                className="text-[18px] inline-block mr-[10px]"
                url={`${base_url}/blog/${blogParam}`}
              >
                <FaFacebookF className="hover:text-facebook"/>
              </FacebookShareButton>

              < TwitterShareButton
                className="text-[18px] inline-block mr-[10px]"
                url={`${base_url}/blog/${blogParam}`}
              >
                <AiOutlineTwitter className="hover:text-twitter"/>
              </ TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinngleBlogBreadcrumb;
