import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { base_url } from "../../Base_url";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { StoreContext } from "../../context/Store";

const cookies = new Cookies();

const Login = () => {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { customerDetails, setcustomerDetails } = useContext(StoreContext);

  const navigate = useNavigate();

  const [loginData, setloginData] = useState({
    username: "",
    password: "",
  });

  const [showForgetPass, setshowForgetPass] = useState(false);
  const [showForgetPassSuccess, setshowForgetPassSuccess] = useState(false);
  const [dataLoading, setdataLoading] = useState(false);

  const handleOnchange = (e) => {
    setloginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (loginData.username != "" || loginData.password != "") {
      const formData = new FormData();
      formData.append("username", loginData.username);
      formData.append("password", loginData.password);

      // make post req
      axios
        .post(`${base_url}/wp-json/jwt-auth/v1/token`, formData)
        .then((response) => {
          // console.log('all data ', response.data);
          // console.log('token', response.data.data.token);

          toast.custom(
            <div>
              <div
                id="toast-success"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Check icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  Logged in Successfully!
                </div>
              </div>
            </div>
          );

          // save token for authintication
          let access_token = {
            _user: response.data.data.token.slice(0, 8),
            sbb: response.data.data.token.slice(8, 10),
            frr: response.data.data.token.slice(10, 13),
            xss: response.data.data.token.slice(13),
          };

          let date = new Date();
          date.setFullYear(date.getFullYear() + 1);
          cookies.set("_user", access_token._user, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          cookies.set("sbb", access_token.sbb, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          cookies.set("frr", access_token.frr, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          cookies.set("xss", access_token.xss, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });

          // save user information
          // setcustomerDetails({ ...customerDetails, id: response.data.data.id, displayName: response.data.data.displayName, email: response.data.data.email });

          // store to local storage
          localStorage.setItem(
            "customerDetails",
            JSON.stringify({
              id: response.data.data.id,
              displayName: response.data.data.displayName,
              email: response.data.data.email,
            })
          );
          navigate("/profile");
          // end then::
        })
        .catch((err) => {
          console.log(err);
          toast.custom(
            <div>
              <div
                id="toast-danger"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Error icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  {err.response.data.message}
                </div>
              </div>
            </div>
          );
        });
    } else {
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Please fill up all required fields!
            </div>
          </div>
        </div>
      );
    }
  };

  const handleForgetPassword = (e) => {
    e.preventDefault();
    if (loginData.username != "") {
      setdataLoading(true);
      axios
        .post(
          `${base_url}/wp-json/softtechit/v1/innaree/lostpassword/${loginData.username}`
        )
        .then((response) => {
          // console.log(response);
          toast.custom(
            <div>
              <div
                id="toast-success"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Check icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  Password reset successfully. Please check your email!
                </div>
              </div>
            </div>
          );
          setshowForgetPassSuccess(true);
          setdataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.custom(
            <div>
              <div
                id="toast-danger"
                className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
                role="alert"
              >
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
                  <svg
                    aria-hidden="true"
                    className="w-3 h-3 rounded-[50%]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Error icon</span>
                </div>
                <div className="px-4 text-sm font-normal">
                  {err.response.data.message}
                </div>
              </div>
            </div>
          );
          setdataLoading(false);
        });
    } else {
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Please fill up the email id!
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          duration: 1000,
        }}
      />
      {dataLoading && (
        <div
          id="loading-screen"
          class="w-full h-full fixed block top-0 left-0 bg-white opacity-[.5] z-50"
        >
          <span class="text-green-500  opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
            {/* <i class="fas fa-circle-notch fa-spin fa-5x"></i> */}
            <svg
              aria-hidden="true"
              class=" rotate-center-fullpage inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </span>
        </div>
      )}
      <div className="login max-w-[500px] mx-auto p-16  shadow-2xl my-16 bg-white rounded-lg">
        <h2 className="text-[32px] text-center mb-[20px]">
          {showForgetPass ? "Reset Password" : "Login"}
        </h2>
        {showForgetPass ? (
          <form onSubmit={handleForgetPassword}>
            {showForgetPass && (
              <span className="block mb-4">
                Please enter your verified email id.
              </span>
            )}
            <input
              name="username"
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Please enter your email"
              onChange={handleOnchange}
            />
            <button
              type="submit"
              className={`text-white font-semibold uppercase bg-innaree-secondary-color text-left  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500`}
            >
              Submit
            </button>
            {showForgetPassSuccess && (
              <p className="bg-innaree-secondary-color text-white mt-4 p-2 rounded text-[14px]">
                {" "}
                Password reset successfully. Please check your email!
              </p>
            )}
          </form>
        ) : (
          <form onSubmit={submitForm}>
            <input
              name="username"
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="text"
              placeholder="Username"
              onChange={handleOnchange}
            />
            <input
              name="password"
              className="w-full rounded-md border border-innaree-border-darkgray focus:ring-1 focus-visible:border-1 focus-visible:border-in-rating-color-golden  focus:ring-in-rating-color-golden mb-[10px]"
              type="password"
              placeholder="Password"
              onChange={handleOnchange}
            />
            <button
              type="submit"
              className={`text-white font-semibold uppercase bg-innaree-secondary-color text-left  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500`}
            >
              Submit
            </button>
          </form>
        )}

        <div className="flex items-center justify-between mt-[30px]">
          <Link className="hover:underline" to="/register">
            Register
          </Link>
          <Link
            onClick={() => {
              setshowForgetPass(!showForgetPass);
              setshowForgetPassSuccess(false);
            }}
            className="hover:underline"
          >
            {showForgetPass ? "Back to login" : " Forgotten your password?"}
          </Link>
          <Link className="hover:underline" to="/">
            Back to home
          </Link>
        </div>
      </div>
    </>
  );
};

export default Login;
