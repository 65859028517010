import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
// import MissionProdcutImg from "../../assets/images/mission-prodcut-img.png";
import { Link } from "react-router-dom";
const Mission = ({ missionData }) => {
  return (
    <div className="mission-area mt-[100px]">
      <div className="innaree-container">
        <div className="grid min-768:grid-cols-2 gap-4">
          <div>
            {/* Section Title */}
            <Titlebar
              smTitle={missionData.sub_title}
              lgTitle={missionData.title}
              ClassForWrapper={"min-768:w-[420px]"}
            />
            <p
              className="text-innaree-black-color text-[14px] mb-4"
              data-aos="fade-up"
              data-aos-duration="1300"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="ease-in-back"
            >
              {missionData.description && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: missionData.description,
                  }}
                />
              )}
            </p>
            {/* <p
              className="text-[16px] font-semibold"
              data-aos="fade-up"
              data-aos-duration="1300"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="ease-in-back"
              data-aos-delay="500"
            >
              Product Packaging Design
            </p>
            <p
              className="text-innaree-black-color text-[14px] mb-4"
              data-aos="fade-up"
              data-aos-duration="1300"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="ease-in-back"
              data-aos-delay="600"
            >
              The stylish product packaging of all MA True Cannabis products is
              one of the results of years of research and design. Made entirely
              from environmentally friendly materials, our product packaging is
              designed to ensure the best preservation of our CBD Cannabis
              Flowers and Pre-Rolled CBD joints.
            </p> */}
            <Link
              data-aos="fade-up"
              data-aos-duration="1300"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="ease-in-back"
              data-aos-delay="700"
              className="text-[16px] font-bold border-b-2 border-innaree-secondary-color mb-5 min-768:mb-0 inline-block"
              to="/shop"
            >
              Discover the Product
            </Link>
          </div>
          <div>
            <img
              src={missionData.product_img.url}
              alt=""
              data-aos="zoom-in-left"
              data-aos-duration="1300"
              data-aos-easing="ease-in-back"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
