import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { StoreContext } from '../../context/Store';

export default function Thankyou() {
    /******************************************
*
* GETTING CONTEXT VALUE
*
******************************************/
    let {
        checkoutSteps,
        setcheckoutSteps,
        setshowCart,
    } = useContext(StoreContext);
    return (
        <div className='innaree-container py-[100px] flex justify-center items-center'>
            <div className='w-96 text-center'>
                <h2 className=' text-[60px] capitalize'>Thank you for your order!!</h2>
                <Link onClick={() => (
                    setcheckoutSteps(1),
                    setshowCart(false)
                )} className='text-innaree-secondary-color text-[32px] capitalize' to='/'>back to home</Link>
            </div>
        </div>
    )
}
