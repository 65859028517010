import React from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import { SingleGoogleCard } from "../singleGoogleCard/SingleGoogleCard";
import { Titlebar } from "../titlebar/Titlebar";
import googleImg from "../../assets/images/google_left_img.webp";
import client1 from "../../assets/images/client1.jpg";
import client2 from "../../assets/images/client2.webp";
import client3 from "../../assets/images/client3.png";
import client4 from "../../assets/images/client4.jpg";
import client5 from "../../assets/images/client5.jpg";
import { RiStarFill } from "react-icons/ri";
import { TemplateButton } from "../buttons/Button";
export default function GoogleCard() {
  return (
    <div className="googlecard innaree-container" key={"GoogleCard_1"}>
      <Titlebar
        smTitle=""
        lgTitle="What are our customers saying..."
        ClassForWrapper={`text-center`}
        ClassForsmTitle=""
        ClassForlgTitle=""
      />

      <div className={`grid grid-cols-1 min-1024:grid-cols-2 gap-4 mt-[30px]`}>
        {/* left side */}
        <div
          className="1024:hidden"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img
            src={googleImg}
            alt=""
            className="w-full h-[600px] object-cover"
          />
        </div>

        {/* right side */}
        <div>
          <ElfsightWidget widgetID="65cad6d4-1056-4c45-8c43-14565ea385f2" />
        </div>
        {/* not working for now */}
        <div
          className={`grid 768:grid-cols-1 grid-cols-2 gap-4 place-content-start hidden`}
          data-aos="fade-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          data-aos-delay="500"
        >
          {/* google top card */}
          <div className="bg-in-bg-light-gay p-5 rounded-3xl 1024:col-span-1 col-span-2 flex justify-between items-center flex-wrap">
            <div>
              <div className="flex items-center">
                <span className="font-semibold text-[26px] inline-block mr-4">
                  <span className="text-[#4285f4]">G</span>
                  <span className="text-[#ea4345]">o</span>
                  <span className="text-[#fbbc05]">o</span>
                  <span className="text-[#4285f4]">g</span>
                  <span className="text-[#34a853]">l</span>
                  <span className="text-[#ea4345]">e</span>
                </span>

                <span className="font-medium text-[18px]">Rating</span>
              </div>
              <div className="flex items-center mb-3">
                <span className="font-bold text-lg inline-block mr-4">
                  5.00
                </span>
                <div className="rating flex justify-center items-center text-yellow-300">
                  <RiStarFill className="text-in-dark-golden" />
                  <RiStarFill className="text-in-dark-golden" />
                  <RiStarFill className="text-in-dark-golden" />
                  <RiStarFill className="text-in-dark-golden" />
                  <RiStarFill className="text-in-dark-golden" />
                </div>
                <span className="ml-4 font-normal text-[10px]">6 review</span>
              </div>
            </div>
            <TemplateButton title="Write Review" />
          </div>
          {/* single review */}
          <SingleGoogleCard
            clientImg={client1}
            review="Having tried a few CBD oils I discovered Innaree, and I have to say it is a fantastic product with a variety of other items such as the muscle rub that I think works wonders for pain and muscle fatigue."
            clientName="Jahangir Sing"
            postDate="1 Month Ago"
          />
          {/* single review */}
          <SingleGoogleCard
            clientImg={client2}
            review="I’ve been suffering from having broken sleep which has left me feeling low and tired throughout the day."
            clientName="Sofia"
            postDate="5 Days"
          />
          {/* single review */}
          <SingleGoogleCard
            clientImg={client3}
            review="Outstanding product and service received from Innaree. Exactly what I needed."
            clientName="Liam"
            postDate="15 Days"
          />
          {/* single review */}
          <SingleGoogleCard
            clientImg={client4}
            review="It was important to find 0% thc and also comforting to know this is halal certified too."
            clientName="Rohima"
            postDate="5 Days"
          />
          {/* single review */}
          <SingleGoogleCard
            clientImg={client5}
            review="Love the Innaree lip balm and the muscle rub. "
            clientName="Sufiya"
            postDate="15 Days"
          />
        </div>
      </div>
    </div>
  );
}
