import React, { useEffect, useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import $ from "jquery";
import "./HeroSection3.scss";
import HeroSlide from "./HeroSlide";
import { HomepageStoreContext } from "../../context/HomepageStore";
const HeroSection3 = () => {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { homeIsLoading, homeData, setHomeData } =
    useContext(HomepageStoreContext);

  let menu = [];

  $(".swiper-pagination .swiper-pagination-bullet:first-child").trigger(
    "click"
  );

  // check screen size
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const handleAnimation = (event) => {
    $(".img-single").each(function () {
      var layers = $(this).attr("data-val");
      // console.log(layers);
      var x = ($(window).innerWidth() - event.pageX * layers) / 100;
      var y = ($(window).innerHeight() - event.pageY * layers) / 100;
      $(this)
        .css("margin-left", x + "px")
        .css("margin-top", y + "px");
    });
  };

  return (
    <div className="herosection3" onMouseMove={handleAnimation}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay, EffectFade]}
        spaceBetween={50}
        initialSlide={1}
        touchRatio={0}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            if (homeData.home_slider) {
              homeData.home_slider.map((sl_name) =>
                menu.push(sl_name.slider_one)
              );
            }

            return (
              '<span class="' + className + '" >' + menu[index] + "</span>"
            );
          },
        }}
        effect={"fade"}
        autoplayDisableOnInteraction={false}
        onSlideChangeTransitionStart={() => {
          // if ssw is greater than 1200 then execute this code
          if (screenWidth >= 1250) {
            $(".swiper-slide-active h1").css("right", "0px");
            $(".swiper-slide-active .main-prodcut").css("left", "35%");
            $(".swiper-slide-active .left-title").css("left", "1%");
          } else if (screenWidth >= 1024) {
            $(".swiper-slide-active h1").css("right", "0px");
            $(".swiper-slide-active .main-prodcut").css("left", "45%");
            $(".swiper-slide-active .left-title").css("left", "1%");
          } else if (screenWidth >= 768) {
            $(".swiper-slide-active h1").css("right", "0px");
            $(".swiper-slide-active .main-prodcut").css("left", "45%");
            $(".swiper-slide-active .left-title").css("left", "1%");
          } else if (screenWidth >= 576) {
            $(".swiper-slide-active h1").css("right", "90px");
            $(".swiper-slide-active .main-prodcut").css("left", "35%");
            $(".swiper-slide-active .left-title").css("left", "147px");
          } else if (screenWidth >= 412) {
            $(".swiper-slide-active h1").css("right", "57px");
            $(".swiper-slide-active .main-prodcut").css("left", "41%");
            $(".swiper-slide-active .left-title").css("left", "3%");
          } else if (screenWidth >= 375) {
            $(".swiper-slide-active h1").css("right", "40px");
            $(".swiper-slide-active .main-prodcut").css("left", "41%");
            $(".swiper-slide-active .left-title").css("left", "3%");
          } else if (screenWidth >= 320) {
            $(".swiper-slide-active h1").css("right", "10px");
            $(".swiper-slide-active .main-prodcut").css("left", "30%");
            $(".swiper-slide-active .left-title").css("left", "1%");
          }

          $(".swiper-slide-active .img-group").css("left", "0px");
        }}
        onSlideChange={() => {
          $(".swiper-slide-active h1").css("right", "-100%");
          $(".swiper-slide-active .img-group").css("left", "100%");
          $(".swiper-slide-active .main-prodcut").css("left", "100%");
          $(".swiper-slide-active .left-title").css("left", "100%");
        }}
        speed={1000}
      >
        {homeData.home_slider &&
          homeData.home_slider.map((slider, index) => (
            <span key={`homeData_slider${index}`}>
              <SwiperSlide>
                <HeroSlide
                  section_wrapper_class={
                    "hero_slider_1 bg-innaree-primary-colorr"
                  }
                  section_id={"slider1"}
                  section_bg_color={slider.home_tabbed_slider.color}
                  // slider img
                  mainImage={
                    slider.home_tabbed_slider.slider_product_img_one.url
                  }
                  topLeftImg={
                    slider.home_tabbed_slider.slider_paralax_top_left.url
                  }
                  topMidImg={
                    slider.home_tabbed_slider.slider_paralax_top_middle.url
                  }
                  topRightImg={
                    slider.home_tabbed_slider.slider_paralax_top_right.url
                  }
                  btmLeftImg={
                    slider.home_tabbed_slider.slider_paralax_bottom_left.url
                  }
                  btmRightImg={
                    slider.home_tabbed_slider.slider_paralax_bottom_right.url
                  }
                  // product_slug
                  product_slug={slider.slider_product_slug}
                  // img class
                  mainImage_class={`w-[60%] 768:mx-auto min-768:w-[80%] min-1024:w-full 
                    ${index == 0 && "max-h-[460px]"}
                    ${index == 1 && "max-h-[300px]"}
                    ${index == 2 && "max-h-[300px]"}
                    ${index == 3 && "max-h-[560px]"}
                    ${index == 4 && "max-h-[560px]"}
                    ${index == 5 && "max-h-[560px]"}`}
                  topLeftImg_class={`absolute max-w-[200px] min-1250:max-w-[300px] min-1440:max-w-[406px] left-[-0.21%] top-[0%] z-10  1024:hidden`}
                  topMidImg_class={`max-w-[150px] min-1250:max-w-[250px] absolute 1024:left[0] min-1024:left-[25%] min-1440:left-[33%] top-[0%] 768:hidden img-single`}
                  topRightImg_class={`max-w-0 min-1024:max-w-[150px] min-1250:max-w-[150px] min-1600:max-w-[300px] absolute right-0 min-1440:right-[-20px] top-[0%] min-1440:top-[-20px] 768:hidden -z-[10] img-single`}
                  btmLeftImg_class={`btmLeftImg max-w-[0] min-1250:max-w-[300px] absolute left-[0%] bottom-[-50px] min-1440:bottom-[-30px] min-1600:bottom-[4.17%] -z-[10] 768:hidden img-single`}
                  btmRightImg_class={`btmRightImg max-w-[0] min-1250:max-w-[300px] absolute bottom-0 left-[75%] min-1440:left-[80%] min-1600:left-[85%] -z-[10] 768:hidden img-single`}
                  // translate 3d
                  // topMidImg_translate3d={position}
                  // topRightImg_translate3d={position}
                  // btmLeftImg_translate3d={position}
                  // btmRightImg_translate3d={position}
                  // slider content
                  left_sm_title={
                    slider.slider_text_three && slider.slider_text_two
                  }
                  left_lg_title={
                    slider.slider_text_three && slider.slider_text_three
                  }
                  right_lg_title={
                    slider.slider_text_one && slider.slider_text_one
                  }
                />
              </SwiperSlide>
            </span>
          ))}
        <div className="swiper-pagination"></div>
      </Swiper>
    </div>
  );
};

export default HeroSection3;
