import React, { useEffect, useState, useContext, useRef } from "react";
import { Titlebar } from "../titlebar/Titlebar";
import categories from "../../store/Categories.json";
import Products from "../../store/Product.json";
import Tilt from "react-tilt";
import { toast, Toaster } from "react-hot-toast";
// import catImg1 from "../../assets/images/category_img1.png";
// import InnareeLeaf from "../../assets/images/innaree-leaf.png";
// import Product1 from "../../assets/images/product1.webp";
// import video_img1 from "../../assets/images/video_img1.webp";
import ModalVideo from "react-modal-video";
import { RiStarFill, RiStarHalfFill } from "react-icons/ri";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
// import HoverImg2 from "../../assets/images/product-hover-img2.png";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  BadgeOne,
  BadgeTwo,
  ButtonOne,
  ButtonSeven,
  ButtonSix,
  ButtonTwo,
} from "../buttons/Button";
import { SiPandas } from "react-icons/si";
import { StoreContext } from "../../context/Store";
import StarRating from "../../helper/StarRating";
export default function ProductFilter({ cats, a_product, section_info }) {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    // add to cart
    cartItems,
    addItemToCart,
  } = useContext(StoreContext);
  const swiperRef = useRef();

  const [activeCategoryIndex, setactiveCategoryIndex] = useState(0);
  const [activeCategoryName, setactiveCategoryName] = useState("all");
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    // console.log('categ from props',cats);
    // console.log('product from props',a_product);
    // console.log("product from props", section_info);
  }, []);

  // handle add to cart (check if item already added to cart)
  const handleAddToCart = (product) => {
    if (cartItems.some((item) => item.id == product.id)) {
      // toast("Product already added!!.");
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Product already added!
            </div>
          </div>
        </div>
      );
    } else {
      addItemToCart(product);
      toast.custom(
        <div>
          <div
            id="toast-success"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Product Added Successfully
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="mt-[100px] innaree-container">
      <Toaster
        position="top-center"
        toastOptions={{
          // Define default options
          duration: 1000,
        }}
      />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="L61p2uyiMSo"
        onClose={() => setOpen(false)}
      />
      {/* title bar */}
      <Titlebar
        smTitle={section_info.sub_title}
        lgTitle={section_info.title}
        ClassForWrapper={"min-768:w-[420px] m-auto text-center"}
      />

      {/* catgories menu */}
      {/* For Desktop */}
      <div className="flex-wrap justify-center mt-[60px] hidden min-768:flex">
        {cats &&
          cats
            .slice()
            .reverse()
            .map((category, index) => (
              <div
                key={index}
                onClick={() => {
                  setactiveCategoryIndex(index);
                  setactiveCategoryName(
                    category.categorie_name == "Uncategorized"
                      ? "all"
                      : category.categorie_name
                  );
                }}
                className={`
                    ${
                      activeCategoryIndex == index
                        ? "bg-innaree-primary-color"
                        : "bg-[#17171719]"
                    }   
                    flex justify-between items-center m-2 py-[5px] px-[15px]  w-[170px] rounded-[16px] cursor-pointer
                    `}
              >
                <span
                  className={`text-[14px] font-bold ${
                    activeCategoryIndex == index && "text-white"
                  }`}
                >
                  {category.categorie_name == "Uncategorized"
                    ? "All Product"
                    : category.categorie_name}
                </span>
                <img
                  className="max-h-[54px] w-auto"
                  src={category.categorie_img && category.categorie_img}
                  alt=""
                />
              </div>
            ))}
      </div>

      {/* For Mobile */}
      <div className="mt-[60px] overflow-x-scroll 768:flex hidden">
        {cats &&
          cats
            .slice()
            .reverse()
            .map((category, index) => (
              <div
                key={index}
                onClick={() => {
                  setactiveCategoryIndex(index);
                  setactiveCategoryName(
                    category.categorie_name == "Uncategorized"
                      ? "all"
                      : category.categorie_name
                  );
                }}
                className={`
                    ${
                      activeCategoryIndex == index
                        ? "bg-innaree-primary-color"
                        : "bg-[#17171719]"
                    }   
                    inline-block m-2 py-[5px] px-[15px] min-w-[150px] mb-[15px] rounded-[16px] cursor-pointer
                    `}
              >
                <div className="flex justify-between items-center ">
                  <span
                    className={`text-[14px] font-bold ${
                      activeCategoryIndex == index && "text-white"
                    }`}
                  >
                    {category.categorie_name == "Uncategorized"
                      ? "All Product"
                      : category.categorie_name}
                  </span>
                  <img
                    className="max-h-[54px] w-auto"
                    src={category.categorie_img && category.categorie_img}
                    alt=""
                  />
                </div>
              </div>
            ))}
      </div>

      {/* catgories product */}
      <div className="min-576:grid  min-576:grid-cols-2 min-1024:grid-cols-3 gap-4 mt-[60px]">
        {activeCategoryName == "all"
          ? [
              a_product.map((filtered_product, index) => (
                <div
                  key={index}
                  className="product-cart-wrap group py-9 relative transition-all duration-500 hover:rounded-[20px]"
                >
                  <div className="product-img-wrap pb-4">
                    {/* <img
                      src={HoverImg2}
                      alt="innaree-leaf"
                      className="product-hover-img transition-all 768:hidden duration-500 min-576:left-[2%] min-768:left-[15%] top-[5%]"
                    /> */}

                    <Tilt className="Tilt" options={{ max: 25 }}>
                      <a>
                        <img
                          className="max-w-[100%] max-h-[300px] ma m-auto block group-hover:hidden"
                          src={filtered_product.product_thumnails}
                          alt="product1"
                        />
                        <img
                          className="max-w-[100%] max-h-[300px] ma m-auto hidden group-hover:block"
                          src={filtered_product.product_hover_image.url}
                          alt="product1"
                        />
                      </a>
                    </Tilt>
                  </div>
                  <div className="product-content-wrap text-center relative mt-4">
                    <div className="product-badges mb-[20px]">
                      <BadgeOne className="mr-4" /> <BadgeTwo />
                    </div>
                    <div className="action-buttons">
                      <div className="action-buttons">
                        <button
                          onClick={() => handleAddToCart(filtered_product)}
                          type="button"
                          className="font-red-hat mr-2 text-white bg-innaree-primary-color border-2 border-transparent h-[55px] w-[55px] rounded-full font-medium text-sm  hover:shadow-in-boxShadow-primary-color hover:-translate-y-1 duration-500"
                        >
                          ADD
                        </button>

                        <Link
                          to={`/shop-details/${filtered_product.slug}`}
                          className="font-red-hat text-center inline-block ml-2 text-black bg-white border-2 border-innaree-secondary-color py-[15px]  h-[55px] w-[55px] rounded-full font-medium text-sm hover:text-black hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                    <Link
                      className="block text-[16px] font-bold mb-4"
                      to={`/shop-details/${filtered_product.slug}`}
                    >
                      {filtered_product.name}
                    </Link>

                    <StarRating
                      averageRating={filtered_product.average_ratings}
                      review_count={filtered_product.review_count}
                    />

                    <h4 className="product-price text-[24px] font-bold">
                      £{filtered_product.price}
                    </h4>
                  </div>
                </div>
              )),
            ]
          : [
              a_product &&
                a_product
                  .filter((product) =>
                    product.product_categories.includes(activeCategoryName)
                  )
                  .map((filtered_product, index) => (
                    <div
                      key={index}
                      className="product-cart-wrap group py-9 relative transition-all duration-500 hover:rounded-[20px]"
                    >
                      <div className="product-img-wrap pb-4">
                        {/* <img
                          src={HoverImg2}
                          alt="innaree-leaf"
                          className="product-hover-img transition-all 768:hidden duration-500 min-576:left-[2%] min-768:left-[15%] top-[5%]"
                        /> */}

                        <Tilt className="Tilt" options={{ max: 25 }}>
                          <a>
                            <img
                              className="max-w-[100%] max-h-[300px] ma m-auto block group-hover:hidden"
                              src={filtered_product.product_thumnails}
                              alt="product1"
                            />
                            <img
                              className="max-w-[100%] max-h-[300px] ma m-auto hidden group-hover:block"
                              src={filtered_product.product_hover_image.url}
                              alt="product1"
                            />
                          </a>
                        </Tilt>
                      </div>
                      <div className="product-content-wrap text-center relative mt-4">
                        <div className="product-badges mb-[20px]">
                          <BadgeOne className="mr-4" /> <BadgeTwo />
                        </div>
                        <div className="action-buttons">
                          <div className="action-buttons">
                            <button
                              onClick={() => handleAddToCart(filtered_product)}
                              type="button"
                              className="font-red-hat mr-2 text-white bg-innaree-primary-color border-2 border-transparent h-[55px] w-[55px] rounded-full font-medium text-sm  hover:shadow-in-boxShadow-primary-color hover:-translate-y-1 duration-500"
                            >
                              ADD
                            </button>

                            <Link
                              to={`/shop-details/${filtered_product.slug}`}
                              className="font-red-hat text-center inline-block ml-2 text-black bg-white border-2 border-innaree-secondary-color py-[15px]  h-[55px] w-[55px] rounded-full font-medium text-sm hover:text-black hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500"
                            >
                              View
                            </Link>
                          </div>
                        </div>
                        <Link
                          className="block text-[16px] font-bold mb-4"
                          to={`/shop-details/${filtered_product.slug}`}
                        >
                          {filtered_product.name}
                        </Link>

                        <StarRating
                          averageRating={filtered_product.average_ratings}
                          review_count={filtered_product.review_count}
                        />

                        <h4 className="product-price text-[24px] font-bold">
                          £{filtered_product.price}
                        </h4>
                      </div>
                    </div>
                  )),
            ]}

        {/* video section */}
        {/* <div
          className="col-span-2 bg-center  bg-[length:100%_100%] bg-no-repeat flex align-items-center left-[10%]"
          style={{
            backgroundImage: `url(${video_img1})`,
          }}
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          <div className="min-320:w-[100%] min-320:h-[400px]  min-576:w-[420px] min-576:h-[400px] relative top-[6%] left-[6%]  min-576:left-[10%]">
            <svg
              className="w-[100%] h-[100%] absolute 576:left-[-4%] top-0 left-0"
              viewBox="0 0 436 296"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_bdii_1_489)">
                <rect
                  x="18"
                  y="8"
                  width="400"
                  height="260"
                  rx="24"
                  fill="#4B3F72"
                  fillOpacity="0.6"
                  shapeRendering="crispEdges"
                />
              </g>
              <defs>
                <filter
                  id="filter0_bdii_1_489"
                  x="-26"
                  y="-36"
                  width="488"
                  height="348"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="22" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_1_489"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="10" />
                  <feGaussianBlur stdDeviation="9" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_backgroundBlur_1_489"
                    result="effect2_dropShadow_1_489"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1_489"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="-22" dy="22" />
                  <feGaussianBlur stdDeviation="11" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect3_innerShadow_1_489"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="22" dy="-22" />
                  <feGaussianBlur stdDeviation="11" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.646745 0 0 0 0 0.646745 0 0 0 0 0.646745 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect3_innerShadow_1_489"
                    result="effect4_innerShadow_1_489"
                  />
                </filter>
              </defs>
            </svg>

            <div className="absolute top-[50%] left-[6%] min-576:left-[10%]  translate-y-[-50%]">
              <h4
                className="text-white text-[24px] font-bold"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              >
                How to Use CBD Oil
              </h4>
              <div
                className="text-white flex items-center capitalize font-semibold mt-4 cursor-pointer"
                onClick={() => setOpen(true)}
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                data-aos-delay="200"
              >
                <span className="inline-block mr-4 text-[30px]">
                  <AiOutlinePlayCircle />
                </span>
                <span>play video</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
