import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import { Titlebar } from "../titlebar/Titlebar";
import { useParams } from "react-router-dom";
import categories from "../../store/Categories.json";
import Products from "../../store/Product.json";
import Tilt from "react-tilt";
import { toast, Toaster } from "react-hot-toast";
// import catImg1 from "../../assets/images/category_img1.png";
// import InnareeLeaf from "../../assets/images/innaree-leaf.png";
// import Product1 from "../../assets/images/product1.webp";
// import video_img1 from "../../assets/images/video_img1.webp";
import ModalVideo from "react-modal-video";
import { RiStarFill, RiStarHalfFill } from "react-icons/ri";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
// import HoverImg2 from "../../assets/images/product-hover-img2.png";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  BadgeOne,
  BadgeTwo,
  ButtonOne,
  ButtonSeven,
  ButtonSix,
  ButtonTwo,
} from "../buttons/Button";
import { SiPandas } from "react-icons/si";
import { StoreContext } from "../../context/Store";
import { base_url } from "../../Base_url";
import StarRating from "../../helper/StarRating";
export default function SearchedProductComponent({ slug }) {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let {
    // add to cart
    cartItems,
    addItemToCart,
  } = useContext(StoreContext);

  const [shopProductData, setshopProductData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${base_url}/wp-json/softtechit/v1/innaree/search/${slug}`
        );
        setshopProductData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  // handle add to cart (check if item already added to cart)
  const handleAddToCart = (product) => {
    if (cartItems.some((item) => item.id == product.id)) {
      // toast("Product already added!!.");
      toast.custom(
        <div>
          <div
            id="toast-danger"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow "
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-secondary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Product already added!
            </div>
          </div>
        </div>
      );
    } else {
      addItemToCart(product);
      toast.custom(
        <div>
          <div
            id="toast-success"
            className="flex items-center p-2 mb-2 w-full max-w-xs text-black bg-white rounded-lg shadow"
            role="alert"
          >
            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-innaree-primary-color rounded-lg">
              <svg
                aria-hidden="true"
                className="w-3 h-3 rounded-[50%]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Check icon</span>
            </div>
            <div className="px-4 text-sm font-normal">
              Product Added Successfully
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {shopProductData.length > 0
        ? [
          <div className="mt-[100px] innaree-container">
            <Toaster
              position="top-center"
              toastOptions={{
                // Define default options
                duration: 1000,
              }}
            />

            {/* title bar */}
            <Titlebar
              smTitle={"Most Popular"}
              lgTitle={"Shop by popular CBD products"}
              ClassForWrapper={"min-768:w-[420px] m-auto text-center"}
            />

            {/* catgories product */}
            <div className="min-576:grid  min-576:grid-cols-2 min-1024:grid-cols-3 gap-4 mt-[60px]">
              {shopProductData &&
                shopProductData.map((filtered_product, index) => (
                  <div
                    key={index}
                    className="product-cart-wrap group py-9 relative transition-all duration-500 hover:rounded-[20px]"
                  >
                    <div className="product-img-wrap pb-4">
                      {/* <img
                 src={HoverImg2}
                 alt="innaree-leaf"
                 className="product-hover-img transition-all 768:hidden duration-500 min-576:left-[2%] min-768:left-[15%] top-[5%]"
               /> */}

                      <Tilt className="Tilt" options={{ max: 25 }}>
                        <a>
                          <img
                            className="max-w-[100%] max-h-[300px] ma m-auto block group-hover:hidden"
                            src={filtered_product.product_thumnails}
                            alt="product1"
                          />
                          <img
                            className="max-w-[100%] max-h-[300px] ma m-auto hidden group-hover:block"
                            src={filtered_product.product_hover_image.url}
                            alt="product1"
                          />
                        </a>
                      </Tilt>
                    </div>
                    <div className="product-content-wrap text-center relative mt-4">
                      <div className="product-badges mb-[20px]">
                        <BadgeOne className="mr-4" /> <BadgeTwo />
                      </div>
                      <div className="action-buttons">
                        <div className="action-buttons">
                          <button
                            onClick={() => handleAddToCart(filtered_product)}
                            type="button"
                            className="font-red-hat mr-2 text-white bg-innaree-primary-color border-2 border-transparent h-[55px] w-[55px] rounded-full font-medium text-sm  hover:shadow-in-boxShadow-primary-color hover:-translate-y-1 duration-500"
                          >
                            ADD
                          </button>

                          <Link
                            to={`/shop-details/${filtered_product.slug}`}
                            className="font-red-hat text-center inline-block ml-2 text-black bg-white border-2 border-innaree-secondary-color py-[15px]  h-[55px] w-[55px] rounded-full font-medium text-sm hover:text-black hover:shadow-in-boxShadow-golden hover:-translate-y-1 duration-500"
                          >
                            View
                          </Link>
                        </div>
                      </div>
                      <Link
                        className="block text-[16px] font-bold mb-4"
                        to={`/shop-details/${filtered_product.slug}`}
                      >
                        {filtered_product.name}
                      </Link>

                      <StarRating averageRating={filtered_product.average_ratings} review_count={filtered_product.review_count} />

                      <h4 className="product-price text-[24px] font-bold">
                        £{filtered_product.price}
                      </h4>
                    </div>
                  </div>
                ))}
            </div>
          </div>,
        ]
        : [
          <div className="flex flex-col align-middle justify-center mt-[100px]">
            <h1 className="text-center w-3/4 font-bold text-[24px] mx-auto">
              Sorry No product found!!
            </h1>
            <Link
              to="/"
              className="text-white font-semibold uppercase bg-innaree-secondary-color text-left mt-[50px] mx-auto px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500"
            >
              back to home
            </Link>
          </div>,
        ]}
    </>
  );
}
