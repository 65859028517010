import React from "react";
import SingleFeatureCard from "../singleFeature/SingleFeatureCard";
// import about_fea_icon_1 from "../../assets/images/about_fea_icon_1.png";
// import about_fea_icon_2 from "../../assets/images/about_fea_icon_2.png";
// import about_fea_icon_3 from "../../assets/images/about_fea_icon_3.png";
// import about_fea_icon_4 from "../../assets/images/about_fea_icon_4.png";

export default function AboutFeature({ fea_details }) {
  return (
    <div className="innaree-container grid min-576:grid-cols-2 min-1024:grid-cols-4 gap-4 1024:mt-[100px] min-1024:mt-[-170px]">
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
      >
        <SingleFeatureCard
          icon={fea_details.box_1_image.url}
          title={fea_details.box_1_title}
          text={fea_details.box_1_description}
        />
      </div>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <SingleFeatureCard
          icon={fea_details.box_2_image.url}
          title={fea_details.box_2_title}
          text={fea_details.box_2_description}
        />
      </div>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        data-aos-delay="600"
      >
        <SingleFeatureCard
          icon={fea_details.box_3_image.url}
          title={fea_details.box_3_title}
          text={fea_details.box_3_description}
        />
      </div>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        data-aos-delay="900"
      >
        <SingleFeatureCard
          icon={fea_details.box_4_image.url}
          title={fea_details.box_4_title}
          text={fea_details.box_4_description}
        />
      </div>
    </div>
  );
}
