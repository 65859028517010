import React, { useContext, useEffect,useState } from "react";
import { StoreContext } from "../../context/Store";
import { TfiClose } from "react-icons/tfi";
import searchSvg from "../../assets/images/search_svg.svg";
import { Link } from "react-router-dom";
export default function Searchbar() {
  /******************************************
   *
   * GETTING CONTEXT VALUE
   *
   ******************************************/
  let { showSearchbar, setshowSearchbar } = useContext(StoreContext);

  const [searchedProduct, setsearchedProduct] = useState('');

  useEffect(() => { }, []);
  return (
    <div
      key={'searcbar_1'}
      className={`h-[100%] w-[100%] bg-innaree-primary-color fixed top-0  z-[9999] justify-center  flex-col items-center transition-all duration-1000 flex ${showSearchbar == true
        ? "opacity-100  visible z-[2000] left-0"
        : "opacity-0 -z-0 invisible  left-[100%]"
        } `}
    >
      <button
        onClick={() => setshowSearchbar(false)}
        className="w-10 h-10    bg-white rounded-full flex justify-center items-center shadow-lg mb-10 hover:scale-95 transition-all hover:bg-in-rating-color-golden hover:text-white"
      >
        <TfiClose className="text-[16px]" />
      </button>
      <div className="relative rounded-2xl bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:px-10">
        <div className="mx-auto max-w-md">
          <div className="relative mx-auto w-max flex">
            <input
              type="search"
              onChange={(e) => setsearchedProduct(e.target.value)}
              className="peer  relative z-10 h-12  rounded-full  border-2 bg-transparent pl-12 outline-none  w-[200px] min-768:w-96 focus:cursor-text focus:border-innaree-yellow-color focus:pl-16 focus:pr-4"
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute inset-y-0 my-auto h-8 w-12 border-r border-transparent stroke-gray-500 px-3.5 peer-focus:border-bg-innaree-yellow-color peer-focus:stroke-bg-innaree-yellow-color"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>

            <Link 
            onClick={()=>setshowSearchbar(false)}
              to={`/searched/${searchedProduct}`}
              type="submit"
              className="p-2.5 ml-2 text-sm font-medium text-white bg-innaree-yellow-color rounded-lg border border-innaree-yellow-color hover:bg-innaree-yellow-color focus:ring-4 focus:outline-none focus:ring-innaree-yellow-color dark:bg-innaree-yellow-color dark:hover:bg-innaree-yellow-color dark:focus:ring-innaree-yellow-color"
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-8 768:hidden mx-auto">
        <img className="w-full" src={searchSvg} alt="" />
      </div>
    </div>
  );
}
