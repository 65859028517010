import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavbarLeft from "../components/navbar/NavbarLeft";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Login from "../components/login/Login";
import Searchbar from "../components/searchbar/Searchbar";
import Cart from "../components/cartpage/Cart";
import { Link } from "react-router-dom";
import { BsPatchCheck, BsPatchPlus } from "react-icons/bs";


export default function Cancelled() {
    return (
        <>
            <Helmet>
                <title>Innaree - payment cancel</title>
            </Helmet>
            <div className="overflow-hidden">
                <Header layout={'sm'} />
                <Searchbar />
                <Cart />
                <NavbarLeft />
                <div className='innaree-container py-[100px] flex justify-center items-center'>
                    <div className='register max-w-[500px] mx-auto p-16  shadow-2xl my-16 bg-white rounded-lg text-center'>
                        <h2 className=' text-[28px] capitalize font-bold'>Sorry your order is Cancelled!!</h2>
                        <span className="text-[60px] mx-auto w-36  my-4 text-innaree-primary-color text-center flex justify-center rotate-45"><BsPatchPlus /></span>
                        <div className="mb-[50px]">
                   
                            <p>Please try again later.</p>
                        </div>
                        <Link to='/' className="text-white font-semibold uppercase mt-[60px] rotate-45 bg-innaree-secondary-color text-left  px-[30px] py-[14px] text-[14px] rounded-lg hover:-translate-y-1 duration-500">back to home</Link>
                        {/* <Link className='text-innaree-secondary-color text-[16px] capitalize mt-4 inline-block' to='/'>back to home</Link> */}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

