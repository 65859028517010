import React from "react";
import { Titlebar } from "../titlebar/Titlebar";
import working_process_bg_1 from "../../assets/images/working_process_bg_1.webp";
// import working_process_img_1 from "../../assets/images/working_process_img_1.png";
// import working_process_img_2 from "../../assets/images/working_process_img_2.png";
// import working_process_img_3 from "../../assets/images/working_process_img_3.png";
import { BsCalendar2Date } from "react-icons/bs";
export default function WorkingProgress({ workingData }) {
  return (
    <div
      style={{
        backgroundImage: `url(${working_process_bg_1})`,
      }}
      className={`text-white mt-[100px] pt-[100px] pb-[100px] bg-center 1024:bg-cover min-1024:bg-[length:100%_100%] bg-no-repeat`}
    >
      <div className="innaree-container">
        <div className="text-center">
          <Titlebar
            smTitle={workingData.sub_title}
            lgTitle={workingData.title}
            ClassForWrapper=""
            ClassForsmTitle="text-innaree-secondary-color"
            ClassForlgTitle="text-white"
          />
        </div>

        {/* wrapper */}
        <div className="mt-[20px] relative">
          {/* position line start */}
          <svg
            className="absolute top-0 768:hidden min-768:left-[-11px] min-1024:left-0 h-[100%]"
            width="10"
            // height="1230"
            viewBox="0 0 10 1230"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.35001 4L5.34991 1224"
              stroke="#B09253"
              strokeWidth="0.7"
              strokeDasharray="5 5"
            />
            <circle cx="5" cy="5" r="5" fill="#B09253" />
            <circle cx="5" cy="1225" r="5" fill="#B09253" />
          </svg>
          {/* position line end */}
          {workingData.work_process_accordion.map((timeline, index) => (
            <div
              key={`wrk_pgrs_timeline${index}`}
              className="min-768:grid grid-cols-2 place-items-center relative"
            >
              {/* position icon */}
              <span className="768:hidden min-768:w-[25px] min-1024:w-[30px] min-768:h-[25px] min-1024:h-[30px] rounded-[50%] bg-innaree-secondary-color flex justify-center items-center absolute min-768:left-[-3%] min-1024:left-[-1%] top-[50%] translate-y-[-50%]">
                <BsCalendar2Date className="text-white text[16px]" />
              </span>

              <div
                className={`${
                  index % 2 === 0 ? "order-1" : "order-2"
                } p-4 min-1024:px-20 min-1024:py-[15px]`}
              >
                <h4
                  className="font-bold text-[24px] leading-[31px]"
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1300"
                >
                  {timeline.working_process_years}
                </h4>
                <p
                  className="text-[16px] font-normal leading-[21px] mt-4"
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1300"
                >
                  {timeline.working_process_description && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: timeline.working_process_description,
                      }}
                    />
                  )}
                </p>
              </div>
              <div
                className={`${
                  index % 2 === 0 ? "order-2" : "order-1"
                } p-4 min-1024:px-20 min-1024:py-[15px]`}
              >
                <img
                  src={timeline.working_process_img.url}
                  alt=""
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1300"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
