import React from "react";
import { Link } from "react-router-dom";
import CtaProducts from "../../assets/images/cta-products.png";
import CtaBg from "../../assets/images/cta-bg.webp";
import { BtnUnderLineBlack } from "../buttons/Button";
const Cta = ({ ctaData }) => {
  return (
    <div
      className="cta-ara mt-[100px] bg-center bg-no-repeat bg-auto min-768:bg-[length:100%_100%] relative pt-[57px] pb-[70px]"
      style={{ backgroundImage: `url(${CtaBg})` }}
    >
      <div className="cta-overlay z-10"></div>
      <div className="innaree-container z-20 relative">
        <div className="min-768:grid min-768:grid-cols-2 min-768:gap-4 items-center">
          <div className="acc-left mb-[50px] min-768:mb-0 text-center min-768:text-start">
            <h1
              className="text-black text-[38px] min-1024:text-[48px] font-bold mb-[5px] capitalize"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              {ctaData.title && ctaData.title}
            </h1>
            <div
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="300"
            >
              <BtnUnderLineBlack title="Find Out More" btnUrl="/shop" />
            </div>
          </div>
          <div className="acc-right">
            {/* <img
              src={ctaData.gallery}
              alt=""
              data-aos="zoom-in-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
              data-aos-delay="300"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
