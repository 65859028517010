import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useQuery } from "react-query";
import ReportingBreadcrumb from "../components/reportingPage/ReportingBreadcrumb";
import BrandSection from "../components/reportingPage/BrandSection";
import DropReport from "../components/reportingPage/DropReport";
import LabReport from "../components/reportingPage/LabReport";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import NavbarLeft from "../components/navbar/NavbarLeft";
import "../components/reportingPage/reportingpage.scss";
import Cart from "../components/cartpage/Cart";
import Searchbar from "../components/searchbar/Searchbar";
import { base_url } from "../Base_url";
import Preloader from "../components/loading/Preloader";

export default function ReportingPage() {
  /******************************************
   *
   * components functions and state
   *
   ******************************************/
  const [laboratoryData, setlaboratoryData] = useState([]);

  // get all about page data
  const {
    isLoading: laboratoryIsLoading,
    error: laboratoryError,
    data: querylaboratoryData,
  } = useQuery("laboratory-page-query-key", async () => {
    const response = await axios.get(
      `${base_url}/wp-json/softtechit/v1/innaree/laboratory/`
    );
    return response.data;
  });

  useEffect(() => {
    if (querylaboratoryData) {
      setlaboratoryData(querylaboratoryData);
    }
  }, [querylaboratoryData]);

  // distructure data from value
  const {
    laboratory_header,
    laboratory_about,
    lab_reports,
    laboratory_we_are,
    laboratory_box,
  } = laboratoryData;

  return (
    <>
      <Helmet>
        <title>Innaree - reporting</title>
      </Helmet>
      {laboratoryIsLoading
        ? [<Preloader />]
        : [
          <div className="bg-body-bg">
            <Header />
            <NavbarLeft />
            <Cart />
            <Searchbar />

            {laboratory_header && (
              <ReportingBreadcrumb brdData={laboratory_header} />
            )}
            {laboratory_about && (
              <BrandSection lababoutData={laboratory_about} />
            )}
            {lab_reports && (
              <LabReport
                labreportData={lab_reports}
                btmreportData={laboratory_we_are}
              />
            )}
            {laboratory_box && <DropReport reportList={laboratory_box} />}

            <Footer />
          </div>,
        ]}
    </>
  );
}
